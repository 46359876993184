import { Brand } from 'shared/types';
import { Product } from 'shared/constants';
import { HeroEnabledForBrandType } from './types';

export const HeroEnabledForBrand: HeroEnabledForBrandType = {
  [Product.LANDING_PAGE]: {
    [Brand.AUDI]: false,
    [Brand.VOLVO]: true,
    [Brand.WAYLANDS]: true,
    [Brand.DRIVVNQA]: true,
  },
  [Product.DETAILED_COMPARATOR]: {
    [Brand.AUDI]: true,
    [Brand.VOLVO]: false,
    [Brand.WAYLANDS]: false,
    [Brand.DRIVVNQA]: true,
    [Brand.VOLKSWAGEN]: true,
  },
};

export enum HeroContentPlacement {
  INSIDE = 'inside',
  OUTSIDE = 'outside',
}
