import React from 'react';

import { useTheme } from 'shared/hooks/useTheme';
import { useBrand } from 'shared/hooks/useBrand';
import { HeroEnabledForBrand, HeroContentPlacement } from 'features/Hero/constants';
import { HeroInterface, HeroType } from 'features/Hero/types';

const Hero: React.FC<HeroInterface> = ({ pageId, title, subTitle }) => {
  const brand = useBrand();
  const globalTheme = useTheme();

  if (!HeroEnabledForBrand[pageId][brand]) {
    return null;
  }

  const theme: HeroType = globalTheme.components.hero[pageId];
  const contentPlacementInside = {
    mobile: theme.content.display.mobile.title === HeroContentPlacement.INSIDE ? 'flex' : 'none',
    desktop: theme.content.display.desktop.title === HeroContentPlacement.INSIDE ? 'flex' : 'none',
  };

  const contentPlacementOutside = {
    mobile:
      theme.content.display.mobile.title === HeroContentPlacement.OUTSIDE ||
      theme.content.display.mobile.subTitle === HeroContentPlacement.OUTSIDE
        ? 'flex'
        : 'none',
    desktop:
      theme.content.display.desktop.title === HeroContentPlacement.OUTSIDE ||
      theme.content.display.desktop.subTitle === HeroContentPlacement.OUTSIDE
        ? 'flex'
        : 'none',
  };

  return (
    <div className="hero" data-test="hero-component">
      <picture className="hero__image">
        {theme.images.srcSet.map((srcSet, index) => (
          <source
            key={`hero-src-${index}`}
            media={`(max-width: ${theme.images.media[index]})`}
            srcSet={srcSet}
            type={theme.images.srcSetMimeType}
          />
        ))}
        <img src={theme.images.fallback} alt={theme.images.altText} />
      </picture>

      <div className="hero__content hero__content--inside-image">
        {title !== undefined && <h1 className="hero__title">{title}</h1>}
        {subTitle !== undefined && <p className="hero__sub-content">{subTitle}</p>}
      </div>

      <div className="hero__content hero__content--outside-image">
        {title !== undefined && <h1 className="hero__title">{title}</h1>}
        {subTitle !== undefined && <p className="hero__sub-content">{subTitle}</p>}
      </div>

      <style jsx>
        {`
          .hero {
            display: flex;
            overflow: hidden;
            width: 100%;
            flex-direction: column;
            margin-bottom: ${theme.mobile.marginBottom};
          }

          .hero__image {
            width: inherit;
          }

          .hero__image img {
            width: inherit;
            height: ${theme.mobile.height};
            object-fit: ${theme.images.fit};
            object-position: ${theme.images.position.default};
          }

          .hero__content {
            display: none;
            flex-direction: column;
            flex: 0 100%;
            color: ${theme.content.title.color};
            width: 100%;
            align-items: ${theme.content.title.alignItems.default};
            text-align: ${theme.content.title.text.alignment.default};
            padding-top: ${theme.content.padding.default.top};
            padding-right: ${theme.content.padding.default.right};
            padding-bottom: ${theme.content.padding.default.bottom};
            padding-left: ${theme.content.padding.default.left};
          }

          .hero__content--inside-image {
            display: ${contentPlacementInside.mobile};
            gap: ${theme.content.inside.gap};
            position: absolute;
            height: inherit;
          }

          .hero__content--outside-image {
            display: ${contentPlacementOutside.mobile};
            position: relative;
          }

          .hero__content--inside-image .hero__title {
            display: ${theme.content.display.mobile.title === HeroContentPlacement.INSIDE ? 'flex' : 'none'};
          }
          .hero__content--inside-image .hero__sub-content {
            display: ${theme.content.display.mobile.subTitle === HeroContentPlacement.INSIDE ? 'flex' : 'none'};
          }

          .hero__content--outside-image .hero__title {
            display: ${theme.content.display.mobile.title === HeroContentPlacement.OUTSIDE ? 'flex' : 'none'};
          }

          .hero__content--outside-image .hero__sub-content {
            display: ${theme.content.display.mobile.subTitle === HeroContentPlacement.OUTSIDE ? 'flex' : 'none'};
          }

          .hero__title,
          .hero__sub-content {
            width: ${theme.content.width.default};
          }

          .hero__title {
            font-family: ${theme.content.title.font.family};
            font-size: ${theme.content.title.font.size.default};
            justify-content: ${theme.content.title.justifyContent.default};
          }

          .hero__sub-content {
            font-size: ${theme.content.subTitle.font.size};
            margin-top: ${theme.content.subTitle.margin.top};
            justify-content: ${theme.content.subTitle.justifyContent.default};
          }

          @media screen and (min-width: 480px) {
            .hero {
              height: ${theme.tablet.height};
              margin-bottom: ${theme.tablet.marginBottom};
            }

            .hero__image img {
              height: ${theme.tablet.height};
              object-position: ${theme.images.position['480']};
            }

            .hero__content--inside-image {
              display: ${contentPlacementInside.desktop};
            }

            .hero__content--outside-image {
              display: ${contentPlacementOutside.desktop};
            }

            .hero__content--inside-image .hero__title {
              display: ${theme.content.display.desktop.title === HeroContentPlacement.INSIDE ? 'flex' : 'none'};
            }
            .hero__content--inside-image .hero__sub-content {
              display: ${theme.content.display.desktop.subTitle === HeroContentPlacement.INSIDE ? 'flex' : 'none'};
            }

            .hero__content--outside-image .hero__title {
              display: ${theme.content.display.desktop.title === HeroContentPlacement.OUTSIDE ? 'flex' : 'none'};
            }
            .hero__content--outside-image .hero__sub-content {
              display: ${theme.content.display.desktop.subTitle === HeroContentPlacement.OUTSIDE ? 'flex' : 'none'};
            }

            .hero__content {
              align-items: ${theme.content.title.alignItems['480']};
              text-align: ${theme.content.title.text.alignment['480']};
              padding-top: ${theme.content.padding['480'].top};
              padding-left: ${theme.content.padding['480'].left};
            }

            .hero__title,
            .hero__sub-content {
              width: ${theme.content.width['480']};
            }

            .hero__title {
              justify-content: ${theme.content.title.justifyContent['480']};
              font-size: ${theme.content.title.font.size['480']};
            }

            .hero__sub-content {
              justify-content: ${theme.content.subTitle.justifyContent['480']};
            }
          }

          @media screen and (min-width: 610px) {
            .hero__image img {
              object-position: ${theme.images.position['610']};
            }
          }

          @media screen and (min-width: 1024px) {
            .hero__image img {
              object-position: ${theme.images.position['1024']};
            }

            .hero__title,
            .hero__sub-content {
              width: ${theme.content.width['1024']};
            }
          }

          @media screen and (min-width: 1440px) {
            .hero {
              height: ${theme.hiRes.height};
              margin-bottom: ${theme.hiRes.marginBottom};
            }

            .hero__image img {
              height: ${theme.hiRes.height};
              object-fit: cover;
              object-position: ${theme.images.position['1440']};
            }
          }

          @media screen and (min-width: 2170px) {
            .hero {
              min-height: ${theme.ultraHiRes.height};
              margin-bottom: ${theme.ultraHiRes.marginBottom};
            }

            .hero__image img {
              min-height: ${theme.ultraHiRes.height};
              object-position: ${theme.images.position['2560']};
            }
          }
        `}
      </style>
    </div>
  );
};

export default Hero;
