export const volvoNavMenuLinks = [
  {
    title: 'Running costs tools',
    links: [
      { title: 'Home', links: [], url: '/volvo' },
      {
        title: 'Is an electric car right for you?',
        links: [],
        url: '/volvo/ev-tree?id=200fa256-056c-474a-ad70-eb94ea590dd1',
      },
      {
        title: 'Simple comparison tool',
        links: [],
        url: '/volvo/comparator',
      },
      {
        title: 'Detailed comparison tool',
        links: [],
        url: '/volvo/detailed-comparator',
      },
    ],
    url: '',
  },
  {
    title: 'Shop',
    links: [
      {
        title: 'Own a Volvo',
        links: [
          { title: 'Order your Volvo online', links: [], url: 'https://www.volvocars.com/uk/shop/' },
          { title: 'Car configurator', links: [], url: 'https://www.volvocars.com/uk/build/' },
          { title: 'Approved used cars', links: [], url: 'https://www.volvocars.com/uk/l/used-cars/' },
          { title: 'Car comparison tool', links: [], url: 'https://comparator.drivvn.tech/volvo' },
          { title: 'Price list', links: [], url: 'https://www.volvocars.com/uk/l/pricelists/' },
          { title: 'Insurance', links: [], url: 'https://www.volvocars.com/uk/car-finance/volvo-car-insurance/' },
        ],
        url: '',
      },
      {
        title: 'Car finance',
        links: [
          { title: 'Subscription', links: [], url: 'https://selekt.volvocars.co.uk/uk/car-finance/subscription' },
          {
            title: 'PCP',
            links: [],
            url: 'https://selekt.volvocars.co.uk/uk/car-finance/personal-contract-purchase',
          },
          { title: 'Lease', links: [], url: 'https://selekt.volvocars.co.uk/uk/car-finance/lease/' },
          { title: 'Purchase', links: [], url: 'https://selekt.volvocars.co.uk/uk/car-finance/purchase/' },
          { title: 'Volvo Loan', links: [], url: 'https://selekt.volvocars.co.uk/uk/car-finance/volvo-loan' },
          { title: 'Part Exchange', links: [], url: 'https://selekt.volvocars.co.uk/uk/car-finance/part-exchange' },
          { title: 'FAQ', links: [], url: 'https://selekt.volvocars.co.uk/uk/car-finance/faq' },
        ],
        url: '',
      },
      {
        title: 'Volvo for business',
        links: [
          { title: 'Fleet and Business', links: [], url: 'https://www.volvocars.com/uk/business' },
          { title: 'Get in touch', links: [], url: 'https://www.volvocars.com/uk/l/fleet-and-business/contact/' },
          {
            title: 'Book a Demonstrator',
            links: [],
            url: 'https://www.volvocars.com/uk/l/fleet-and-business/demo/',
          },
          { title: 'Business login', links: [], url: 'https://www.volvocars.com/uk/business/fleet/your-fleet' },
        ],
        url: '',
      },
      {
        title: 'Extras',
        links: [
          { title: 'Book a test drive', links: [], url: 'https://www.volvocars.com/uk/test-drive-booking' },
          { title: 'Find a retailer', links: [], url: 'https://www.volvocars.com/uk/dealers/car-retailers' },
          { title: 'Car accessories', links: [], url: 'https://www.volvocars.com/uk/l/accessories/' },
          { title: 'Talk to a Volvo Specialist', links: [], url: 'https://www.volvocars.com/uk/quote' },
          {
            title: 'Subscribe to Newsletter',
            links: [],
            url: 'https://www.volvocars.com/uk/keep-me-updated?campaigncode=VCB2CSalesVCUK',
          },
        ],
        url: '',
      },
    ],
    url: '',
  },
  {
    title: 'Owners',
    links: [
      {
        title: 'Service',
        links: [
          { title: 'Book a service', links: [], url: 'https://www.volvocars.com/uk/service-booking' },
          { title: 'Car accessories', links: [], url: 'https://www.volvocars.com/uk/l/accessories/' },
        ],
        url: '',
      },
      {
        title: 'Support',
        links: [
          { title: 'Support centre', links: [], url: 'https://www.volvocars.com/uk/support' },
          {
            title: 'Complaints procedure',
            links: [],
            url: 'https://www.volvocars.com/uk/l/contact-us/customer-complaints-procedure/',
          },
          { title: 'Contact us', links: [], url: 'https://www.volvocars.com/uk/support/contact-us' },
        ],
        url: '',
      },
      {
        title: 'Volvo Account',
        links: [
          {
            title: 'Manage Volvo ID',
            links: [],
            url: 'https://volvoid.eu.volvocars.com/Account/landing-page?market=UK&language=en',
          },
          { title: 'Volvo Cars app', links: [], url: 'https://www.volvocars.com/uk/v/volvo-cars-app' },
        ],
        url: '',
      },
    ],
    url: '',
  },
  {
    title: 'About us',
    links: [
      {
        title: 'Go electric with volvo',
        links: [
          { title: 'Electric cars', links: [], url: 'https://www.volvocars.com/uk/v/cars/electric-cars' },
          { title: 'Plug-in hybrid cars', links: [], url: 'https://www.volvocars.com/uk/v/cars/plug-in-hybrids' },
          { title: 'Volvo Recharge', links: [], url: 'https://www.volvocars.com/uk/v/cars/recharge' },
          {
            title: 'Charging your Volvo',
            links: [],
            url: 'https://www.volvocars.com/uk/v/cars/recharge/charging-your-volvo',
          },
        ],
        url: '',
      },
      {
        title: 'About Volvo Cars',
        links: [
          { title: 'Our story', links: [], url: 'https://www.volvocars.com/uk/v/our-story' },
          { title: 'Safety', links: [], url: 'https://www.volvocars.com/uk/v/safety/highlights' },
          { title: 'Sustainability', links: [], url: 'https://www.volvocars.com/uk/v/sustainability/highlights' },
          { title: 'News', links: [], url: 'https://www.volvocars.com/uk/news/latest/' },
          { title: 'Family Cars', links: [], url: 'https://selekt.volvocars.co.uk/uk/cars/family-cars/' },
        ],
        url: '',
      },
      {
        title: 'Connect with us',
        links: [
          {
            title: 'Careers',
            links: [
              {
                title: 'UK careers',
                links: [],
                url: 'https://jobs.volvocars.com/search/?createNewAlert=false&q=&optionsFacetsDD_department=&optionsFacetsDD_country=&locationsearch=gb',
              },
              {
                title: 'Global careers',
                links: [],
                url: 'https://www.volvocars.com/intl/v/careers/highlights',
              },
            ],
            url: '',
          },
          { title: 'Investors', links: [], url: 'https://investors.volvocars.com/' },
          { title: 'Suppliers', links: [], url: 'https://www.volvocars.com/intl/v/suppliers/responsible-business' },
          { title: 'Press & media', links: [], url: 'https://www.media.volvocars.com/uk/en-gb' },
        ],
        url: '',
      },
    ],
    url: '',
  },
];
