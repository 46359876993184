import { BaseTheme, Theme } from 'shared/types';
import { HeroContentPlacement } from 'features/Hero/constants';

export const fonts = {
  bold: 'vw head bold',
  regular: 'vw text regular',
  light: 'vw head light',
  introCopy1Bold: '700 24px/36px vw text bold',
  introCopy1Regular: '400 24px/36px vw text regular',
  introCopy2Bold: '700 20px/32px vw text bold',
  introCopy2Regular: '400 20px/32px vw text regular',
  bodyCopy1Bold: '700 16px/24px vw text bold',
  bodyCopy1Regular: '400 16px/24px vw text regular',
  bodyCopy2Bold: '700 14px/24px vw text bold',
  bodyCopy2Regular: '400 14px/24px vw text regular',
  bodyCopy3Bold: '700 12px/20px vw text bold',
  bodyCopy3Regular: '400 12px/20px vw text regular',
  buttonBold: '700 20px/24px vw text bold',
  buttonRegular: '400 16px/18.4px vw text regular',
  label1All: '400 24px/28px vw text regular',
  label2All: '400 20px/24px vw text regular',
  label3All: '400 16px/20px vw text regular',
  label4All: '700 14px/16px vw text regular',
  label5All: '400 12px/16px vw text regular',
  label1XXL: '700 32px/36px vw text bold',
  label2XXL: '700 24px/28px vw text bold',
  label3XXL: '700 20px/24px vw text bold',
  label4XXL: '700 16px/20px vw text bold',
  label5XXL: '700 14px/16px vw text bold',
  desktop: {
    display1Bold: '700 96px/108px vw head bold',
    display1Light: '300 96px/108px vw head light',
    display2Bold: '700 72px/80px vw head bold',
    display2Light: '300 72px/80px vw head light',
    display3Bold: '700 60px/68px vw head bold',
    display3Light: '300 60px/68px vw head light',
    h1Bold: '700 48px/56px vw head bold',
    h1Light: '300 48px/56px vw head light',
    h2Bold: '700 32px/36px vw head bold',
    h2Light: '300 32px/36px vw head light',
    h3Bold: '700 24px/28px vw head bold',
    h3Light: '300 24px/28px vw head light',
    h4Bold: '700  20px/24px vw head bold',
    h4Light: '300 20px/24px vw head light',
    h5Bold: '700 16px/20px vw head bold',
    h5Light: '300 16px/20px vw head light',
  },
  mobile: {
    display1Bold: '700 84px/96px vw head bold',
    display1Light: '300 84px/96px vw head light',
    display2Bold: '700 56px/64px vw head bold',
    display2Light: '300 56px/64px vw head light',
    display3Bold: '700 44px/52px vw head bold',
    display3Light: '300 44px/52px vw head light',
    h1Bold: '700 32px/36px vw head bold',
    h1Light: '300 32px/36px vw head light',
    h2Bold: '700 28px/32px vw head bold',
    h2Light: '300 28px/32px vw head light',
    h3Bold: '700 24px/28px vw head bold',
    h3Light: '300 24px/28px vw head light',
    h4Bold: '700 20px/24px vw head bold',
    h4Light: '300 20px/24px vw head light',
    h5Bold: '700 16px/20px vw head bold',
    h5Light: '300 16px/20px vw head light',
  },
};

const fontSizes = {
  sm: '0.75rem', // 12px
  md: '0.875rem', // 14px
  lg: '1rem', // 16px
  xl: '1.125rem', // 18px
  xxl: '1.563rem', // 25px
  xxxl: '1.75rem', // 28px
  xxxxl: '2.25rem', // 36px
  xxxxxl: '4.5rem', // 72px
};

const colors = {
  primary: '#001E50',
  secondary: '#ffffff',
  tertiary: '#707070',
  quaternary: '#4c4c4c',
  quinary: '#b3b3b3',
  senary: '#ffffff',
  grey: 'grey',
  grey100: '#DFE4E8',
  grey200: '#C2CACF',
  grey300: '#96A3A8',
  grey400: '#6A767D',
  grey500: '#D9D9D9',
  blue600: '#001E50',
  blue600Hover: '#334B73',
  black: '#000',
  white: '#fff',
  lightBlue: '#00B0F0',
  linkHoverColor: '#f3f5f6',
};

const spacing = {
  xs: '0.5rem', // 7px
  sm: '.857rem', // 12px
  md: '1rem', // 14px
  lg: '1.142rem', // 16px
  xl: '1.2857rem', // 18px
  xxl: '1.7857rem', // 25px
  xxxl: '2rem', // 28px
  xxxxl: '3rem', // 42px
  xxxxxl: '4rem', // 56px
  xxxxxxl: '6rem', // 96px
};

const lineHeight = {
  desktop: {
    heading1: '4.429rem', // 52px
    heading2: '2.857rem', // 32px
    heading3: '2.143', // 30px
    heading4: '1.786rem', // 30px
    copy1: '1.5rem', // 16px
    copy3: '1.286rem', // 12px
    copy2: '1.429rem', // 14px
  },
  mobile: {
    heading1: '4.429rem', // 52px
    heading2: '2.857rem', // 32px
    heading3: '2.143', // 30px
    heading4: '1.786rem', // 30px
  },
};

const volkswagen: Theme<BaseTheme> = {
  fonts,
  fontSizes,
  colors,
  favicons: {
    16: 'favicon.ico',
    32: 'favicon.ico',
    192: 'favicon.ico',
  },
  background: {
    primary: '#F2F2F2',
    secondary: '',
  },
  border: {
    primary: '#4C4C4C',
    radius: '0px',
  },
  progress: {
    0: {
      background: '#CCCCCC',
      border: '',
      bar: '#4C4C4C',
    },
    1: {
      background: '#CCCCCC',
      border: '',
      bar: '#4C4C4C',
    },
    border: {
      attributes: '',
      radius: '',
    },
  },

  buttons: {
    globals: {
      font: `400 ${fontSizes.md}/16px ${fonts.regular}`,
      color: colors.primary,
      transform: 'none',
      border: {
        width: '1px',
        style: 'solid',
        radius: '0px',
        color: colors.primary,
      },
      variants: {
        large: {
          width: '154px',
          height: '44px',
          padding: '16px 32px',
        },
        medium: {
          width: '127px',
          height: '40px',
          padding: '9px 24px',
        },
        small: {
          width: '114px',
          height: '32px',
          padding: '5px 24px',
        },
      },
      transition: 'all 200ms ease-in-out',
    },
    primary: {
      backgroundColor: colors.primary,
      border: {
        width: '2px',
        style: 'solid',
        radius: '50px',
        color: colors.primary,
      },
      font: {
        color: colors.secondary,
      },
      state: {
        hover: {
          borderColor: '#334B73',
          backgroundColor: '#334B73',
          fontColor: colors.secondary,
        },
        active: {
          transform: 'none',
        },
        focussed: {
          backgroundColor: '#0077cc',
          fontColor: colors.secondary,
          outline: {
            color: colors.quaternary,
            style: 'unset',
            width: 'unset',
            offset: 'unset',
          },
        },
        disabled: {
          color: colors.secondary,
          borderColor: '#B3B3B3',
          backgroundColor: '#B3B3B3',
        },
      },
    },
    secondary: {
      backgroundColor: colors.secondary,
      border: {
        width: '2px',
        style: 'solid',
        radius: '50px',
        color: colors.primary,
      },
      font: {
        color: colors.primary,
      },
      state: {
        hover: {
          borderColor: colors.primary,
          backgroundColor: '#F3F4F5',
          fontColor: '#334B73',
        },
        active: {
          transform: 'scale(0.95, 0.9)',
          font: {
            color: colors.secondary,
          },
        },
        focussed: {
          backgroundColor: '#0077cc',
          fontColor: colors.secondary,
          outline: {
            color: 'unset',
            style: 'unset',
            width: 'unset',
            offset: 'unset',
          },
        },
        disabled: {
          color: colors.quinary,
          borderColor: colors.quinary,
          backgroundColor: 'transparent',
        },
      },
    },
    tertiary: {
      backgroundColor: 'unset',
      border: {
        width: 'unset',
        style: 'unset',
        radius: 'unset',
        color: 'unset',
      },
      font: {
        color: colors.primary,
        weight: '700',
        decoration: 'underline',
        decorationThickness: '2px',
      },
      state: {
        hover: {
          borderColor: 'none',
          backgroundColor: 'unset',
          fontColor: colors.quaternary,
        },
        active: {
          transform: 'none',
          font: {
            color: `${colors.senary}56`,
          },
        },
        focussed: {
          backgroundColor: 'none',
          fontColor: colors.primary,
          outline: {
            color: colors.quaternary,
            style: 'unset',
            width: 'unset',
            offset: 'unset',
          },
        },
        disabled: {
          color: colors.primary,
          borderColor: 'unset',
          backgroundColor: 'unset',
        },
      },
    },
  },
  form: {
    inputs: {
      radio: {
        option: {
          text: {
            transformation: 'capitalize',
            font: fonts.bodyCopy1Regular,
          },
          spacing: '12px',
          size: '24px',
          border: {
            size: '1px',
            style: 'solid',
          },
          checked: {
            color: colors.primary,
          },
          unchecked: {
            color: colors.primary,
          },
          isCircular: true,
        },
      },
    },
  },
  components: {
    detailedComparator: {
      attributes: {
        tooltipLabel: { font: fonts.buttonRegular, color: colors.white },
        tooltipContent: { font: fonts.label3All, color: colors.white },
        columnGap: '16px',
        rowGap: '15px',
        attributeTitle: { font: fonts.bodyCopy1Bold },
        attributeValue: {
          font: fonts.introCopy2Regular,
          justifyContent: { mobile: 'center', desktop: 'flex-start' },
          padding: '0 0 15px',
        },
        list: { padding: { mobile: '20px 0 28px', desktop: '0 0 54px' }, flexGap: { mobile: '20px', desktop: '0' } },
        title: {
          font: { mobile: fonts.mobile.h2Bold, desktop: fonts.desktop.h2Bold },
          marginBottom: { mobile: '24px', desktop: '24px' },
        },
        note: {
          title: { font: fonts.bodyCopy1Bold, color: colors.grey400, marginBottom: '12px' },
          body: { font: fonts.bodyCopy2Regular, color: colors.grey400 },
          list: { gap: '16px' },
        },
        value: { padding: { desktop: '24px 0' } },
        marginBottom: { mobile: '60px', desktop: '90px' },
      },
      tabDefaultHeader: {
        marginBottom: { mobile: '40px', desktop: '60px' },
        header: {
          defaultFont: { mobile: fonts.desktop.h2Light, desktop: fonts.desktop.h2Light },
          boldFont: { mobile: fonts.desktop.h2Bold, desktop: fonts.desktop.h2Bold },
          margin: { mobile: '0 0 24px', desktop: '0 0 32px' },
        },
        body: { defaultFont: { mobile: fonts.bodyCopy1Regular } },
      },
    },
    formItem: {
      margin: spacing.xxl,
      hint: {
        color: colors.grey300,
      },
      label: {
        color: colors.blue600,
      },
      input: {
        color: colors.blue600,
        padding: '0',
      },
    },
    landingPage: {
      header: {
        contentBackgroundColor: '',
      },
    },
    pageFooter: {
      font: {
        family: fonts.regular,
        color: colors.black,
        size: '16px',
      },
      border: {
        top: `2px solid ${colors.blue600}`,
      },
      background: {
        color: 'none',
      },
      padding: {
        right: {
          mobile: '16px',
          tablet: '80px',
        },
        left: {
          mobile: '16px',
          tablet: '80px',
        },
        top: {
          mobile: '25px',
          tablet: '25px',
        },
        bottom: {
          mobile: '25px',
          tablet: '25px',
        },
      },
      iconHeight: '55px',
      linkWidth: '100px',
      nextSteps: {
        background: {
          color: colors.white,
        },
        font: {
          family: fonts.bold,
          color: '#ffffff',
        },
        title: {
          padding: '2.4rem',
          font: {
            family: 'extended normal',
            color: colors.black,
            size: '2.857rem',
          },
        },
        icon: {
          margin: '0 auto 0.5rem',
        },
        link: {
          font: {
            family: fonts.regular,
          },
          color: colors.black,
        },
        margin: {
          top: '20px',
          right: '40px',
          bottom: '20px',
          left: '40px',
        },
      },
      copy: {
        width: '300px',
        padding: {
          top: '20px',
        },
        border: {
          width: '1px 0 0',
          style: 'solid',
          color: colors.grey500,
        },
        font: {
          family: fonts.bodyCopy1Regular,
          color: colors.black,
        },
        textAlign: 'left',
      },
      footerBottom: {
        links: {
          color: colors.blue600,
          separator: {
            content: '|',
            spacing: {
              mobile: '0 16px',
              desktop: '0 16px',
            },
          },
          order: {
            mobile: '1',
            tablet: '1',
          },
          justifyContent: 'flex-start',
          marginBottom: '26px',
          link: {
            margin: {
              top: '0px',
              right: '0px',
              bottom: '8px',
              left: '0px',
            },
          },
          padding: {
            left: '0px',
            right: '0px',
          },
        },
        copy: {
          order: '4',
        },
        disclaimer: {
          lineHeight: '24px',
        },
        disclaimerLine1: {
          order: {
            mobile: '2',
            tablet: '2',
          },
          color: colors.black,
        },
        disclaimerLine2: {
          marginBottom: {
            mobile: '72px',
            desktop: '67px',
          },
          order: '3',
        },
      },
    },
    form: {
      header: {
        font: fonts.desktop.h3Bold,
        margin: {
          mobile: '40px 32px 0',
          desktop: '56px 24px 0',
        },
        padding: '0',
        backgroundColor: 'transparent',
      },
      borderColor: colors.grey200,
      logo: {
        display: 'none',
        width: '60px;',
      },
      margin: {
        bottom: '0',
      },
      paddingLeft: '0',
      backgroundColor: colors.white,
      alignItems: 'center',
      label: {
        marginBottom: '0px',
        fontWeight: 'normal',
      },
      tab: {
        color: '#666666',
        selected: {
          color: colors.black,
        },
      },
      select: {
        color: colors.blue600,
        backgroundColor: 'transparent',
        outlineFocus: 'none',
        borderBottom: `1px solid ${colors.grey200}`,
        borderBottomFocus: `1px solid ${colors.grey400}`,
        label: {
          font: {
            family: 'unset',
          },
        },
        font: fonts.bodyCopy1Regular,
        height: '40px',
        icon: {
          top: '0px',
          right: '0px',
          width: '40px',
          height: '40px',
          focusColor: colors.grey100,
          hoverColor: colors.grey100,
          iconWidth: '24px',
          iconHeight: '24px',
          color: colors.blue600,
        },
        disabledOpacity: '0.3',
      },
      content: {
        marginTop: '0px',
        marginBottom: '0px',
        marginLeft: '0px',
        marginRight: '0px',
      },
      inputField: {
        width: '210px',
        height: '43px',
        marginBottom: {
          desktop: '36px',
          mobile: '36px',
        },
        border: '1px solid #000000',
        fontWeight: '700',
        fontSize: '1.714rem',
        textAlign: 'center',
        background: '#FBDA2D',
        borderRadius: '0 4px 4px 0',
      },
      buttons: {
        flexDirection: 'column',
        justifyContent: 'left',
        maxWidth: '100%',
      },
      errorFontSize: '1.143rem',
      viewports: {
        mobile: {
          spacing: '2rem',
          error: {
            flexDirection: 'column',
            paddingLeft: '2rem',
            paddingRight: '2rem',
            textMarginLeft: '0px',
            textMarginTop: '1rem',
          },
        },
        desktop: {
          spacing: '2rem',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'start',
          error: {
            flexDirection: 'row',
            marginLeft: '2rem',
          },
        },
      },
      border: {
        top: 'none',
        left: 'none',
        right: 'none',
        bottom: '1px solid #4C4C4C',
      },
      vehicle: {
        font: fonts.bodyCopy1Bold,
        margin: {
          top: {
            mobile: '32px',
            desktop: '53px',
          },
          bottom: { mobile: '104px', desktop: '56px' },
        },
        loader: {
          fill: colors.black,
        },
        image: {
          fill: '#B3B3B3',
          width: '267px',
        },
        flexDirection: 'column',
        btn: {
          width: '140px',
          disable: {
            background: '#B3B3B3',
            color: '#FFFFFF',
          },
          hover: {
            background: '#4C4C4C',
            color: '#FFFFFF',
          },
        },
        body: {
          width: {
            desktop: '450px',
            mobile: '100%',
          },
          background: 'unset',
        },
        label: {
          background: colors.lightBlue,
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          width: '31px',
          height: '43px',
          borderRadius: '4px 0 0 4px',
          color: '#FFFFFF',
          border: {
            width: '1px 0 1px 1px',
            style: 'solid',
            color: colors.black,
          },
          font: {
            weight: '800',
            size: '14px',
          },
          padding: {
            left: '2px',
            right: '2px',
            top: '2px',
            bottom: '2px',
          },
          footer: {
            textAlign: 'center',
            padding: {
              top: '17px',
              right: '23px',
              bottom: '0px',
              left: '23px',
            },
            color: colors.blue600,
          },
          content: {
            input: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
            padding: {
              top: '0',
              bottom: '16px',
            },
          },
          vrm: {
            margin: {
              right: '13px',
            },
          },
        },
        group: {
          gap: '16px',
          mobile: {
            margin: {
              top: '32px',
            },
          },
          margin: {
            top: '32px',
          },
        },
      },
    },
    dataTableColumn: {
      width: {
        mobile: '50%',
        desktop: '33.33%',
      },
    },
    modal: {
      closeButton: {
        border: '1px solid #000',
        borderRadius: '100px',
        position: {
          top: '12px',
          right: '13px',
        },
      },
      background: {
        color: colors.black,
      },
    },
    testTypeInformation: {
      padding: { mobile: '40px 16px 32px', desktop: '' },
      header: {
        color: colors.primary,
        font: fonts.mobile.h3Bold,
        margin: { mobile: '', desktop: '' },
        height: { mobile: 'unset', desktop: 'unset' },
      },
      title: {
        color: colors.primary,
        font: fonts.mobile.h3Light,
        margin: '0 0 24px',
        justifyContent: 'center',
      },
      body: {
        color: colors.black,
        font: fonts.bodyCopy1Regular,
        margin: '0 0 56px',
      },
      link: {
        font: {
          lineHeight: '18px',
        },
      },
      button: {
        width: '100%',
      },
    },
    vehicleConfirm: {
      image: {
        width: {
          mobile: '335px',
          desktop: '431px',
        },
      },
      findAnotherVehicle: {
        font: fonts.buttonBold,
        textDecoration: 'underline',
        color: '#1c6bba',
        cursor: 'pointer',
        marginTop: '21px',
      },
      details: {
        vrm: {
          font: fonts.desktop.h2Bold,
        },
        description: {
          font: fonts.desktop.h4Bold,
          color: colors.black,
          margin: {
            top: '7px',
          },
        },
      },
      currentMileage: {
        maxWidth: '',
        width: '100%',
        background: '#fafafa',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '7px',
        description: {
          font: fonts.bodyCopy3Regular,
          textAlign: 'left',
          maxWidth: {
            mobile: '343px',
            desktop: '417px',
          },
          color: '#707070',
          marginTop: '8px',
        },
        form: {
          label: {
            textAlign: 'left',
            marginBottom: '0',
            font: fonts.bodyCopy2Regular,
            alignment: 'center',
            color: {
              default: colors.blue600,
              optional: '#666',
            },
          },
          input: {
            font: fonts.bodyCopy1Regular,
            height: '37px',
            width: {
              mobile: '343px',
              desktop: '417px',
            },
            color: colors.blue600,
            textAlign: 'left',
            border: {
              width: '1px',
              style: 'none none solid none',
              color: colors.grey400,
            },
          },
        },
      },
      actionButtons: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '22px',
        marginTop: '47px',
        width: {
          mobile: '343px',
          desktop: '423px',
        },
        cancel: {
          margin: '16px 0 0 0',
        },
      },
    },
    vehicleOption: {
      superscript: { font: fonts.bodyCopy1Regular },
      showIcon: {
        prepend: true,
        append: false,
      },
      minHeight: '212px',
      padding: '13px',
      backgroundColor: colors.white,
      unSelected: { backgroundColor: colors.white },
      border: `1px solid ${colors.grey500}`,
      borderRadius: '4px',
      width: {
        mobile: '50%',
        desktop: '33.33%',
      },
      changeVehicle: {
        mobile: {
          justification: 'flex-start',
        },
      },
      compareButton: {
        height: '52px',
        padding: '14px',
        iconHeight: '24px',
        iconWidth: '24px',
      },
      vehicleSummaryDetails: {
        backgroundColor: colors.white,
        alignSelf: 'flex-start',
        display: 'inline',
        link: {
          display: 'none',
          height: 'auto',
        },
        font: { mobile: fonts.bodyCopy2Regular, desktop: fonts.bodyCopy2Regular },
        color: colors.black,
      },
      image: {
        mobile: {
          height: 'auto',
          stickyHeight: 'auto',
          stickyWidth: '50%',
          width: '100%',
        },
        height: 'auto',
        width: '100%',
      },
      price: {
        fontFamily: fonts.bold,
      },
      hr: {
        color: 'transparent',
      },
      testStandard: {
        link: {
          color: '#1c6bba',
        },
        wrapper: {
          order: '4',
          width: '100%',
          color: colors.black,
          padding: '0',
        },
        font: fonts.bodyCopy2Regular,
        type: { font: fonts.bodyCopy2Bold },
      },
    },
    vehicleSummary: {
      icon: '/assets/volkswagen/detailed-comparator/close_circle.svg',
      width: '24px',
      title: {
        font: { mobile: fonts.mobile.h5Bold, desktop: fonts.desktop.h4Bold },
        margin: { mobile: '0 0 8px', desktop: '0 0 4px' },
      },
      description: { margin: { mobile: '0 0 5px', desktop: '0 0 5px' } },
      changeButton: { margin: { mobile: '0 0 16px', desktop: '0 0 16px' } },
      shopButton: { margin: { mobile: '8px 0 0', desktop: '16px 0 0' } },
      closeButton: { icon: { backgroundColor: colors.primary } },
    },
    findVehicleForm: {
      font: fonts.bodyCopy2Regular,
      padding: { mobile: '40px 16px 32px', desktop: '56px 24px 33px' },
      buttons: {
        margin: { mobile: '56px 0 0', desktop: '70px 0 0' },
        first: {
          margin: { mobile: '0 0 16px 0 !important', desktop: '46px 0 16px 0 !important' },
        },
      },
    },
    vehicleOptionsForm: {
      padding: '0',
      margin: '56px 32px 40px',
      title: {
        font: fonts.desktop.h3Bold,
        color: colors.black,
      },
      description: {
        font: fonts.bodyCopy1Regular,
        color: colors.black,
        marginTop: '40px',
      },
      options: {
        option: {
          margin: {
            top: '44px',
            right: '32px',
            bottom: '16px',
          },
        },
        mobile: {
          margin: {
            top: '24px',
          },
        },
        container: {
          border: `2px solid ${colors.grey500}`,
          borderRadius: '4px',
          width: {
            desktop: '100%',
            mobile: '198px',
          },
          height: '198px',
          hover: {
            border: `2px solid ${colors.blue600Hover}`,
            backgroundColor: colors.blue600Hover,
            color: colors.white,
            padding: {
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            },
          },
          icon: {
            registrationNumber: {
              image: {
                default: 'url(/assets/volkswagen/components/add-vehicle/registration-number.svg)',
                hover: 'url(/assets/volkswagen/components/add-vehicle/registration-number--hover.svg)',
                height: '48px',
              },
            },
            vehiclesList: {
              image: {
                default: 'url(/assets/volkswagen/components/add-vehicle/vehicle-options.svg)',
                hover: 'url(/assets/volkswagen/components/add-vehicle/vehicle-options--hover.svg)',
                height: '44px',
              },
            },
            marginTop: '45px',
          },
          description: {
            font: fonts.bodyCopy1Regular,
            color: colors.blue600,
            marginTop: '40px',
          },
        },
      },
      button: {
        marginTop: { mobile: '48px', desktop: '56px' },
        width: '177px',
        background: colors.black,
        color: '#FFFFFF',
        hover: {
          background: '#4C4C4C',
          color: '#FFFFFF',
        },
      },
    },
    tabHeader: {
      color: { mobile: colors.secondary, desktop: colors.primary },
      font: fonts.introCopy2Regular,
      backgroundColor: { mobile: colors.primary, desktop: colors.white },
      chevronIcon: { color: 'white', height: '24px', width: '24px' },
      height: { mobile: '56px', desktop: '42px' },
      flexGap: '44px',
      justifyContent: 'flex-start',
      borderBottom: `1px solid ${colors.grey200}`,
      margin: { mobile: '0', desktop: '0 0 11px' },
      minHeight: '6.313rem',
      spacing: {
        xxxl: '0',
      },
      item: {
        font: fonts.bodyCopy1Bold,
        hover: {
          borderBottomColor: colors.primary,
        },
        minHeight: 'unset',
        minWidth: { mobile: 'unset', desktop: 'unset' },
        textTransform: 'capitalize',
        border: {
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          borderBottom: 'none',
          lastItem: {
            borderRight: 'none',
          },
        },
        padding: { desktop: '0' },
        svg: {
          fill: 'none',
          stroke: '#808080',
        },
        width: { desktop: 'unset' },
      },
      itemDisabled: {
        border: {
          borderRight: '',
        },
        svg: {
          fill: 'none',
          stroke: '#808080',
        },
      },
      itemActive: {
        underlineSpacing: '11px',
        underline: `2px solid ${colors.primary}`,
        backgroundColor: colors.white,
        color: { mobile: colors.white, desktop: colors.primary },
        border: {
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          borderBottom: 'none',
        },
        svg: {
          fill: 'none',
          stroke: '#FFFFFF',
          opacity: '00.5',
        },
        active: {
          color: colors.primary,
        },
      },
      heading: {
        borderBottom: 'none',
      },
    },
    toggle: {
      position: 'left',
      font: {
        color: colors.tertiary,
        family: `400 14px/16px ${fonts.regular}`,
      },
      margin: {
        mobile: {
          top: 'unset',
          bottom: '24px',
          left: '0',
        },
        desktop: {
          left: '17px',
        },
      },
      button: {
        margin: {
          mobile: {
            right: '11px',
            left: '11px',
          },
          desktop: {
            right: '12px',
            left: '12px',
          },
        },
        padding: {
          mobile: {
            top: '12px',
            bottom: '12px',
          },
          desktop: {
            top: '12px',
            bottom: '12px',
          },
        },
        border: {
          bottom: {
            width: '2px',
            style: 'solid',
            color: 'transparent',
          },
        },
        focus: {
          outline: `thin dotted ${colors.quaternary}`,
        },
        active: {
          font: {
            color: colors.primary,
          },
          border: {
            color: colors.primary,
          },
        },
      },
    },
    attributes: {
      alignItems: 'center',
      title: {
        color: colors.black,
      },
      icon: {
        color: '#FFFFFF',
      },
    },
    prompt: {
      alignItems: 'center',
      textAlign: 'left',
      margin: '61px 32px 33px',
      content: {
        margin: '0 0 40px',
      },
      font: {
        family: fonts.regular,
        weight: 'normal',
      },
      buttons: {
        width: '100%',
        direction: 'column',
        margin: '0',
        button: {
          margin: '16px 0 0 ',
        },
      },
    },
    ownLeaseToggle: {
      wrapper: {
        gap: '24px',
        font: fonts.bodyCopy1Bold,
      },
    },
    runningCostTable: {
      margin: { mobile: '44px 0 0', desktop: '66px 0 0' },
      attributesHeading: {
        title: {
          font: { mobile: fonts.mobile.h3Bold, desktop: fonts.desktop.h3Bold },
          padding: { mobile: '0 0 24px', desktop: '0 0 24px' },
          borderBottom: { mobile: `2px solid ${colors.grey500}`, desktop: `2px solid ${colors.grey500}` },
        },
        superscript: { font: fonts.bodyCopy3Bold },
        subheading: {
          font: fonts.introCopy2Regular,
          color: colors.black,
        },
        value: {
          font: {
            mobile: fonts.desktop.h3Bold,
            desktop: fonts.desktop.h3Bold,
          },
          borderBottom: { mobile: `2px solid ${colors.grey500}`, desktop: `2px solid ${colors.grey500}` },
          padding: { mobile: '0 0 25px', desktop: '0' },
          justifyContent: { mobile: 'initial', desktop: 'flex-start' },
        },
        font: {
          family: fonts.bold,
          weight: 'normal',
        },
        MobileAlignItems: 'flex-end',
        DesktopAlignItems: 'center',
        headerBorder: {
          borderBottom: { mobile: 'none', desktop: 'none' },
        },
        margin: { mobile: '32px 0 0', desktop: '40px 0 0' },
      },
      tableRow: {
        border: '0px',
        borderTop: '0px',
      },
      tableCell: {
        borderBottom: '1px solid #000000',
        margin: 'none',
      },
      inputCell: {
        borderTop: '0px',
        borderBottom: `1px solid ${colors.grey400}`,
        borderLeft: '0px',
        borderRight: '0px',
        focus: {
          outline: 'transparent',
          borderBottom: '1px solid #000000',
        },
        textAlign: 'left',
        padding: '0 0 0 12px',
      },
      title: {
        mobile: {
          margin: {
            bottom: '24px',
            left: '0',
          },
        },
        tablet: {
          margin: {
            bottom: '24px',
            left: '0',
          },
        },
      },
    },
    recalculateForm: {
      wrapper: {
        border: {
          open: {
            width: 'unset',
            style: 'unset',
            color: 'unset',
            radius: 'unset',
          },
          closed: {
            width: '2px',
            style: 'none none solid none',
            color: colors.primary,
          },
        },
        toggle: {
          height: '40px',
          color: `${colors.black}`,
          textDecoration: 'none ',
          iconSize: '12px',
          font: fonts.bodyCopy1Bold,
        },
        margin: {
          top: '1rem',
          bottom: '2.5rem',
        },
        form: {
          mobileTitle: {
            font: fonts.mobile.h3Bold,
          },
          border: {
            width: '1px',
            style: 'solid ',
            color: colors.grey500,
            radius: '4px',
          },
          padding: '1.5rem 2rem',
          inner: {
            padding: [`0 ${spacing.lg}`, '0'],
          },
          close: {
            border: {
              bottom: `2px solid ${colors.blue600} !important`,
            },
            minWidth: '24px !important',
          },

          cta: {
            alignSelf: 'flex-end !important',
          },
        },
      },
      input: {
        padding: 'unset !important',
        height: '40px',
      },
    },
    infoPopup: {
      messageRunningCosts: 'flex',
      background: '#353F44',
      arrowBackground: 'transparent',
      borderRadius: '4px',
      color: '#FFFFFF',
      minWidth: '250px',
      maxWidth: '320px',
      padding: '12px 20px 12px',
      text: {
        width: '288px',
        textAlign: 'left',
        font: fonts.buttonRegular,
      },
      close: {
        width: '12px',
        display: 'inline',
      },
      message: {
        left: '190px',
        runningCost: {
          width: '60px',
          left: '50vw',
        },
      },
      border: {
        color: '#ffffff',
      },
      image: { height: '24px', width: '24px' },
    },
    monthlyCost: {
      color: colors.black,
      font: {
        size: '1.142rem',
        family: fonts.bold,
        weight: 'bold',
      },
      heading: {
        title: {
          color: colors.black,
          font: { mobile: fonts.bodyCopy1Bold },
          padding: { mobile: '', desktop: '0 0 11px' },
        },
        description: {
          font: { mobile: fonts.buttonRegular },
          color: colors.black,
        },
      },
      padding: {
        left: '1.2857rem',
      },
      totals: {
        gap: { mobile: '24px' },
        ownTypeOwn: {
          backgroundColor: { mobile: 'transparent', desktop: colors.grey100 },
          innerBackgroundColor: colors.grey100,
          padding: {
            mobile: '20px 12px 21px',
            desktop: '16px',
          },
          heading: { padding: { mobile: '0 0 16px', desktop: '0' } },
          titleAndValuesGap: '8px',
          title: {
            mobile: {
              font: fonts.bodyCopy1Bold,
              margin: '24px 0 0',
            },
          },
          value: {
            mobile: {
              font: fonts.introCopy2Regular,
              margin: '16px 0 0',
            },
          },
        },
        heading: {
          padding: { mobile: '20px 0 14px', desktop: '12px 0 23px' },
          flexGap: { mobile: '3px', desktop: '11px' },
        },
        value: {
          padding: { mobile: '0 0 14px', desktop: '0' },
        },
      },
    },
    hero: {
      'detailed-comparator': {
        mobile: {
          height: '180px',
        },
        tablet: {
          height: '421px',
        },
        hiRes: {
          height: '721px',
        },
        images: {
          altText: 'An e-tron parked in the mountains',
          srcSet: [
            '/assets/audi/detailed-comparator/hero/e-tron_2020_2096-480x600px.webp',
            '/assets/audi/detailed-comparator/hero/e-tron_2020_2096-1080x608px.webp',
            '/assets/audi/detailed-comparator/hero/e-tron_2020_2096-1920x1080px.webp',
            '/assets/audi/detailed-comparator/hero/e-tron_2020_2096-3840x2160px.webp',
          ],
          srcSetMimeType: 'image/webp',
          fallback: '/assets/audi/detailed-comparator/hero/e-tron_2020_2096-1920x1080px.jpg',
          media: ['768px', '1024px', '1440px', '4000px'],
          fit: 'cover',
          position: {
            default: 'center 60%',
            480: 'center 80%',
            1440: 'center 80%',
            2560: 'center 90%',
          },
        },
        content: {
          display: {
            mobile: { title: HeroContentPlacement.OUTSIDE, subTitle: HeroContentPlacement.OUTSIDE },
            desktop: { title: HeroContentPlacement.INSIDE, subTitle: HeroContentPlacement.INSIDE },
          },
          padding: {
            default: {
              top: '28px',
              right: '19px',
              bottom: '28px',
              left: '19px',
            },
            480: {
              top: '42px',
              right: '80px',
              bottom: '42px',
              left: '80px',
            },
          },
          title: {
            justifyContent: {
              default: 'center',
              480: 'flex-start',
            },
            text: {
              alignment: {
                default: 'center',
                480: 'left',
              },
            },
            color: colors.black,
            font: {
              size: {
                default: '2rem',
                480: '2.85714rem',
              },
            },
          },
        },
      },
    },
    nextStepsRedesign: {
      color: colors.black,
      title: {
        fontMobile: fonts.mobile.h2Bold,
        fontDesktop: fonts.desktop.h2Bold,
      },
      stepDescription: { font: fonts.desktop.h3Light },
      link: { hoverColor: colors.linkHoverColor },
    },
    loader: {
      text: {
        fontSize: '1.144rem',
      },
      icon: {
        fill: colors.black,
      },
    },
  },
  vrmLookup: {
    background: '#FDC238',
  },
  layout: {
    pageHeader: {
      height: {
        mobile: '72px',
        desktop: '112px',
      },
      logo: {
        imageSrc: '/assets/volkswagen/common/logo.svg',
        width: {
          desktop: '48px',
          tablet: '32px',
          mobile: '32px',
        },
        height: '48px',
        padding: {
          left: {
            desktop: '0',
            mobile: '0',
          },
        },
        container: {
          display: 'grid',
          gridTemplateAreas: '. logo .',
          gridTemplateColumns: { mobile: 'auto max-content auto', desktop: '70% max-content auto' },
          gridColumnGap: { mobile: '', desktop: '' },
          width: '100%',
          height: { mobile: '48px', desktop: '92px' },
          before: { height: '2px', backgroundColor: colors.primary },
        },
        alignItems: 'flex-end',
      },
    },
  },
  tools: {
    detailedComparator: {
      tabs: {
        default: {
          title: {
            font: {
              family: 'extended normal',
              size: '2.286rem',
            },
          },
        },
      },
    },
  },
};

export { volkswagen };
