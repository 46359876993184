import 'styles/globals.css';
import React from 'react';
import App, { AppContext, AppProps } from 'next/app';
import PlausibleProvider from 'next-plausible';
import { appWithTranslation } from 'next-i18next';
import { ThemeProvider, StyledThemeProvider } from 'shared/hooks/useTheme';
import { makeTheme } from 'shared/theme';
import { BrandProvider } from 'shared/hooks/useBrand';
import { BrandConfigProvider } from 'shared/hooks/useBrandConfig';
import { JourneyTypeProvider } from 'shared/hooks/useJourney';
import { FeatureProvider, readFeatureToggles } from 'shared/hooks/useFeature';
import { Brand, PlausibleConfiguration } from 'shared/types';
import { formatParameter } from 'shared/utils/formatParameter';
import { configs } from 'shared/brandConfigs';
import { CustomSVGProvider } from 'shared/hooks/useCustomSVG';
import { FuelCostProvider, FuelCosts } from 'shared/hooks/useFuelCosts';
import axios from 'axios';
import {
  DEFAULT_BUSINESS_FLEET_MANAGER_MILEAGE,
  DEFAULT_DIESEL_FUEL_PRICE,
  DEFAULT_ELECTRIC_FUEL_PRICE,
  DEFAULT_PETROL_FUEL_PRICE,
  DEFAULT_PRIVATE_AND_COMPANY_CAR_MILEAGE,
} from 'services/helpers/calculations/constants';
import { logger } from 'services/utils/logger';
import { formatError } from 'services/utils/errors';
import { StyleSheetManager } from 'styled-components';

type CustomAppProps = AppProps & {
  plausibleConfiguration: PlausibleConfiguration;
  brand: Brand;
  features: ReturnType<typeof readFeatureToggles>;
  fuelCosts: FuelCosts;
};

const ComparatorApp = ({
  Component,
  pageProps,
  plausibleConfiguration,
  brand,
  features,
  fuelCosts,
}: CustomAppProps): JSX.Element => {
  const baseDomain: string = plausibleConfiguration?.domain ?? '';
  const plausibleDomains = `${String(brand)}.${baseDomain},${baseDomain}`;

  return (
    <PlausibleProvider
      domain={plausibleDomains}
      enabled={plausibleConfiguration.enabled}
      trackLocalhost={process.env.PUBLIC_PLAUSIBLE_ENABLE_LOCALHOST === 'true'}
      trackOutboundLinks
    >
      <StyleSheetManager enableVendorPrefixes>
        <ThemeProvider theme={makeTheme(brand)}>
          <StyledThemeProvider theme={makeTheme(brand)}>
            <BrandProvider brand={brand}>
              <FuelCostProvider fuelCosts={fuelCosts}>
                <BrandConfigProvider configs={configs}>
                  <FeatureProvider features={features}>
                    {/** // TODO: Update types * */}
                    <JourneyTypeProvider>
                      <CustomSVGProvider brand={brand}>
                        <Component {...pageProps} />
                      </CustomSVGProvider>
                    </JourneyTypeProvider>
                  </FeatureProvider>
                </BrandConfigProvider>
              </FuelCostProvider>
            </BrandProvider>
          </StyledThemeProvider>
        </ThemeProvider>
      </StyleSheetManager>
    </PlausibleProvider>
  );
};

ComparatorApp.getInitialProps = async (context: AppContext) => {
  const plausibleConfiguration = {
    enabled: process.env.PUBLIC_PLAUSIBLE_ENABLED === 'true',
    domain: process.env.PUBLIC_PLAUSIBLE_DOMAIN ?? '',
  };
  const brand = formatParameter(context.ctx.query.brand);
  const features = readFeatureToggles(process.env as any);

  const newContext = {
    ...context,
    plausibleConfiguration,
  };

  const pageProps = await App.getInitialProps(newContext);

  const apiUrl = `http://${process.env.NODE_ENV === 'development' ? 'app:3000' : 'viewer'}/api/fuel-cost`;
  const res = await axios.get(apiUrl, { timeout: 2000 }).catch((error) => {
    logger.error(`[APP]: Fetching fuel cost data failed: ${formatError(error)}`);

    return {
      data: {
        data: {
          business: {
            miles: DEFAULT_BUSINESS_FLEET_MANAGER_MILEAGE,
            petrol: DEFAULT_PETROL_FUEL_PRICE,
            diesel: DEFAULT_DIESEL_FUEL_PRICE,
            electricity: DEFAULT_ELECTRIC_FUEL_PRICE,
          },
          private: {
            miles: DEFAULT_PRIVATE_AND_COMPANY_CAR_MILEAGE,
            petrol: DEFAULT_PETROL_FUEL_PRICE,
            diesel: DEFAULT_DIESEL_FUEL_PRICE,
            electricity: DEFAULT_ELECTRIC_FUEL_PRICE,
          },
          fuel_cost: {
            miles: DEFAULT_PRIVATE_AND_COMPANY_CAR_MILEAGE,
            petrol: DEFAULT_PETROL_FUEL_PRICE,
            diesel: DEFAULT_DIESEL_FUEL_PRICE,
            electricity: DEFAULT_ELECTRIC_FUEL_PRICE,
          },
        },
      },
    };
  });

  const fuelCosts = res.data.data;

  return {
    pageProps,
    plausibleConfiguration,
    brand,
    features,
    fuelCosts,
  };
};

export default appWithTranslation(ComparatorApp);
