import React from 'react';
import styled from 'styled-components';
import { fonts } from 'shared/theme/volkswagen';
import { Trans, useTranslation } from 'next-i18next';
import { Brand } from 'shared/types';
import { TranslationKey } from 'shared/constants';
import { Container } from 'shared/components/Container';

const HeroWrapper = styled.section`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    padding: 0 0 48px;

    @media screen and (min-width: ${theme.viewports.desktop}) {
        flex-direction: row;
        padding: 0 0  68px;
    }
  `}
`;

const HeroImage = styled.div`
  background-image: url('/assets/volkswagen/detailed-comparator/hero/vw_hero_image_mobile.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 160px;

  @media screen and (min-width: ${({ theme }) => theme.viewports.tablet}) {
    background-image: url('/assets/volkswagen/detailed-comparator/hero/vw_hero_image_desktop.jpeg');
    height: 230px;
  }

  @media screen and (min-width: ${({ theme }) => theme.viewports.desktop}) {
    height: 484px;
    flex-basis: 50%;
  }
`;

const HeroTextWrapper = styled(Container)`
  &&& {
    display: flex;
    flex-direction: column;
    padding-top: 40px;

    @media screen and (min-width: ${({ theme }) => theme.viewports.desktop}) {
      flex-basis: 50%;
      justify-content: center;
      padding: 0 0 0 56px;
    }
  }
`;

const Title = styled.h5`
  margin-bottom: 4px;
  font: ${fonts.mobile.h5Bold};

  @media screen and (min-width: ${({ theme }) => theme.viewports.desktop}) {
    margin-bottom: 9px;
    font: ${fonts.desktop.h5Bold};
  }
`;

const SubTitle = styled.h1`
  font: ${fonts.mobile.display3Light};
  margin-bottom: 16px;

  @media screen and (min-width: ${({ theme }) => theme.viewports.desktop}) {
    font: ${fonts.desktop.display3Light};
    margin-bottom: 16px;
  }
`;

const BoldSubTitle = styled.span`
  ${({ theme }) => `
    font: ${fonts.mobile.display3Bold};

    @media screen and (min-width: ${theme.viewports.desktop}) {
        font: ${fonts.desktop.display3Bold};
    }
  `}
`;

const Description = styled.p`
  font: ${fonts.introCopy1Regular};

  @media screen and (min-width: ${({ theme }) => theme.viewports.desktop}) {
    width: calc(100% / 3 * 2);
  }
`;

const BoldDescription = styled.span`
  font: ${fonts.introCopy1Regular};

  @media screen and (min-width: ${({ theme }) => theme.viewports.desktop}) {
    font: ${fonts.introCopy1Bold};
  }
`;

export const VolkswagenHero = () => {
  const { t: translate } = useTranslation(`${Brand.VOLKSWAGEN}.${TranslationKey.DETAILED_COMPARATOR}`);
  return (
    <HeroWrapper>
      <HeroImage />
      <HeroTextWrapper>
        <Title>{translate('hero.title')}</Title>
        <SubTitle>
          <Trans i18nKey="hero.subtitle" t={translate} components={{ bold: <BoldSubTitle /> }} />
        </SubTitle>
        <Description>
          <Trans i18nKey="hero.description" t={translate} components={{ bold: <BoldDescription /> }} />
        </Description>
      </HeroTextWrapper>
    </HeroWrapper>
  );
};
