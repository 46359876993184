import { Product } from 'shared/constants';
import { Brand, FuelType } from 'shared/types';
import { volvoNavMenuLinks } from 'shared/brandConfigs/navMenuLinks/volvo';

const volvoConfig = {
  [Product.COMPARATOR]: {
    shouldUseNewIcons: true,
    components: { displayShowcaseHeader: true },
    features: {
      shopLinkCTA: {
        isShopCTAShown: true,
        makeShopLink: ({
          manufacturer,
          model,
          fuelType,
        }: {
          manufacturer: string;
          model: string;
          fuelType: FuelType;
        }) => {
          const validFuelTypes = [FuelType.ELECTRIC, FuelType.PLUG_IN_HYBRID];
          if (!validFuelTypes.includes(fuelType) || manufacturer?.toLowerCase?.() !== Brand.VOLVO) {
            return '';
          }
          return `https://www.volvocars.com/uk/shop/${model.toLowerCase()}${
            fuelType === FuelType.ELECTRIC ? '-electric' : ''
          }/?utm_source=drivvn&utm_medium=referral&utm_campaign=simple-shop`;
        },
      },
    },
  },
  [Product.USED_COMPARATOR]: {
    shouldUseNewIcons: true,
    filters: { useModelsFilters: [{ discontinuedFrom: 2010 }] },
    features: {
      vehicleSummary: { isImageResizeOnStickyDisabled: true },
    },
  },
  [Product.EV_TREE]: { shouldUseNewIcons: true, shouldShowCompareAndConfigureButtons: true },
  [Product.LANDING_PAGE]: { shouldUseNewIcons: true },
  [Product.DETAILED_COMPARATOR]: {
    shouldUseNewIcons: true,
    features: {
      currentMileage: { shouldShowCurrentMileageInput: true },
      shopLinkCTA: {
        isShopCTAShown: true,
        makeShopLink: ({
          manufacturer,
          model,
          fuelType,
        }: {
          manufacturer: string;
          model: string;
          fuelType: FuelType;
        }) => {
          const validFuelTypes = [FuelType.ELECTRIC, FuelType.PLUG_IN_HYBRID];
          if (!validFuelTypes.includes(fuelType) || manufacturer?.toLowerCase?.() !== Brand.VOLVO) {
            return '';
          }
          return `https://www.volvocars.com/uk/shop/${model.toLowerCase()}${
            fuelType === FuelType.ELECTRIC ? '-electric' : ''
          }/?utm_source=drivvn&utm_medium=referral&utm_campaign=detailed-shop`;
        },
      },
    },
  },
  [Product.ALL]: {
    navMenuLinks: volvoNavMenuLinks,
  },
};

export default volvoConfig;
