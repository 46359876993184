import { merge, cloneDeep } from 'lodash';
import { Brand, BaseTheme } from 'shared/types';
import { heroTheme } from 'features/Hero';
import { volvo } from 'shared/theme/volvo';
import { ford } from 'shared/theme/ford';
import { waylands } from 'shared/theme/waylands';
import { audi } from 'shared/theme/audi';
import { motability } from 'shared/theme/motability';
import { drivvnQA } from 'shared/theme/drivvnQA';
import { volkswagen } from 'shared/theme/volkswagen';
import { titleAndDescriptionTheme } from 'features/detailed-comparator/components/DetailedComparator/TitleAndDescription/theme';
import { radioTheme } from 'shared/components/Radio/theme';

const colors = {
  primary: '#ccc',
  secondary: '#fff',
  tertiary: '#707070',
  quaternary: '#eee',
  quinary: '#284e80',
  senary: '#000000',
};

const alpha = {
  primary: '32',
};

const fonts = {
  light: 'Arial Narrow',
  regular: 'Arial',
  medium: 'Arial Medium',
  bold: 'Arial Bold',
};

const fontSizes = {
  sm: '.857rem', // 12px
  md: '1rem', // 14px
  lg: '1.142rem', // 16px
  xl: '1.2857rem', // 18px
  xxl: '1.7857rem', // 25px
  xxxl: '2rem', // 28px
  xxxxl: '2.571rem', // 36px
  xxxxxl: '5.143rem', // 72px,
  desktop: {
    heading1: '3.429rem', // 48px
    heading2: '2.286rem', // 32px
    heading3: '1.714rem', // 16
    heading4: '1.429rem', // 20px
    copy1: '1.143rem', // 16px
    copy3: '0.857rem', // 12px
    copy2: '1rem', // 14px
  },
  mobile: {
    heading1: '3.429rem', // 48px
    heading2: '2.286rem', // 32px
    heading3: '1.714rem', // 16
    heading4: '1.429rem', // 20px
    copy1: '1.143rem', // 16px
    copy3: '0.857rem', // 12px
    copy2: '1rem', // 14px
  },
};

const lineHeight = {
  desktop: {
    heading1: '4.429rem', // 52px
    heading2: '2.857rem', // 32px
    heading3: '2.143', // 30px
    heading4: '1.786rem', // 30px
    copy1: '1.5rem', // 16px
    copy3: '1.286rem', // 12px
    copy2: '1.429rem', // 14px
  },
  mobile: {
    heading1: '4.429rem', // 52px
    heading2: '2.857rem', // 32px
    heading3: '2.143', // 30px
    heading4: '1.786rem', // 30px
  },
};

const spacing = {
  xs: '0.5rem', // 7px
  sm: '.857rem', // 12px
  md: '1rem', // 14px
  lg: '1.142rem', // 16px
  xl: '1.2857rem', // 18px
  xxl: '1.7857rem', // 25px
  xxxl: '2rem', // 28px
  xxxxl: '3rem', // 42px
  xxxxxl: '4rem', // 56px
  xxxxxxl: '6rem', // 96px
};

const defaultTheme: BaseTheme = {
  alpha,
  favicons: {
    16: 'favicon-16x16.png',
    32: 'favicon-32x32.png',
    192: 'favicon-192x192.png',
  },
  colors,
  background: {
    primary: colors.secondary,
    secondary: colors.primary,
  },
  border: {
    primary: '#D5D5D5',
    radius: '',
  },
  spacing,
  fonts,
  fontSizes,
  lineHeight,
  buttons: {
    globals: {
      font: fonts.regular,
      color: colors.secondary,
      transform: 'uppercase',
      border: {
        width: '1px',
        style: 'solid',
        radius: '4px',
        color: colors.quaternary,
      },
      variants: {
        large: {
          width: '114px',
          height: '48px',
          padding: '12px 24px',
        },
        medium: {
          width: '114px',
          height: '40px',
          padding: '9px 24px',
        },
        small: {
          width: '114px',
          height: '32px',
          padding: '5px 24px',
        },
      },
      transition: 'all 200ms ease-in-out',
    },
    primary: {
      backgroundColor: colors.quaternary,
      border: {
        width: '1px',
        style: 'solid',
        radius: '4px',
        color: colors.quaternary,
      },
      font: {
        color: colors.secondary,
        weight: 'inherit',
        size: '1rem',
        decoration: 'unset',
        decorationThickness: 'unset',
      },
      state: {
        hover: {
          borderColor: colors.senary,
          backgroundColor: colors.senary,
          fontColor: colors.secondary,
        },
        active: {
          transform: 'scale(0.95, 0.9)',
          font: {
            color: colors.secondary,
          },
        },
        focussed: {
          backgroundColor: 'none',
          fontColor: colors.secondary,
          outline: {
            color: colors.quaternary,
            style: 'solid',
            width: '2px',
            offset: '2px',
          },
        },
        disabled: {
          color: colors.secondary,
          borderColor: `${colors.quaternary}00`,
          backgroundColor: `${colors.quaternary}${alpha.primary}`,
        },
      },
      icon: {
        color: 'unset',
        hover: {
          color: 'unset',
        },
        appended: {
          position: {
            right: '-5px',
          },
          hover: {
            position: {
              right: '-5px',
            },
          },
        },
      },
    },
    secondary: {
      backgroundColor: colors.secondary,
      border: {
        width: '1px',
        style: 'solid',
        radius: '4px',
        color: colors.quaternary,
      },
      font: {
        color: colors.quaternary,
        weight: 'inherit',
        size: '1rem',
        decoration: 'unset',
        decorationThickness: 'unset',
      },
      state: {
        hover: {
          borderColor: colors.quaternary,
          backgroundColor: colors.quaternary,
          fontColor: colors.secondary,
        },
        active: {
          transform: 'scale(0.95, 0.9)',
          font: {
            color: colors.secondary,
          },
        },
        focussed: {
          backgroundColor: 'none',
          fontColor: colors.quaternary,
          outline: {
            color: colors.quaternary,
            style: 'solid',
            width: '2px',
            offset: '2px',
          },
        },
        disabled: {
          color: colors.secondary,
          borderColor: `${colors.quaternary}00`,
          backgroundColor: `${colors.quaternary}${alpha.primary}`,
        },
      },
    },
    tertiary: {
      backgroundColor: colors.secondary,
      border: {
        width: 'none',
        style: 'none',
        radius: 'none',
        color: colors.quaternary,
      },
      font: {
        color: colors.quaternary,
        weight: 'inherit',
        size: 'inherit',
        decoration: 'unset',
        decorationThickness: 'unset',
      },
      state: {
        hover: {
          borderColor: 'none',
          backgroundColor: 'none',
          fontColor: colors.senary,
        },
        active: {
          transform: 'none',
          font: {
            color: `${colors.senary}56`,
          },
        },
        focussed: {
          backgroundColor: 'none',
          fontColor: colors.quaternary,
          outline: {
            color: colors.quaternary,
            style: 'solid',
            width: '2px',
            offset: '2px',
          },
        },
        disabled: {
          color: `${colors.quaternary}${alpha.primary}`,
          borderColor: 'none',
          backgroundColor: 'none',
        },
      },
    },
  },
  form: {
    inputs: {
      radio: radioTheme(lineHeight, colors),
    },
  },
  progress: {
    0: {
      background: colors.primary,
      border: colors.primary,
      bar: '#e9ecef',
    },
    1: {
      background: colors.primary,
      border: colors.primary,
      bar: '#e9ecef',
    },
    border: {
      attributes: '',
      radius: '',
    },
  },
  components: {
    formItem: {
      margin: spacing.xxl,
      label: {
        color: colors.senary,
      },
      hint: {
        color: colors.tertiary,
      },
      input: {
        color: colors.senary,
        padding: '9px 12px',
      },
      lastItem: {
        margin: {
          bottom: 'unset',
        },
      },
    },
    simpleComparator: {
      vehicleSummary: {
        title: {
          font: { mobile: 'initial', desktop: 'initial' },
          margin: { mobile: '0 0 8px', desktop: '' },
        },
        description: {
          font: { mobile: 'initial', desktop: 'initial' },
          margin: { mobile: '0 0 8px', desktop: '' },
        },
        price: {
          font: { mobile: 'initial', desktop: 'initial' },
          margin: { mobile: '0 0 16px', desktop: '' },
        },
        changeVehicleButton: {
          icon: { color: colors.primary },
        },
        attribute: {
          title: { font: 'initial' },
          value: { font: 'initial' },
        },
      },
      runningCosts: {
        title: {
          font: { mobile: 'initial', desktop: 'initial' },
        },
        price: {
          font: { mobile: 'initial', desktop: 'initial' },
        },
        infoLink: {
          icon: { color: colors.primary },
        },
      },
      showcase: {
        attributeBar: {
          backgroundColor: 'hotpink',
          color: 'white',
          flexGap: { mobile: '16px', desktop: '2px' },
          font: { mobile: '500 20px/25px Arial', desktop: '500 20px/25px Arial' },
          minHeight: { mobile: '105px', desktop: '' },
          minWidth: { mobile: '', desktop: '160px' },
          padding: { mobile: '', desktop: '16px' },
        },
        content: {
          padding: { mobile: '0 16px', desktop: '0' },
          vehicle: {
            titles: { flexGap: '16px' },
            title: { font: { mobile: '500 20px/25px Arial', desktop: '500 20px/25px Arial' } },
            value: { font: { mobile: '500 20px/25px Arial', desktop: '500 20px/25px Arial' } },
          },
        },
        footer: {
          flexGap: '16px',
          title: { font: { mobile: '500 20px/25px Arial', desktop: '500 20px/25px Arial' } },
          subTitle: { font: { mobile: '400 16px/21px Arial', desktop: '400 16px/21px Arial' } },
        },
        rowPadding: { mobile: '24px 0 16px', desktop: '32px 0 16px' },
        rowBorder: '1px solid hotpink',
        wrapper: { flexGap: { mobile: '', desktop: '16px' }, padding: { mobile: '', desktop: '16px' } },
      },
      calculations: {
        modal: {
          header: {
            flexGap: '8px',
            font: { mobile: '', desktop: '' },
            backgroundColor: 'hotpink',
            height: { mobile: '98px', desktop: '87px' },
            padding: { mobile: '24px 16px 14px', desktop: '32px 24px 30px' },
          },
          content: { padding: { mobile: '16px', desktop: '16px' } },
          vehicles: {
            gridColumnGap: '14px',
            font: { mobile: '16px', desktop: '24px' },
            borderBottom: '1px solid hotpink',
            padding: { mobile: '16px', desktop: '24px' },
          },
          costs: {
            gridColumnGap: '14px',
            gridRowGap: '10px',
            padding: '16px 0',
            cost: { font: { mobile: '', desktop: '' } },
            title: { font: { mobile: '16px', desktop: '24px' } },
            subTitle: { font: { mobile: '16px', desktop: '24px' } },
          },
          assets: {
            backgroundColor: 'red',
            flexGap: '16px',
            title: { font: { mobile: '', desktop: '' } },
            value: { font: { mobile: '', desktop: '' } },
            padding: { mobile: '16px 0', desktop: '16px' },
            asset: { gridColumnGap: '14px', gridRowGap: '8px' },
          },
          attributes: {
            gridColumnGap: '14px',
            gridRowGap: '5.5px',
            padding: '16px 0',
            borderBottom: '',
            title: { flexGap: '8px', font: { mobile: '', desktop: '' } },
            value: { font: { mobile: '', desktop: '' } },
          },
          footer: {
            flexGap: '4px',
            title: { font: { mobile: '', desktop: '' } },
            body: { font: { mobile: '', desktop: '' } },
            padding: { mobile: '0 16px 16px', desktop: '0 16px 16px' },
          },
        },
      },
      toggle: {
        width: '40px',
        height: '24px',
        backgroundColor: colors.primary,
        insert: {
          width: '20px',
          height: '20px',
        },
      },
      toggler: {
        font: 'initial',
        fontColor: 'black',
        active: {
          font: 'initial',
          fontColor: 'black',
        },
      },
    },
    detailedComparator: {
      attributes: {
        title: { font: { mobile: 'initial', desktop: 'initial' }, marginBottom: { mobile: '14px', desktop: '24px' } },
        value: { padding: { desktop: '16px 0' } },
        tooltipLabel: { font: 'initial', color: colors.tertiary },
        tooltipContent: { font: 'initial', color: colors.tertiary },
        attributeTitle: { font: 'initial' },
        attributeValue: {
          font: 'initial',
          alignItems: { mobile: 'flex-start', desktop: 'flex-start' },
          justifyContent: { mobile: 'center', desktop: 'center' },
          padding: '',
        },
        list: { padding: { mobile: '', desktop: '' }, flexGap: { mobile: '', desktop: '' } },
        borderBottom: `1px solid ${colors.primary}`,
        borderTop: `1px solid ${colors.primary}`,
        note: {
          title: { font: 'initial', marginBottom: '', color: 'initial' },
          body: { font: 'initial', color: 'initial' },
          list: { gap: '16px' },
        },
        marginBottom: { mobile: '26px', desktop: '44px' },
        columnGap: '0',
        rowGap: '0',
        minHeight: { mobile: '0', desktop: '0' },
      },
      tabDefaultHeader: {
        marginBottom: { mobile: '40px', desktop: '60px' },
        header: {
          defaultFont: {
            mobile: `${fontSizes.mobile.heading2} ${fonts.medium}`,
            desktop: `${fontSizes.desktop.heading2} ${fonts.medium}`,
          },
          boldFont: {
            mobile: `${fontSizes.mobile.heading2} ${fonts.medium}`,
            desktop: `${fontSizes.desktop.heading2} ${fonts.medium}`,
          },
          margin: { mobile: '0 0 24px', desktop: '0 0 32px' },
        },
        body: { defaultFont: { mobile: `${fontSizes.lg} initial` } },
      },
    },
    container: {
      maxWidth: '',
      padding: '',
    },
    steps: {
      active: {
        background: '#707070',
      },
      completed: {
        background: '#1C6BBA',
      },
      incomplete: {
        background: '#D5D5D5',
      },
      alignItems: 'flex-start',
      icon: {
        src: {
          desktop: '',
          mobile: '',
        },
        margin: {
          bottom: {
            mobile: '0px',
            tablet: '0px',
          },
          left: {
            mobile: '0px',
            tablet: '0px',
          },
        },
      },
      width: '4rem',
      tablet: {
        width: '4.3rem',
        position: 'center',
        margin: {
          top: '1.7857rem',
          bottom: '1.7857rem',
        },
      },
      desktop: {
        width: '5.8rem',
      },
      borderRadius: '10px',
      step: {
        marginRight: {
          mobile: '10px',
          tablet: '10px',
        },
        height: {
          mobile: '0.3rem',
          tablet: '0.3rem',
        },
      },
      margin: {
        top: '4rem',
        bottom: '4rem',
      },
    },
    landingPage: {
      header: {
        contentBackgroundColor: '#fafafa',
        color: '#fff',
        font: {
          family: 'Arial',
        },
      },
      subtitle: {
        color: '#fff',
        fontSize: '1.429rem',
      },
    },
    footer: {
      padding: {
        top: '1.7857rem',
      },
    },
    pageFooter: {
      border: {
        top: 'none',
      },
      background: {
        color: '#FFF',
      },
      font: {
        family: 'Arial Narrow',
        color: 'red',
        size: '12px',
      },
      padding: {
        right: {
          mobile: '0px',
          tablet: '10px',
        },
        left: {
          mobile: '0px',
          tablet: '10px',
        },
        top: {
          mobile: '10px',
          tablet: '10px',
        },
        bottom: {
          mobile: '10px',
          tablet: '10px',
        },
      },
      iconHeight: '90px',
      linkWidth: '90px',
      nextSteps: {
        background: {
          color: 'inherit',
        },
        font: {
          family: 'inherit',
          color: 'inherit',
        },
        title: {
          padding: '',
          font: {
            family: 'inherit',
            color: 'yellow',
            size: '2.850rem',
          },
        },
        icon: {
          margin: '',
        },
        link: {
          font: {
            family: 'inherit',
          },
          color: 'inherit',
        },
        margin: {
          top: '20px',
          right: '40px',
          bottom: '20px',
          left: '40px',
        },
      },
      copy: {
        border: {
          width: '0',
          style: 'unset',
          color: 'unset',
        },
        font: {
          family: 'inherit',
          color: colors.senary,
        },
        textAlign: 'center',
        lineHeight: '15px',
        padding: {
          top: '0px',
          bottom: '0px',
        },
        width: 'inherit',
        margin: {
          left: 'inherit',
        },
      },
      footerBottom: {
        flex: {
          direction: 'column',
          wrap: 'inherit',
        },
        links: {
          color: colors.senary,
          separator: {
            content: '',
            spacing: {
              mobile: '0px',
              desktop: '0px',
            },
          },
          order: {
            mobile: '1',
            tablet: '1',
          },
          justifyContent: 'center',
          marginBottom: '0px',
          link: {
            margin: {
              top: '4px',
              right: '8px',
              bottom: '8px',
              left: '8px',
            },
            icon: '#',
            textDecoration: 'inherit',
            cursor: 'inherit',
          },
          padding: {
            left: '10px',
            right: '10px',
            bottom: '0px',
          },
          img: {
            padding: {
              right: '0px',
            },
          },
          span: {
            margin: {
              bottom: '8px',
            },
          },
        },
        copy: {
          order: '2',
        },
        disclaimer: {
          lineHeight: 'inherit',
        },
        disclaimerLine1: {
          order: {
            mobile: '3',
            tablet: '3',
          },
          padding: {
            left: '0px',
          },
          img: {
            padding: {
              left: '0px',
            },
          },
          color: 'red',
        },
        disclaimerLine2: {
          marginBottom: {
            mobile: '0px',
            desktop: '0px',
          },
          order: '4',
        },
      },
    },
    form: {
      header: {
        font: '400 14px/18px Arial Medium',
        margin: {
          mobile: `${spacing.xxxl} ${spacing.xxxl} ${spacing.sm} ${spacing.xxxl}`,
          desktop: `${spacing.xxxl} ${spacing.xxxl} ${spacing.sm} ${spacing.xxxl}`,
        },
        padding: spacing.sm,
        backgroundColor: '#eee',
      },
      borderColor: `${colors.secondary}`,
      logo: {
        display: 'initial',
        width: '100px;',
      },
      margin: {
        bottom: '1.143rem',
      },
      paddingLeft: '0px',
      backgroundColor: '#fafafa',
      alignItems: 'center',
      label: {
        fontWeight: 'bold',
        marginBottom: '0.5rem',
        textAlign: 'center',
        fontSize: '16px',
      },
      tab: {
        margin: {
          right: spacing.xxxl,
        },
        color: colors.senary,
        selected: {
          color: colors.senary,
          fontWeight: 'normal',
        },
        header: {
          justifyContent: 'center',
        },
        font: {
          size: '24px',
        },
      },
      select: {
        color: colors.senary,
        backgroundColor: '#fff',
        outlineFocus: `thin dotted ${colors.quaternary}`,
        borderBottom: `1px solid ${colors.primary}`,
        borderBottomFocus: `2px solid ${colors.primary}`,
        label: {
          font: {
            family: 'Arial',
            weight: 'normal',
          },
        },
        font: 'unset',
        height: 'unset',
        icon: {
          top: '4px',
          right: '2px',
          width: '22px',
          height: '22px',
          focusColor: 'unset',
          hoverColor: 'unset',
          iconWidth: '18px',
          iconHeight: '18px',
          color: 'black',
        },
        disabledOpacity: '0.3',
      },
      content: {
        marginTop: '28px',
        marginBottom: '28px',
        marginLeft: '28px',
        marginRight: '28px',
      },
      inputField: {
        width: '210px',
        height: '43px',
        marginBottom: {
          desktop: '0px',
          mobile: '0.5rem',
        },
        border: '1px solid #CDCDCD',
        fontWeight: 'normal',
        fontSize: '24px',
        textAlign: 'center',
        background: '#FBDA2D',
        borderRadius: '0',
        placeholder: {
          color: '#666666',
        },
        registration: {
          font: {
            size: '1rem',
          },
          textAlign: 'left',
        },
      },
      buttons: {
        flexDirection: 'row',
        justifyContent: 'center',
        maxWidth: '100%',
      },
      errorFontSize: '14px',
      errorIconWidth: '40px',
      viewports: {
        mobile: {
          spacing: '0px',
          error: {
            flexDirection: 'row',
            paddingLeft: '0px',
            paddingRight: '0px',
            textMarginLeft: '1rem',
            textMarginTop: '0px',
          },
        },
        desktop: {
          spacing: '0px',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          error: {
            flexDirection: 'row',
            paddingLeft: '0px',
            paddingRight: '0px',
            marginLeft: '0px',
            textMarginLeft: '1rem',
            textMarginTop: '0px',
          },
        },
      },
      border: {
        top: '1px solid #CDCDCD',
        left: '1px solid #CDCDCD',
        right: '1px solid #CDCDCD',
        bottom: '1px solid #CDCDCD',
      },
      disclaimer: {
        before: {
          content: '',
          textDecoration: 'none',
          textDecorationColor: 'none',
        },
      },
      vehicle: {
        font: `400 16px/18px ${fonts.regular}`,
        margin: {
          top: {
            mobile: '10px',
            desktop: '10px',
          },
          bottom: { mobile: '90px', desktop: '90px' },
        },
        loader: {
          fill: '#E5E5E5',
          marginTop: '17px',
        },
        image: {
          fill: '#E5E5E5',
          mobile: {
            transform: 'scale(0.792)',
          },
          width: 'auto',
        },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        body: {
          width: {
            desktop: '450px',
            mobile: '450px',
          },
          background: '#FAFAFA',
        },
        label: {
          background: '#696969',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          width: '31px',
          height: '43px',
          borderRadius: '6px 0px 0px 6px',
          color: '#FFFFFF',
          border: {
            width: 'none',
            style: 'unset',
            color: 'unset',
          },
          font: {
            weight: '800',
            size: '14px',
          },
          padding: {
            left: '2px',
            right: '2px',
            top: '2px',
            bottom: '2px',
          },
          footer: {
            textAlign: 'center',
            padding: {
              top: '23px',
              right: '23px',
              bottom: '7px',
              left: '23px',
            },
            color: '#696969',
          },
          content: {
            input: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
            padding: {
              top: '31px',
              bottom: '10px',
            },
          },
          vrm: {
            margin: {
              right: '13px',
            },
          },
        },
        group: {
          justifyContent: 'center',
          display: 'flex',
          gap: spacing.xs,
          margin: {
            top: '62px',
          },
          mobile: {
            margin: {
              top: '29px',
            },
          },
        },
        btn: {
          width: '137px',
          disable: {
            background: '#D0D0D0',
            color: '#696969',
          },
          hover: {
            background: '#4F1CBA',
            color: '#FFFFFF',
          },
        },
        errorMessage: {
          color: '#bf2012',
          font: {
            size: '14px',
            weight: '400',
          },
        },
      },
    },
    buttons: {
      base: {
        font: {
          family: 'Arial Narrow',
        },
        borderRadius: '0px',
        fontWeight: '200',
        backgroundColor: 'red',
        fontColor: '#ffffff',
        borderWidth: '0.1rem',
        borderStyle: 'solid',
        borderColor: '#ffffff',
        transition: 'all 200ms ease-in-out',
        minHeight: 'inherit',
        minWidth: 'inherit',
        mobile: {
          minHeight: 'inherit',
          minWidth: 'inherit',
        },
        hover: {
          fontColor: 'white',
          backgroundColor: 'darkred',
          borderColor: 'darkred',
        },
        icon: {
          color: 'unset',
          hover: {
            color: 'unset',
          },
          appended: {
            position: {
              right: '-5px',
            },
            hover: {
              position: {
                right: '-5px',
              },
            },
          },
        },
      },
      primary: {
        borderRadius: '0px',
        fontWeight: '200',
        backgroundColor: 'red',
        fontColor: '#ffffff',
        borderWidth: '0.1rem',
        borderStyle: 'solid',
        borderColor: '#ffffff',
        transition: 'all 200ms ease-in-out',
        minHeight: 'inherit',
        minWidth: 'inherit',
        mobile: {
          minHeight: 'inherit',
          minWidth: 'inherit',
        },
        hover: {
          fontColor: 'white',
          backgroundColor: 'darkred',
          borderColor: 'darkred',
        },
        icon: {
          color: 'unset',
          hover: {
            color: 'unset',
          },
          appended: {
            position: {
              right: '-5px',
            },
            hover: {
              position: {
                right: '-5px',
              },
            },
          },
        },
      },
      secondary: {
        borderRadius: 'unset',
        fontWeight: 'normal',
        backgroundColor: 'green',
        fontColor: '#ffffff',
        borderWidth: '0.1rem',
        borderStyle: 'solid',
        borderColor: '#ffffff',
        transition: 'none',
        minHeight: 'inherit',
        minWidth: 'inherit',
        mobile: {
          minHeight: 'inherit',
          minWidth: 'inherit',
        },
        hover: {
          fontColor: 'white',
          backgroundColor: 'blue',
          borderColor: 'blue',
        },
        icon: {
          color: 'unset',
          hover: {
            color: 'unset',
          },
          appended: {
            position: {
              right: '0px',
            },
            hover: {
              position: {
                right: '0px',
              },
            },
          },
        },
      },
      tertiary: {
        borderRadius: '0px',
        fontWeight: '200',
        backgroundColor: 'red',
        fontColor: '#ffffff',
        borderWidth: '0.1rem',
        borderStyle: 'solid',
        borderColor: '#ffffff',
        transition: 'none',
        minHeight: 'inherit',
        minWidth: 'inherit',
        mobile: {
          minHeight: 'inherit',
          minWidth: 'inherit',
        },
        hover: {
          fontColor: 'white',
          backgroundColor: 'darkred',
          borderColor: 'darkred',
        },
        icon: {
          color: 'unset',
          hover: {
            color: 'unset',
          },
          appended: {
            position: {
              right: '0px',
            },
            hover: {
              position: {
                right: '0px',
              },
            },
          },
        },
      },
      invertedPrimary: {
        borderRadius: '0px',
        fontWeight: '200',
        backgroundColor: 'red',
        fontColor: '#ffffff',
        borderWidth: '0.1rem',
        borderStyle: 'solid',
        borderColor: '#ffffff',
        transition: 'all 200ms ease-in-out',
        minHeight: 'inherit',
        minWidth: 'inherit',
        mobile: {
          minHeight: 'inherit',
          minWidth: 'inherit',
        },
        hover: {
          fontColor: 'white',
          backgroundColor: 'darkred',
          borderColor: 'darkred',
        },
        icon: {
          color: 'unset',
          hover: {
            color: 'unset',
          },
          appended: {
            position: {
              right: '-5px',
            },
            hover: {
              position: {
                right: '-5px',
              },
            },
          },
        },
      },
      linkAsPrimary: {
        borderRadius: '0px',
        fontWeight: '200',
        backgroundColor: 'transparent',
        fontColor: colors.senary,
        borderWidth: '0',
        borderStyle: 'none',
        borderColor: '#ffffff',
        transition: 'all 200ms ease-in-out',
        minHeight: 'inherit',
        minWidth: 'inherit',
        mobile: {
          minHeight: 'inherit',
          minWidth: 'inherit',
        },
        hover: {
          fontColor: '#4c4c4c',
          backgroundColor: 'transparent',
          borderColor: 'transparent',
        },
        icon: {
          color: 'unset',
          hover: {
            color: 'unset',
          },
          appended: {
            position: {
              right: '-5px',
            },
            hover: {
              position: {
                right: '-5px',
              },
            },
          },
        },
      },
      disable: {
        opacity: '0.3',
        backgroundColor: '#284E80',
        color: 'none',
        hover: {
          color: '#FFFFFF',
        },
      },
      back: {
        secondary: {
          background: 'none',
          color: '#213E8F',
        },
        icon: {
          source: '/chevron-left.svg',
        },
      },
      backChevon: {
        padding: {
          top: '0.857rem',
          right: '1.142rem',
          bottom: '0.857rem',
          left: '1.142rem',
        },
        margin: {
          top: '18px',
        },
        img: {
          height: '10px',
          padding: {
            right: '6px',
          },
        },
        alignItem: 'center',
        text: {
          fontSize: '1rem',
          lineHeight: 'normal',
        },
        width: {
          mobile: '82px',
          tablet: '145px',
        },
      },
      evTree: {
        width: {
          mobile: '272px',
          tablet: '145px',
        },
        text: {
          lineHeight: 'normal',
        },
      },
      trip: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #213E8F',
        color: '#213E8F',
        lineHeight: '28px',
        height: '52px',
        margin: {
          right: '20px',
          bottom: '20px',
        },
        hover: {
          backgroundColor: '#FFFFFF',
        },
        before: {
          content: '<',
          margin: {
            right: '3px',
          },
          color: '#FFFFFF !important',
        },
        outlineOffset: '3px',
      },
    },
    modal: {
      closeButton: {
        border: '',
        borderRadius: '',
        position: {
          top: spacing.xs,
          right: spacing.xs,
        },
      },
      backgroundColor: '#fff',
      background: {
        color: '#333333',
      },
      back: {
        width: '1.5222rem',
        height: '1.2857rem',
      },
    },
    testTypeInformation: {
      padding: { mobile: '12px', desktop: '60px 52px 38px' },
      header: {
        color: 'unset',
        font: 'unset',
        margin: { mobile: '0 0 12px', desktop: '0 0 40px' },
        height: { mobile: '44px', desktop: 'unset' },
      },
      title: {
        color: 'black',
        font: '',
        margin: '0 0 8px',
        justifyContent: 'flex-start',
      },
      body: {
        color: '#707070',
        font: '14px/24px Arial',
        margin: '0 0 50px',
      },
      link: {
        font: {
          lineHeight: '18px',
        },
      },
      button: {
        width: 'unset',
      },
    },
    dataTableColumn: {
      width: {
        mobile: '50%',
        desktop: '33.33%',
      },
    },
    vehicleConfirm: {
      image: {
        width: {
          mobile: '70%',
          desktop: '100%',
        },
      },
      findAnotherVehicle: {
        font: `400 1.143rem/18px ${fonts.regular}`,
        textDecoration: 'none',
        color: '#1c6bba',
        cursor: 'pointer',
        marginTop: '12px',
      },
      details: {
        vrm: {
          font: '400 16px/18px Arial Narrow',
        },
        description: {
          font: '400 16px/18px Arial Bold',
          color: colors.tertiary,
          margin: {
            top: '12px',
          },
        },
      },
      currentMileage: {
        maxWidth: '447px',
        width: '100%',
        background: '#fafafa',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '7px',
        description: {
          font: `400 1rem/18px ${fonts.regular}`,
          textAlign: 'center',
          maxWidth: {
            mobile: '332px',
            desktop: '332px',
          },
          color: '#707070',
          marginTop: '8px',
        },
        form: {
          label: {
            textAlign: 'center',
            marginBottom: '7px',
            font: `400 16px/18px ${fonts.regular}`,
            alignment: 'center',
            color: {
              default: colors.primary,
              optional: colors.primary,
            },
          },
          input: {
            height: '43px',
            width: {
              mobile: '177px',
              desktop: '177px',
            },
            textAlign: 'center',
            font: `400 24px/18px ${fonts.regular}`,
            color: colors.senary,
            border: {
              width: '1px',
              style: 'solid',
              color: '#cdcdcd',
            },
            padding: '9px 12px',
          },
        },
      },
      actionButtons: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '22px',
        marginTop: spacing.xxxl,
        width: {
          mobile: 'auto',
          desktop: 'auto',
        },
        alignItems: 'center',
        cancel: {
          margin: '0 0 0 18px',
        },
      },
    },
    vehicleOption: {
      superscript: { font: 'unset' },
      showIcon: {
        prepend: true,
        append: false,
      },
      minHeight: '',
      padding: '',
      unSelected: { backgroundColor: 'white' },
      backgroundColor: 'white',
      width: {
        mobile: '33.333%',
        desktop: '33.333%',
      },
      border: 'none',
      borderRadius: 'initial',
      changeVehicle: {
        mobile: {
          justification: 'center',
        },
      },
      compareButton: {
        height: '',
        padding: '',
        iconHeight: '',
        iconWidth: '',
      },
      vehicleSummaryDetails: {
        backgroundColor: 'white',
        order: 'auto',
        alignSelf: 'auto',
        display: 'none',
        link: {
          display: 'inline',
          height: '34px',
        },
        font: { mobile: 'unset', desktop: 'unset' },
        color: 'unset',
      },
      image: {
        mobile: {
          height: '60px',
          width: 'auto',
          stickyHeight: '30px',
          stickyWidth: 'auto',
        },
        height: '200px',
        width: 'auto',
      },
      price: {
        fontFamily: 'inherit',
        fontWeight: 'bold',
      },
      hr: {
        color: 'transparent',
      },
      testStandard: {
        link: {
          color: '#1c6bba',
        },
        wrapper: {
          order: '4',
          width: '100%',
          color: '#707070',
          padding: '16px 0 0',
        },
        font: 'unset',
        type: { font: 'unset' },
      },
    },
    vehicleSummary: {
      icon: '/close.svg',
      width: '14px',
      height: '30px',
      title: { font: { mobile: 'unset', desktop: 'unset' }, margin: { mobile: '0 0 8px', desktop: '0 0 4px' } },
      description: { margin: { mobile: '0 0 5px', desktop: '0 0 5px' } },
      changeButton: { margin: { mobile: '', desktop: '' } },
      shopButton: { margin: { mobile: '14px 0 0', desktop: '14px 0 0' } },
      closeButton: { icon: { backgroundColor: 'black' } },
    },
    findVehicleForm: {
      font: 'inherit',
      padding: {
        mobile: spacing.xxxl,
        desktop: spacing.xxxl,
      },
      buttons: {
        margin: { mobile: '56px 0 0', desktop: '70px 0 0' },
        first: {
          margin: { mobile: '0 1rem 0 0', desktop: '0 1rem 0 0' },
        },
      },
    },
    vehicleOptionsForm: {
      margin: `${spacing.xxxl} 3rem`,
      padding: '60px 38px 46px 38px',
      mobile: {
        padding: {
          top: '36px',
          right: '30px',
          bottom: '26px',
          left: '30px',
        },
      },
      title: {
        font: '400 24px/18px Arial Medium',
        color: colors.senary,
      },
      description: {
        mobile: {
          margin: {
            top: '25px',
          },
        },
        font: '400 16px/20px Arial',
        color: colors.senary,
        width: '475px',
        marginTop: '50px',
      },
      options: {
        mobile: {
          margin: {
            top: '24px',
          },
        },
        option: {
          margin: {
            top: '77px',
            right: '33px',
            bottom: '26px',
          },
        },
        container: {
          border: '1px solid #707070',
          borderRadius: '20px',
          width: {
            desktop: '221px',
            mobile: '150px',
          },
          height: '198px',
          hover: {
            border: `1px solid ${colors.senary}`,
            padding: {
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            },
            backgroundColor: 'unset',
            color: colors.senary,
          },
          icon: {
            registrationNumber: {
              image: {
                default: 'url(/assets/components/add-vehicle/registration-number.svg)',
                hover: 'url(/assets/components/add-vehicle/registration-number-hover.svg)',
                height: '42px',
              },
            },
            vehiclesList: {
              image: {
                default: 'url(/assets/components/add-vehicle/vehicle-options-list.svg)',
                hover: 'url(/assets/components/add-vehicle/vehicle-options-list-hover.svg)',
                height: '42px',
              },
            },
            marginTop: '42px',
          },
          description: {
            font: '400 14px/18px Arial Narrow',
            color: colors.senary,
            marginTop: '40px',
          },
        },
      },
      button: {
        mobile: {
          marginTop: '30px',
        },
        marginTop: { mobile: '117px', desktop: '117px' },
        width: '177px',
        height: '50px',
        background: colors.senary,
        color: '#FFFFFF',
        hover: {
          background: '#4F1CBA',
          color: '#FFFFFF',
        },
      },
    },
    attributes: {
      alignItems: 'center',
      icon: {
        color: '#1C6BBA',
      },
      title: {
        color: '#707070',
      },
      tile: {
        hover: {
          borderColor: '',
          backgroundColor: '',
          outline: '',
        },
      },
    },
    tabHeader: {
      color: { mobile: 'red', desktop: 'red' },
      font: '',
      backgroundColor: { mobile: colors.primary, desktop: colors.primary },
      chevronIcon: { color: colors.secondary, height: '11px', width: '19px' },
      height: { mobile: '48px', desktop: '48px' },
      flexGap: 'unset',
      justifyContent: 'center',
      borderBottom: 'unset',
      margin: { mobile: 'unset', desktop: 'unset' },
      minHeight: '9.5rem',
      spacing: {
        xxxl: '2rem',
      },
      item: {
        font: '',
        hover: {
          borderBottomColor: `${colors.senary}`,
        },
        minHeight: '7.625rem',
        minWidth: { mobile: '162px', desktop: '200px' },
        textTransform: '',
        border: {
          borderTop: '1px solid #ccc',
          borderLeft: '1px solid #ccc',
          borderRight: '1px solid #ccc',
          borderBottom: '1px solid #ccc',
          lastItem: {
            borderRight: '1px solid #ccc',
          },
        },
        padding: { desktop: `${spacing.md} 0 ${spacing.sm}` },
        svg: {
          fill: '#707070',
          stroke: 'none',
        },
        width: { desktop: '200px' },
      },
      itemDisabled: {
        border: {
          borderRight: '1px solid #ccc',
        },
        svg: {
          fill: '#707070',
          stroke: 'none',
        },
      },
      itemActive: {
        underlineSpacing: '11px',
        underline: `2px solid ${colors.senary}`,
        backgroundColor: '',
        color: { mobile: '', desktop: '' },
        border: {
          borderTop: '1px solid #ccc',
          borderLeft: '1px solid #ccc',
          borderRight: '1px solid #ccc',
          borderBottom: '1px solid #ccc',
        },
        svg: {
          fill: colors.primary,
          stroke: 'none',
          opacity: '0.5',
        },
        active: {
          color: colors.senary,
        },
      },
      heading: {
        borderBottom: '1px solid #D5D5D5',
      },
    },
    toggle: {
      position: 'left',
      font: {
        color: colors.senary,
        family: fonts.regular,
      },
      margin: {
        mobile: {
          top: '8px',
          bottom: '8px',
          left: 'unset',
        },
        desktop: {
          left: 'unset',
        },
      },
      button: {
        margin: {
          mobile: {
            right: '8px',
            left: '8px',
          },
          desktop: {
            right: '12px',
            left: '12px',
          },
        },
        padding: {
          mobile: {
            top: '12px',
            bottom: '12px',
          },
          desktop: {
            top: '12px',
            bottom: '12px',
          },
        },
        border: {
          bottom: {
            width: '2px',
            style: 'solid',
            color: 'transparent',
          },
        },
        focus: {
          outline: `thin dotted ${colors.quaternary}`,
        },
        active: {
          font: {
            color: 'unset',
          },
          border: {
            color: colors.senary,
          },
        },
      },
    },
    prompt: {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      margin: spacing.xxxxl,
      content: {
        margin: spacing.lg,
      },
      font: {
        family: 'Arial',
        weight: 'bold',
      },
      buttons: {
        width: 'auto',
        direction: 'row',
        margin: spacing.xxl,
        button: {
          margin: `0 ${spacing.sm} 0`,
        },
      },
    },
    ownLeaseToggle: {
      wrapper: {
        gap: '24px',
        font: fonts.regular,
      },
    },
    runningCostTable: {
      margin: { mobile: '60px 0 0', desktop: '60px 0 0' },
      attributesHeading: {
        backgroundColor: '#F2F2F2',
        title: {
          font: {
            mobile: 'Arial',
            desktop: 'Arial',
          },
          padding: {
            mobile: '0 0 16px',
            desktop: '0',
          },
          flexDirection: { mobile: 'column', desktop: 'column' },
          alignItems: { mobile: 'flex-start', desktop: 'flex-start' },
          gap: { mobile: '0', desktop: '0' },
          borderBottom: { mobile: 'none', desktop: 'none' },
        },
        superscript: { font: 'unset' },
        subheading: {
          fontFamily: 'Arial',
          font: 'Arial',
          color: colors.primary,
        },
        value: {
          font: {
            mobile: 'bold Arial',
            desktop: 'bold Arial',
          },
          borderBottom: { mobile: 'none', desktop: 'none' },
          padding: { mobile: '16px 0', desktop: '0' },
          justifyContent: { mobile: 'initial', desktop: 'initial' },
        },
        font: {
          family: 'Arial',
          weight: 'bold',
        },
        MobileAlignItems: 'center',
        DesktopAlignItems: 'center',
        headerBorder: {
          borderBottom: { mobile: '0px', desktop: '0px', mobileFleetManagerSelected: 'none' },
        },
        margin: { mobile: '16px 0 0', desktop: '13px 0 0' },
      },
      tableRow: {
        border: '1px solid #707070',
        borderTop: '1px solid #D5D5D5',
      },
      tableCell: {
        borderBottom: '0px',
        margin: '0',
      },
      inputCell: {
        borderTop: '1px solid #CDCDCD',
        borderBottom: '1px solid #CDCDCD',
        borderLeft: '1px solid #CDCDCD',
        borderRight: '1px solid #CDCDCD',
        borderRadius: '0',
        width: { mobile: '83px', desktop: '86px' },
        height: { mobile: '48px', desktop: '48px' },
        focus: {
          outline: 'outline-width',
          borderBottom: '0px',
        },
        textAlign: 'center',
        padding: '0',
      },
      title: {
        mobile: {
          margin: {
            bottom: '2rem',
            left: '1.142rem',
          },
        },
        tablet: {
          margin: {
            bottom: '2rem',
            left: '1.142rem',
          },
        },
      },
    },
    productCard: {
      title: {
        font: {
          size: '20px',
          color: '#141414',
          family: 'Arial',
        },
      },
      intro: {
        font: {
          size: '16px',
          color: '#141414',
        },
      },
      buttons: {
        font: {
          family: 'Arial',
        },
      },
    },
    recalculateForm: {
      backgroundColor: 'unset',
      wrapper: {
        border: {
          open: {
            width: 'unset',
            style: 'unset',
            color: 'unset',
            radius: 'unset',
          },
          closed: {
            width: 'unset',
            style: 'unset',
            color: 'unset',
          },
        },
        toggle: {
          height: 'unset',
          color: 'inherit',
          textDecoration: 'unset',
          iconSize: '12',
          font: '',
        },
        margin: {
          top: 'unset',
          bottom: 'unset',
        },
        form: {
          mobileTitle: {
            font: fontSizes.xxl,
          },
          padding: `${spacing.xl} ${spacing.xxl}`,
          border: {
            width: 'unset',
            style: 'unset',
            color: 'unset',
            radius: 'unset',
          },
          inner: {
            padding: [`0 ${spacing.lg}`, `0 ${spacing.lg}`],
          },
          close: {
            border: {
              bottom: '',
            },
            minWidth: '',
          },
          cta: {
            alignSelf: 'unset',
          },
        },
      },
      input: {
        height: '40px',
        padding: '',
      },
    },
    infoPopup: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.25)',
      borderRadius: '0',
      messageRunningCosts: 'inherit',
      background: '#FAFAFA',
      arrowBackground: '#FAFAFA',
      icon: '',
      color: '#FAFAFA',
      minWidth: '300px',
      maxWidth: '300px',
      padding: '16px',
      text: {
        width: '300px',
        color: '#707070',
        textAlign: 'center',
        fontSize: '1rem',
        whiteSpace: 'unset',
        font: 'unset',
      },
      close: {
        width: '0px',
        display: 'none',
      },
      message: {
        left: '155px',
        runningCost: {
          width: '50px',
          left: '52vw',
        },
      },
      border: {
        color: '#fff',
      },
      marginTop: '0px',
    },
    monthlyCost: {
      color: '#707070',
      font: {
        size: '1.2857rem',
        family: 'Arial',
        weight: 'normal',
      },
      heading: {
        title: {
          font: { mobile: 'initial' },
          color: colors.primary,
          padding: { mobile: '', desktop: '' },
        },
        description: {
          color: '#707070',
          font: { mobile: 'initial' },
        },
      },
      padding: {
        left: '2.571rem',
      },
      inputs: {
        margin: '1.2857rem 0',
      },
      totals: {
        ownTypeOwn: {
          backgroundColor: { mobile: colors.primary, desktop: colors.primary },
          innerBackgroundColor: 'transparent',
          padding: {
            mobile: '16px 0',
            desktop: '16px',
          },
          heading: { padding: { mobile: '0 0 16px', desktop: '0 0 16px' } },
          titleAndValuesGap: '24px',
          title: {
            mobile: {
              font: 'initial',
              margin: '24px 0 0',
            },
          },
          value: {
            mobile: {
              font: 'initial',
              margin: '16px 0 0',
            },
          },
        },
        heading: {
          padding: { mobile: '0 0 16px', desktop: '0 0 16px' },
          flexGap: { mobile: '', desktop: '' },
        },
        value: {
          padding: { mobile: '0 0 16px', desktop: '0 0 16px' },
        },
        gap: { mobile: '24px', desktop: '24px' },
      },
    },
    hero: {
      'landing-page': heroTheme,
      'detailed-comparator': heroTheme,
    },
    titleAndDescription: titleAndDescriptionTheme,
    businessToggle: {
      button: {
        font: 'initial',
        border: {
          top: 'none',
          right: 'none',
          bottom: '1px solid #fff',
          left: 'none',
        },
        hover: {
          borderBottom: '1px solid #ccc',
        },
        isSelected: {
          borderBottom: '1px solid #ccc',
        },
      },
      margin: {
        mobile: '0 0 24px',
        desktop: '0 0 24px',
      },
    },
    survey: {
      layout: {
        content: {
          width: '496px',
          maxWidth: 'auto',
        },
        header: {
          padding: {
            left: '24px',
            right: '24px',
          },
        },
      },
      question: {
        width: 'initial',
        title: {
          color: colors.senary,
          lineHeight: 'initial',
        },
        inputType: {
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '280',
          margin: {
            top: '1rem',
            right: '0px',
            bottom: '1rem',
            left: '0px',
          },
          color: colors.senary,
          input: {
            display: 'block',
            height: '54px',
            backgroundColor: '#FFFFFF',
            border: '1px solid #C5D6EC',
            borderRadius: '8px',
            padding: {
              top: '13px',
              right: '39px',
              bottom: '13px',
              left: '13px',
            },
            font: {
              size: '20px',
            },
            lineHeight: '28px',
            active: {
              outline: 'none',
            },
            color: '#333333',
            destination: {
              width: '133px',
            },
            tripsCount: {
              width: '96px',
            },
          },
          label: {
            padding: {
              top: '20px',
              bottom: '5px',
            },
            font: {
              size: '20px',
            },
            lineHeight: '28px',
            color: colors.senary,
          },
          error: {
            margin: {
              top: '5px',
            },
            font: {
              size: '20px',
            },
            lineHeight: '28px',
            color: '#DB3624',
            width: {
              max: '258px',
            },
          },
          form: {
            email: {
              width: '0px',
              margin: {
                bottom: '0xp',
              },
            },
            vrm: {
              width: '0px',
            },
            homePostcode: {
              width: {
                mobile: '280px',
                desktop: '280px',
              },
            },
            regularDestination: {
              width: {
                mobile: '280px',
                desktop: '280px',
              },
            },
          },
        },
        option: {
          border: '0px',
          width: '272px',
          font: {
            size: '1.142rempx',
          },
          padding: {
            tablet: '0px',
            mobile: '0px',
          },
          margin: {
            desktop: {
              bottom: '16px',
            },
            mobile: {
              bottom: '14px',
            },
          },
          borderRadius: '0px',
          tablet: {
            width: '400px',
          },
          active: {
            background: '#ffffff',
          },
          input: {
            display: 'none',
            label: {
              border: '1px solid #ccc',
              lineHeight: 'normal',
              padding: {
                mobile: {
                  top: '1rem',
                  right: '1rem',
                  bottom: '1rem',
                  left: '1rem',
                },
                tablet: {
                  top: '0rem',
                  right: '0rem',
                  bottom: '0rem',
                  left: '2.25rem',
                },
              },
            },
            checked: {
              border: '2px solid #ccc',
              fontWeight: {
                tablet: 'normal',
                mobile: 'bold',
              },
            },
            radio: {
              width: {
                tablet: '1rem',
                mobile: '1rem',
              },
              height: {
                tablet: '1rem',
                mobile: '1rem',
              },
              display: {
                mobile: 'none',
                tablet: 'inline-block',
              },
              border: {
                radius: '50%',
                style: 'solid',
                width: '0.1rem',
                color: 'gray',
              },
              borderChecked: {
                background: 'radial-gradient(#ccc 0%, #ccc 40%, transparent 50%, transparent)',
                color: colors.primary,
              },
            },
            verticalRadio: {
              color: '#3357B8',
              margin: {
                right: '30px',
                bottom: {
                  desktop: '120px',
                  mobile: '142px',
                },
              },
              border: '1px solid #3357B8',
              padding: {
                top: '20px',
                right: '0',
                bottom: '20px',
                left: '0',
              },
            },
          },
          alignItems: 'none',
          color: colors.senary,
        },
        label: {
          active: {
            webkitTapHighLightColor: 'none',
          },
        },
        description: {
          fontFamily: 'Arial Medium',
          fontSize: {
            lg: '1.142rem',
            xxl: '1.7857rem',
          },
          color: '#333333',
          margin: {
            desktop: {
              top: '13px',
            },
            mobile: {
              top: '13px',
            },
          },
          footer: {
            margin: {
              top: '0px',
            },
            font: {
              size: '0px',
              weight: '0',
            },
            lineHeight: '0px',
            color: '#333333',
          },
        },
        link: {
          fontSize: '20px',
          color: '#3357B8',
          margin: {
            top: '13px',
          },
          lineHeight: '28px',
        },
        fullMapLink: {
          fontSize: '20px',
          color: '#3357B8',
          margin: {
            top: '20px',
          },
          lineHeight: '28px',
          displayDesktop: 'none',
        },
        options: {
          padding: {
            table: {
              top: '54px',
              right: '0',
              bottom: '40px',
              left: '0',
            },
            mobile: {
              top: '14px',
              right: '0',
              bottom: '0',
              left: '0',
            },
          },
          minHeight: {
            mobile: '52px',
            tablet: '52px',
          },
        },
        multiInput: {
          padding: {
            table: {
              top: '20px',
              right: '0',
              bottom: '32px',
              left: '0',
            },
            mobile: {
              top: '20px',
              right: '0',
              bottom: '32px',
              left: '0',
            },
          },
        },
        boxTrip: {
          margin: {
            top: '-32px',
            bottom: '52px',
          },
          display: 'flex',
        },
      },
      startPage: {
        margin: {
          top: '1.7857rem',
          right: '20px',
          bottom: '1.7857rem',
          left: '20px',
        },
        width: '280px',
        height: '383px',
        header: {
          title: {
            margin: {
              top: '3rem',
              bottom: '.857rem',
            },
            font: {
              color: 'initial',
              size: '41px',
            },
            lineHeight: '49px',
          },
          subtitile: {
            font: {
              color: '#333333',
              size: '20px',
            },
            lineHeight: '28px',
          },
        },
        button: {
          padding: {
            top: '.857rem',
            right: '1.3857rem',
            bottom: '.857rem',
            left: '1.3857rem',
          },
          font: {
            size: '1.3857rem',
          },
          lineHeight: 'normal',
          outlineOffset: '2px',
        },
        tabletView: {
          width: '272px',
          height: '385px',
          justifyContent: 'center',
          header: {
            minWidth: '400px',
          },
          margin: {
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          },
        },
        outlineOffset: '2px',
      },
      MapModal: {
        content: {
          height: '97.87vh',
          maxHeight: 'calc(100% - 20px)',
        },
        inner: {
          borderRadius: '8px',
          maxWidth: 'calc(100% - 20px)',
          maxHeight: 'calc(100vh - 20px)',
        },
      },
    },
    results: {
      enhancedResults: {
        header: {
          color: '#707070',
        },
        imgSrc: '',
        additionalLink: {
          font: {
            size: '20px',
            weight: '400',
            lineHeight: '28px',
            color: '#333333',
          },
        },
        section: {
          font: {
            color: '#333333',
          },
        },
      },
      attributeHeader: {
        justifyContent: 'center',
        border: {
          bottom: 'solid 1px #fff',
        },
        attribute: {
          minHeight: '130px',
          padding: {
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          },
          evenAttribute: {
            background: {
              color: 'transparent',
            },
            border: {
              radius: {
                topLeft: '0px',
                topRight: '0px',
                bottomRight: '0px',
                bottomLeft: '0px',
              },
            },
          },
          left: {
            flex: 'inherit',
            width: '172px',
            tabletView: {
              width: '172px',
            },
            desktopView: {
              width: '172px',
            },
          },
          icon: {
            justifyContent: 'flex-end',
            alignItems: 'initial',
            flex: '1 0 25%',
            globalSvg: {
              fill: colors.primary,
              transform: 'scale(0.66)',
            },
          },
          text: {
            title: {
              margin: {
                bottom: '4px',
              },
              font: {
                weight: 'bold',
                size: 'medium',
              },
              textAlign: 'center',
            },
          },
        },
      },
      attribute: {
        figureAmount: {
          font: 'initial',
        },
        figureUnit: {
          font: 'initial',
          color: '#707070',
        },
        icon: {
          globalSvg: {
            fill: colors.primary,
            transform: 'scale(0.5)',
          },
          infoPopup: {
            margin: {
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            },
          },
        },
      },
      vehicle: {
        margin: {
          left: '0px',
          right: '0px',
          top: '16px',
        },
        title: {
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'between',
          alignItems: 'stretch',
          padding: {
            top: '0px',
            right: '15px',
            bottom: '0px',
            left: '15px',
          },
          model: {
            font: 'initial',
          },
          fuelAndTransmission: {
            display: 'none',
            marginLeft: '20px',
            fontSize: '13px',
            lintHeight: '18px',
            color: colors.primary,
          },
          price: {
            display: 'flex',
            startingFrom: {
              font: 'initial',
            },
            value: {
              fontMobile: 'initial',
              fontDesktop: 'initial',
            },
          },
          font: { desktop: 'initial', mobile: 'initial' },
        },
        inner: {
          attributeAmount: 4,
          border: {
            tabletView: {
              bottom: {
                width: '0.5px',
                style: 'dashed',
                color: '#5E7D95',
              },
              top: {
                width: '0.5px',
                style: 'dashed',
                color: '#5E7D95',
              },
            },
            top: {
              width: '1px',
              style: 'dashed',
              color: '#70707050',
            },
            bottom: {
              width: '1px',
              style: 'dashed',
              color: '#707070',
            },
          },
          header: {
            image: {
              maxWidth: 'auto',
              maxHeight: '225px',
              width: 'auto',
              height: 'auto',
              tabletView: {
                width: 'auto',
              },
              desktopView: {
                width: 'auto',
              },
            },
            showDetail: {
              border: {
                top: '1px solid #D5D5D550',
              },
            },
          },
          attribute: {
            border: {
              bottom: '#70707050',
            },
            odd: {
              background: {
                color: colors.primary,
              },
            },
            title: {
              font: 'initial',
            },
            amount: {
              font: 'initial',
            },
            unit: {
              font: 'initial',
            },
            marginBottom: '12px',
          },
          icon: {
            fill: colors.primary,
          },
        },
        borderBottom: `1px solid ${colors.primary}`,
      },
      layout: {
        margin: {
          top: '0',
          right: '0',
          bottom: '0',
        },
        padding: {
          mobile: '20px',
          desktop: '0',
        },
      },
      filters: {
        padding: {
          top: '32px',
          right: '16px',
          bottom: '32px',
          left: '16px',
        },
        margin: {
          right: '2rem',
        },
        borderRadius: '30px',
        background: {
          color: '#F2F2F3',
        },
        width: {
          desktop: '280px',
          mobile: '100%',
        },
        height: '100%',
        fontSize: '1.85714rem',
        accordion: {
          header: {
            padding: '12px',
          },
          content: {
            padding: {
              top: '0',
              right: '0',
              bottom: '0',
              left: '12px',
            },
          },
          maxWidth: '280px',
          fontSize: '1.42857rem',
          divider: {
            border: {
              top: '1px solid #DAD9F1',
              right: '0',
              bottom: '0',
              left: '0',
            },
            margin: {
              top: '0',
              right: '0',
              bottom: '0',
              left: '0',
            },
          },
          label: {
            color: colors.senary,
          },
        },
        option: {
          marginRight: '0.5rem',
        },
      },
      noResults: {
        padding: '20px',
        font: {
          size: {
            desktop: '41px',
            mobile: '30px',
          },
          weight: '400',
          lineHeight: {
            desktop: '49px',
            mobile: '38px',
          },
        },
        minHeight: '1480px',
      },
      personalFigures: {
        padding: {
          desktop: '0',
          mobile: '20px',
        },
        margin: {
          top: '40px',
        },
        personalFigure: {
          padding: '20px',
          margin: {
            right: '30px',
            bottom: '30px',
          },
          background: '#FDDCD8',
          borderRadius: '30px',
          height: '166px',
          title: {
            font: {
              size: '26px',
              weight: '400',
              lineHeight: '36px',
            },
            padding: {
              mobile: '20px 0 0 0',
              highRes: '0',
            },
          },
          content: {
            font: {
              size: '41px',
              weight: '700',
              lineHeight: '60px',
            },
          },
          unit: {
            font: {
              size: '26px',
              weight: '400',
              lineHeight: '36px',
            },
          },
          financialSavings: {
            background: 'url(/assets/motability/common/financial-saving.svg) #99DBCA no-repeat;',
          },
          co2Savings: {
            background: 'background: url(/assets/motability/common/co2-saving.svg) #DAD9F1 no-repeat;',
          },
          InfoPopup: {
            top: '20px',
            right: '20px',
          },
        },
      },
      footer: {
        heading: {
          font: {
            size: '1.142rem',
            weight: '700',
          },
          margin: {
            bottom: '1rem',
          },
        },
        text: {
          font: {
            size: '1.142rem',
          },
          color: '#707070',
        },
        margin: {
          desktop: '48px 0 0',
          mobile: '24px 0 12px',
        },
      },
      findYourCar: {
        margin: {
          top: '52px',
        },
        border: {
          top: '1px solid #DAD9F1',
          right: '0',
          bottom: '0',
          left: '0',
        },
        padding: {
          desktop: '0',
          mobile: '0 20px',
        },
        title: {
          font: {
            size: '41px',
            weight: '400',
            lineHeight: '49px',
          },
          margin: {
            top: '32px',
          },
        },
        description: {
          font: {
            size: '20px',
            weight: '400',
            lineHeight: '28px',
            color: '#333333',
          },
          margin: {
            top: '20px',
          },
        },
      },
    },
    loader: {
      text: {
        fontSize: '1rem',
      },
      icon: {
        fill: 'inherit',
      },
    },
    nextStepsRedesign: {
      color: colors.senary,
      backgroundColor: colors.primary,
      marginMobile: '0 0 40px 0',
      marginDesktop: '0 0 60px 0',
      paddingMobile: '24px',
      paddingDesktop: '32px 50px',
      title: {
        fontMobile: fonts.regular,
        fontDesktop: fonts.regular,
        marginMobile: '24px 0 34px 0',
        marginDesktop: '32px 0 46px 0',
      },
      stepTitle: { margin: '0 0 16px 0', font: '16px bold' },
      stepDescription: { margin: '0 0 25px 0', font: '16px regular' },
      stepCTA: { paddingMobile: '9px 24px', paddingDesktop: '13px 24px' },
      link: { hoverColor: 'unset' },
    },
    navMenu: {
      trigger: { font: { mobile: 'initial', desktop: 'initial' } },
      header: {
        height: '65px',
        padding: '21px 0',
        backgroundColor: 'white',
        borderBottom: '1px solid grey',
        font: 'initial',
      },
      logo: { margin: '0 21px' },
      closeButton: {
        borderRadius: '4px',
        margin: '0 calc(21px - 0.8rem) 0 0',
        width: '58px',
        height: '58px',
        padding: 'none',
        hover: {
          backgroundColor: '#ebebeb',
        },
        icon: {
          width: '16px',
          height: '16px',
          backgroundColor: 'black',
        },
      },
      backButton: {
        borderRadius: '4px',
        padding: '0.8rem',
        margin: '0 0 0 calc(21px - 0.8rem)',
        hover: {
          backgroundColor: '#ebebeb',
        },
        icon: {
          width: '18px',
          height: '18px',
          backgroundColor: 'black',
        },
      },
      linksWrapper: {
        padding: '32px 0',
        atStart: { borderBottom: '1px solid #ebebeb', padding: '32px 0 42px' },
      },
      link: {
        listItem: {
          padding: '10px 21px',
          height: '52px',
          hover: {
            backgroundColor: '#fafafa',
          },
        },
        icon: { width: '16px', height: '16px', backgroundColor: 'black' },
        font: 'initial',
      },
    },
  },
  tools: {
    detailedComparator: {
      tabs: {
        default: {
          title: {
            font: {
              family: 'inherit',
              size: '2rem',
            },
          },
        },
      },
    },
    evTree: {
      content: {
        maxWidth: '1200px',
        margin: {
          top: '0',
          right: 'auto',
          bottom: '0',
          left: 'auto',
        },
        tabletView: {
          padding: {
            top: '2rem',
            right: '3rem',
            bottom: '2rem',
            left: '3rem',
          },
        },
        desktop: {
          padding: {
            top: '2rem',
            right: '3rem',
            bottom: '2rem',
            left: '3rem',
          },
        },
      },
      question: {
        container: {
          alignItem: 'center',
          width: '272px',
          margin: {
            desktop: {
              top: '1.7857rem',
              bottom: '1.7857rem',
            },
            mobile: {
              top: '1.7857rem',
              bottom: '1.7857rem',
            },
          },
        },
      },
    },
  },
  showcase: {
    background: colors.primary,
    font: {
      mobile: 'initial',
      desktop: 'initial',
    },
  },
  vrmLookup: {
    background: '#FFE600',
  },
  modal: {
    default: {
      close: {
        color: colors.senary,
        opacity: '0.5',
      },
    },
    totalCostOfOwnership: {
      close: {
        color: '#fff',
        opacity: '1',
      },
    },
  },
  layout: {
    pageHeader: {
      backgroundColor: '#fff',
      height: {
        mobile: '64px',
        desktop: '64px',
      },
      logo: {
        imageSrc: 'https://via.placeholder.com/150',
        width: {
          desktop: '98px',
          tablet: '98px',
          mobile: '98px',
        },
        height: 'auto',
        padding: {
          left: {
            desktop: '24px',
            mobile: '24px',
          },
        },
        margin: 'auto',
        justifyContent: 'flex-start',
        alignItems: 'initial',
        container: {
          display: 'flex',
          gridTemplateAreas: '',
          gridTemplateColumns: { mobile: '', desktop: '' },
          gridColumnGap: { mobile: '', desktop: '' },
          width: 'initial',
          height: { mobile: 'initial', desktop: 'initial' },
          before: { height: '', backgroundColor: 'none' },
        },
      },
    },
    background: {
      image: {
        desktop: {
          src: 'no-image.png',
          height: '100px',
          width: '100px',
          display: 'inherit',
          size: 'contain',
          alignSelf: 'center',
          background: {
            positionX: 'center',
          },
          position: 'initial',
          margin: {
            left: '0px',
          },
        },
        tablet: {
          display: 'inherit',
        },
        mobile: {
          display: 'inherit',
        },
        startPage: {
          margin: {
            top: '0px',
          },
        },
        questionPage: {
          margin: {
            top: '0px',
            bottom: '0px',
          },
        },
      },
    },
    map: {
      circle: {
        strokeColor: '#1D2058',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#1D205825',
        fillOpacity: 0.35,
      },
      order: {
        desktop: 2,
        tablet: 2,
        mobile: 2,
      },
      desktop: {
        height: '457px',
        width: '479px',
        display: 'inherit',
        margin: {
          left: '61px',
          top: '-35px',
        },
      },
      tablet: {
        display: 'inherit',
      },
      mobile: {
        display: 'inherit',
      },
      marker: {
        startPoint: '/assets/components/map/path/start-point.png',
        endPoint: '/assets/components/map/path/end-point.png',
        currentLocation: '/assets/components/map/path/current-location.png',
      },
      polyline: {
        color: '#e30e0e',
      },
    },
    survey: {
      step: {
        margin: {
          top: '0px',
        },
      },
    },
  },
  viewports: {
    xsMobile: '375px',
    xsTablet: '376px',
    mobile: '576px',
    tablet: '768px',
    desktop: '1024px',
    highRes: '1440px',
  },
};

const themes = {
  [Brand.VOLVO]: volvo,
  [Brand.FORD]: ford,
  [Brand.WAYLANDS]: waylands,
  [Brand.MOTABILITY]: motability,
  [Brand.DRIVVNQA]: drivvnQA,
  [Brand.AUDI]: audi,
  [Brand.VOLKSWAGEN]: volkswagen,
};

const makeTheme = (brand: Brand): BaseTheme => {
  return merge(cloneDeep(defaultTheme), themes[brand]);
};

export { makeTheme, defaultTheme };
