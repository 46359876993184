import React, { useContext } from 'react';
import { BaseTheme } from 'shared/types';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

const ThemeContext = React.createContext({});

interface Props {
  theme: BaseTheme;
  children: React.ReactNode;
}

const ThemeProvider: React.FC<Props> = ({ theme, children }) => (
  <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
);

const StyledThemeProvider: React.FC<Props> = ({ theme, children }) => (
  <StyledComponentsThemeProvider theme={theme}>{children}</StyledComponentsThemeProvider>
);

type UseTheme = () => BaseTheme;

const useTheme: UseTheme = () => useContext(ThemeContext) as BaseTheme;

export { ThemeProvider, StyledThemeProvider, useTheme };
