import { Product } from 'shared/constants';

const waylandsConfig = {
  [Product.COMPARATOR]: {
    shouldUseNewIcons: true,
    components: { displayShowcaseHeader: true },
    features: {
      shopLinkCTA: {
        isShopCTAShown: false,
        makeShopLink: () => '',
      },
    },
  },
  [Product.USED_COMPARATOR]: {
    shouldUseNewIcons: true,
    filters: { useModelsFilters: [{ discontinuedFrom: 2010 }] },
    features: {
      vehicleSummary: { isImageResizeOnStickyDisabled: true },
    },
  },
  [Product.EV_TREE]: { shouldUseNewIcons: true },
  [Product.LANDING_PAGE]: { shouldUseNewIcons: true },
  [Product.DETAILED_COMPARATOR]: {
    shouldUseNewIcons: true,
    features: {
      vehicleSummary: { isImageResizeOnStickyDisabled: true },
      currentMileage: { shouldShowCurrentMileageInput: true },
      shopLinkCTA: {
        isShopCTAShown: false,
        makeShopLink: () => '',
      },
    },
  },
};

export default waylandsConfig;
