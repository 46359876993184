import React, { useContext } from 'react';
import { Brand } from 'shared/types';

const BrandContext = React.createContext('');

interface Props {
  brand: Brand;
  children: React.ReactNode;
}

const BrandProvider: React.FC<Props> = ({ brand, children }) => (
  <BrandContext.Provider value={brand}>{children}</BrandContext.Provider>
);

type UseBrand = () => Brand;

const useBrand: UseBrand = () => useContext(BrandContext) as Brand;

export { BrandProvider, useBrand };
