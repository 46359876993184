import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div<{ ref: React.ForwardedRef<HTMLElement> }>`
  ${({ theme }) => `
    display: flex;
    flex-grow: 1;
    margin: 0 auto;
    padding-left: ${theme.spacing.lg};
    padding-right: ${theme.spacing.lg};
    width: 100%;
    max-width: 1310px;

    ${theme?.components?.container?.maxWidth};
    ${theme?.components?.container?.padding};
  `}
`;

type ContainerProps = JSX.IntrinsicElements['div'];

export const Container = React.forwardRef<HTMLElement, ContainerProps>(({ children, className, ...rest }, ref) => {
  return (
    <StyledContainer {...rest} ref={ref} className={className}>
      {children}
    </StyledContainer>
  );
});
