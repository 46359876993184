import React, { useContext, useMemo } from 'react';
import { useBrand } from 'shared/hooks/useBrand';
import mergeWith from 'lodash/mergeWith';
import cloneDeep from 'lodash/cloneDeep';
import type { BaseConfig } from 'shared/brandConfigs';
import { configs } from 'shared/brandConfigs';
import { Brand } from 'shared/types';
import { Product } from 'shared/constants';

const BrandConfigContext = React.createContext({});

type Configs = typeof configs;
interface Props {
  configs: Configs;
  children: React.ReactNode;
}

function customiser(_, sourceValue) {
  if (Array.isArray(sourceValue)) {
    return sourceValue;
  }
  return undefined;
}

type ReturnType = {
  [key in Product]: BaseConfig[keyof BaseConfig] | { [key: string]: string | boolean | {} };
};

export function makeBrandSpecificConfig({
  configs,
  brand,
}: {
  configs: { defaultConfig: {} };
  brand: Brand;
}): ReturnType {
  return mergeWith(cloneDeep(configs.defaultConfig), cloneDeep(configs[brand]), customiser);
}

const BrandConfigProvider: React.FC<Props> = ({ children, configs }) => {
  const brand = useBrand();

  const config = useMemo(() => makeBrandSpecificConfig({ configs, brand }), [configs, brand]);
  return <BrandConfigContext.Provider value={config}>{children}</BrandConfigContext.Provider>;
};

type UseBrandConfig = () => BaseConfig;

// @TODO: Review return type https://drivvn.atlassian.net/browse/COMPRO-540
const useBrandConfig: UseBrandConfig = () => useContext(BrandConfigContext) as BaseConfig;

export { BrandConfigProvider, useBrandConfig };
