import React, { useContext, useState, useMemo } from 'react';
import { Brand } from 'shared/types';
import getSubBrandMapping from 'shared/utils/getSubBrandMapping';

interface SVGs {
  [key: string]: undefined | React.ElementType;
}

interface CustomSVGContextType {
  customSVGs: SVGs;
  getCustomSVG: (componentName: string, onError?: (err: Error) => void, onCompleted?: () => void) => void;
}

const CustomSVGContext = React.createContext<CustomSVGContextType | null>(null);

interface Props {
  brand: Brand;
  children: React.ReactNode;
}

type UseCustomSVG = () => CustomSVGContextType;

const useCustomSVGComponent: UseCustomSVG = () => {
  const customSVGContext = useContext(CustomSVGContext);

  if (!customSVGContext) {
    throw new Error('useCustomSVGComponent has to be used within <CustomSVGContext.Provider>');
  }

  return customSVGContext;
};

const CustomSVGProvider: React.FC<Props> = ({ brand, children }) => {
  const [SVGs, setSVGs] = useState({});

  const value = useMemo(() => {
    const getCustomSVG = (componentName: string, onError?: (err: Error) => void, onCompleted?: () => void) => {
      if (SVGs[componentName]) {
        return null;
      }
      const importComponent = async (): Promise<void> => {
        try {
          const { default: namedImport } = await import(
            `../../../public/assets/${getSubBrandMapping(brand)}/Icons/${componentName}.svg`
          );

          setSVGs((currentSVGs) => ({ ...currentSVGs, [componentName]: namedImport }));
          return namedImport;
        } catch (err) {
          if (onError) {
            onError(err);
            // eslint-disable-next-line no-console
            console.error(err);
          }
        } finally {
          if (onCompleted) {
            onCompleted();
          }
        }
      };
      importComponent();
    };
    return { customSVGs: SVGs, getCustomSVG };
  }, [brand, SVGs]);

  return <CustomSVGContext.Provider value={value}>{children}</CustomSVGContext.Provider>;
};

export { CustomSVGProvider, useCustomSVGComponent };
