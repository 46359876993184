import { HeroContentPlacement } from 'features/Hero/constants';
import { HeroType } from 'features/Hero/types';

export const heroTheme: HeroType = {
  mobile: {
    height: '200px',
    marginBottom: '24px',
  },
  tablet: {
    height: '300px',
    marginBottom: '24px',
  },
  hiRes: {
    height: '500px',
    marginBottom: '40px',
  },
  ultraHiRes: {
    height: '700px',
    marginBottom: '40px',
  },
  images: {
    altText: 'Welcome to drivvn',
    srcSet: ['/assets/components/hero/fail-safe-default.png'],
    srcSetMimeType: 'image/png',
    fallback: '/assets/components/hero/fail-safe-default.png',
    media: ['1080px'],
    fit: 'unset',
    position: {
      default: 'unset',
      480: 'unset',
      610: 'unset',
      1024: 'unset',
      1440: 'unset',
      2560: 'unset',
    },
  },
  content: {
    display: {
      mobile: { title: HeroContentPlacement.INSIDE, subTitle: HeroContentPlacement.INSIDE },
      desktop: { title: HeroContentPlacement.INSIDE, subTitle: HeroContentPlacement.INSIDE },
    },
    width: {
      default: '100%',
      480: '100%',
      1024: '100%',
    },
    padding: {
      default: {
        top: 'inherit',
        right: 'inherit',
        bottom: 'inherit',
        left: 'inherit',
      },
      480: {
        top: 'inherit',
        right: 'inherit',
        bottom: 'inherit',
        left: 'inherit',
      },
    },
    title: {
      alignItems: {
        default: 'flex-start',
        480: 'flex-start',
      },
      justifyContent: {
        default: 'flex-start',
        480: 'flex-start',
      },
      text: {
        alignment: {
          default: 'left',
          480: 'left',
        },
      },
      color: 'inherit',
      font: {
        family: 'inherit',
        size: {
          default: 'inherit',
          480: 'inherit',
        },
      },
    },
    subTitle: {
      justifyContent: {
        default: 'flex-start',
        '480': 'center',
      },
      textAlign: {
        default: 'left',
        '480': 'center',
      },
      margin: {
        top: 'unset',
      },
      font: {
        size: 'unset',
      },
    },
    inside: { gap: '0' },
  },
};
