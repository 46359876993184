import { BaseTheme, Theme } from 'shared/types';

const motability: Theme<BaseTheme> = {
  favicons: {
    16: 'favicon-16x16.png',
  },
  background: {
    secondary: '#213E8F',
  },
  fonts: {
    regular: 'regular',
    bold: 'semi-bold',
  },
  colors: {
    primary: '#213E8F',
    secondary: '#fff',
    tertiary: '#707070',
  },
  spacing: {
    sm: '.75rem', // 12px
    lg: '1.25rem', // 20px
  },
  fontSizes: {
    md: '1.25rem', // 20px
    lg: '1.625rem', // 26px
    xxl: '2.563rem', // 41px
  },
  components: {
    steps: {
      active: {
        background: '#213E8F',
      },
      incomplete: {
        background: '#99DBCA',
      },
      completed: {
        background: '#2EA386',
      },
      alignItems: 'flex-end',
      icon: {
        src: {
          desktop: '/assets/motability/common/motability-car.svg',
          mobile: '/assets/motability/common/motability-car-mobile.svg',
        },
        margin: {
          bottom: {
            mobile: '-3px',
            tablet: '-3px',
          },
          left: {
            mobile: '0px',
            tablet: '3px',
          },
        },
      },
      width: '1.25rem',
      tablet: {
        width: '2.5rem',
        position: 'flex-start',
        margin: {
          top: '0px',
          bottom: '10px',
        },
      },
      desktop: {
        width: '2.5rem',
      },
      borderRadius: '0px',
      step: {
        marginRight: {
          tablet: '4px',
          mobile: '2px',
        },
        height: {
          mobile: '2px',
          tablet: '4px',
        },
      },
      margin: {
        top: '25px',
        bottom: '0px',
      },
    },
    footer: {
      padding: {
        top: '0px',
      },
    },
    buttons: {
      base: {
        font: {
          family: 'regular',
        },
        borderRadius: '6px',
        fontWeight: '400',
        backgroundColor: '#213E8F',
        hover: {
          backgroundColor: '#3357B8',
        },
        borderWidth: '0px',
      },
      primary: {
        borderRadius: '6px',
        borderWidth: '0px',
        backgroundColor: '#213E8F',
        hover: {
          backgroundColor: '#3357B8',
        },
      },
      disable: {
        opacity: 'inherit',
        backgroundColor: '#F2F2F3',
        color: '#333333',
      },
      secondary: {
        hover: {
          backgroundColor: '#FAFAFA',
          fontColor: '#213E8F',
          borderColor: '#FAFAFA',
        },
      },
      back: {
        icon: {
          source: '/assets/motability/common/chevron-left.svg',
        },
      },
      backChevon: {
        padding: {
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
        margin: {
          top: '8px',
        },
        img: {
          height: '14px',
          padding: {
            right: '8px',
          },
        },
        text: {
          fontSize: '16px',
          lineHeight: '20px',
        },
        width: {
          mobile: '40px',
          tablet: '40px',
        },
      },
      evTree: {
        width: {
          mobile: '82px',
          tablet: '82px',
        },
      },
      trip: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #213E8F',
        color: '#213E8F',
        lineHeight: '28px',
        height: '52px',
        margin: {
          right: '29px',
          bottom: '0px',
        },
        hover: {
          backgroundColor: '#FFFFFF',
        },
        before: {
          content: '<',
          margin: {
            right: '3px',
          },
          color: '#FFFFFF !important',
        },
        outlineOffset: '3px',
      },
    },
    infoPopup: {
      boxShadow: '3px 3px 5px 0px rgba(0, 0, 0, 0.25)',
      messageRunningCosts: 'inherit',
      background: '#F2F2F3',
      color: '#FAFAFA',
      minWidth: '250px',
      maxWidth: '280px',
      text: {
        width: '',
        color: '#333333',
        textAlign: 'left',
        fontSize: '1rem', // 16px
        whiteSpace: 'pre-line',
      },
      close: {
        width: '0px',
        display: 'none',
      },
      message: {
        left: '180px',
        runningCost: {
          width: '50px',
          left: '52vw',
        },
      },
      border: {
        color: '#F2F2F3',
      },
      marginTop: 'auto',
    },
    pageFooter: {
      background: {
        color: '#213E8F',
      },
      padding: {
        right: {
          mobile: '20px',
          tablet: '150px',
        },
        left: {
          mobile: '20px',
          tablet: '150px',
        },
        bottom: {
          mobile: '32px',
          tablet: '32px',
        },
        top: {
          mobile: '32px',
          tablet: '32px',
        },
      },
      copy: {
        textAlign: 'left',
        font: {
          color: '#FFFFFF',
        },
        lineHeight: '18px',
        width: '600px',
        margin: {
          left: 'auto',
        },
      },
      footerBottom: {
        flex: {
          direction: 'row',
          wrap: 'wrap',
        },
        links: {
          order: {
            mobile: '4',
            tablet: '5',
          },
          justifyContent: 'start',
          link: {
            margin: {
              left: '0px',
              right: '0px',
            },
            icon: '/assets/motability/common/blank-link.svg',
            textDecoration: 'underline',
            cursor: 'pointer',
          },
          padding: {
            left: '0px',
            right: '0px',
            bottom: '20px',
          },
          img: {
            padding: {
              right: '18px',
            },
          },
          span: {
            margin: {
              bottom: '0px',
            },
          },
        },
        copy: {
          order: '3',
        },
        disclaimerLine1: {
          order: {
            mobile: '5',
            tablet: '4',
          },
          padding: {
            left: '342px',
          },
          img: {
            padding: {
              left: '3px',
            },
          },
          color: '#F2F2F3',
        },
      },
      font: {
        family: 'regular',
        size: '13px',
        color: '#F2F2F3',
      },
    },
    form: {
      disclaimer: {
        before: {
          content: '< ',
          textDecoration: 'underline',
          textDecorationColor: '#213E8F',
        },
      },
    },
    survey: {
      layout: {
        content: {
          width: '600px',
          maxWidth: '600px',
        },
      },
      question: {
        width: 'inherit',
        description: {
          fontSize: {
            lg: '1.25rem',
            xxl: '1.25rem',
          },
          color: '#333333',
          margin: {
            desktop: {
              top: '20px',
            },
            mobile: {
              top: '20px',
            },
          },
          footer: {
            margin: {
              top: '52px',
            },
            font: {
              size: '16px',
              weight: '400',
            },
            lineHeight: '20px',
            color: '#333333',
          },
        },
        link: {
          fontSize: '20px',
          color: '#3357B8',
          margin: {
            top: '20px',
          },
          lineHeight: '28px',
        },
        fullMapLink: {
          fontSize: '20px',
          color: '#3357B8',
          margin: {
            top: '20px',
          },
          lineHeight: '28px',
          displayDesktop: 'none',
        },
        title: {
          color: '#333333',
          lineHeight: '28px',
        },
        options: {
          padding: {
            table: {
              top: '20px',
              right: '0',
              bottom: '39px',
              left: '0',
            },
            mobile: {
              top: '20px',
              right: '0',
              bottom: '39px',
              left: '0',
            },
          },
        },
        inputType: {
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '280px',
          margin: {
            top: '0px',
            right: '0px',
            bottom: '20px',
            left: '0px',
          },
          color: '#000000',
          input: {
            display: 'block',
            height: '54px',
            backgroundColor: '#FFFFFF',
            border: '1px solid #C5D6EC',
            borderRadius: '8px',
            padding: {
              top: '13px',
              right: '0px',
              bottom: '13px',
              left: '13px',
            },
            font: {
              size: '1.25rem',
            },
            lineHeight: '28px',
            active: {
              outline: 'none',
            },
            destination: {
              width: '133px',
            },
            tripsCount: {
              width: '96px',
            },
          },
          label: {
            padding: {
              top: '0px',
              bottom: '5px',
            },
            font: {
              size: '1.25rem',
            },
            lineHeight: '28px',
            color: '#333333',
          },
          error: {
            margin: {
              top: '5px',
            },
            font: {
              size: '1.25rem',
            },
            lineHeight: '28px',
            color: '#DB3624',
            width: {
              max: '258px',
            },
          },
          form: {
            email: {
              width: '600px',
              margin: {
                bottom: '25px',
              },
            },
            vrm: {
              width: '115px',
            },
          },
        },
        option: {
          border: '1px solid #C5D6EC',
          width: '282px',
          font: {
            size: '1.25rem',
          },
          padding: {
            tablet: '12px',
            mobile: '0px',
          },
          margin: {
            desktop: {
              bottom: '13px',
            },
            mobile: {
              bottom: '13px',
            },
          },
          borderRadius: '8px',
          tablet: {
            width: '600px',
          },
          active: {
            background: '#C5D6EC',
          },
          input: {
            display: 'inherit',
            label: {
              border: 'none',
              lineHeight: '28px',
              padding: {
                mobile: {
                  top: '12px',
                  right: '12px',
                  bottom: '12px',
                  left: '36px',
                },
                tablet: {
                  top: '0rem',
                  right: '0rem',
                  bottom: '0rem',
                  left: '24px',
                },
              },
            },
            checked: {
              border: 'none',
              fontWeight: {
                tablet: 'bold',
                mobile: 'bold',
              },
            },
            radio: {
              width: {
                tablet: '20px',
                mobile: '20px',
              },
              height: {
                tablet: '20px',
                mobile: '20px',
              },
              display: {
                mobile: 'inline-block',
                tablet: 'inline-block',
              },
              border: {
                color: '#3357B8',
              },
              borderChecked: {
                background: 'radial-gradient(#3357B8 0%, #3357B8 40%, transparent 50%, transparent)',
                color: '#3357B8',
              },
            },
            verticalRadio: {
              color: '#3357B8',
              margin: {
                right: '30px',
                bottom: {
                  desktop: '120px',
                  mobile: '142px',
                },
              },
              border: '1px solid #3357B8',
              padding: {
                top: '20px',
                right: '0',
                bottom: '20px',
                left: '0',
              },
            },
          },
          alignItems: 'center',
          color: '#333333',
        },
        label: {
          active: {
            webkitTapHighLightColor: 'rgba(0,0,0,0)',
          },
        },
        multiInput: {
          padding: {
            table: {
              top: '20px',
              right: '0',
              bottom: '32px',
              left: '0',
            },
            mobile: {
              top: '20px',
              right: '0',
              bottom: '32px',
              left: '0',
            },
          },
        },
        boxTrip: {
          margin: {
            top: '-32px',
            bottom: '52px',
          },
          display: 'flex',
        },
      },
      startPage: {
        margin: {
          top: '52px',
          right: '20px',
          bottom: '52px',
          left: '20px',
        },
        header: {
          title: {
            margin: {
              top: '0rem',
              bottom: '20px',
            },
            font: {
              color: '#333333',
              size: '41px',
            },
          },
        },
        button: {
          padding: {
            top: '.75rem',
            right: '1.25rem',
            bottom: '.75rem',
            left: '1.25rem',
          },
          font: {
            size: '1.25rem',
          },
          lineHeight: '28px',
          outlineOffset: '2px',
        },
        tabletView: {
          width: '600px',
          height: '496px',
          justifyContent: 'center',
          header: {
            minWidth: '400px',
          },
        },
        outlineOffset: '2px',
      },
      MapModal: {
        content: {
          height: '97.87vh',
          maxHeight: 'calc(100% - 20px)',
        },
        inner: {
          borderRadius: '8px',
          maxWidth: 'calc(100% - 20px)',
          maxHeight: 'calc(100vh - 20px)',
        },
      },
    },
    results: {
      enhancedResults: {
        header: {
          color: '#333333',
        },
        imgSrc: '/assets/motability/common/electric-icon.svg',
        additionalLink: {
          font: {
            size: '20px',
            weight: '400',
            lineHeight: '28px',
            color: '#333333',
          },
        },
        section: {
          font: {
            color: '#333333',
          },
        },
      },
      attributeHeader: {
        justifyContent: 'center',
        border: {
          bottom: 'solid 1px #F2F2F3',
        },
        attribute: {
          minHeight: '125px',
          padding: {
            top: '20px',
            right: '10px',
            bottom: '8px',
            left: '10px',
          },
          evenAttribute: {
            background: {
              color: '#F2F2F3',
            },
            border: {
              radius: {
                topLeft: '30px',
                topRight: '30px',
                bottomRight: '0px',
                bottomLeft: '0px',
              },
            },
          },
          left: {
            flex: 'inherit',
            width: '228px',
            tabletView: {
              width: '158px',
            },
            desktopView: {
              width: '228px',
            },
          },
          icon: {
            justifyContent: 'flex-end',
            flex: '1 0 25%',
            globalSvg: {
              fill: 'none',
              transform: 'scale(1)',
            },
          },
          text: {
            title: {
              margin: {
                bottom: '4px',
              },
              font: {
                weight: '400',
                size: '16px',
              },
              textAlign: 'center',
            },
          },
        },
      },
      attribute: {
        figureAmount: {
          font: '1.25rem/28px semi-bold',
        },
        figureUnit: {
          font: '1rem',
          color: '#000000',
        },
        icon: {
          globalSvg: {
            fill: 'none',
            transform: 'scale(1)',
          },
          infoPopup: {
            margin: {
              top: '15px',
              right: '8px',
              bottom: '15px',
              left: '8px',
            },
          },
        },
      },
      vehicle: {
        margin: {
          left: '20px',
          right: '20px',
        },
        title: {
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: {
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          },
          model: {
            font: '1rem semi-bold',
          },
          fuelAndTransmission: {
            display: 'initial',
            marginLeft: '20px',
            fontSize: '0.813rem;',
            lintHeight: '18px',
            color: '#213E8F',
          },
          price: {
            display: 'none',
          },
        },
        inner: {
          attributeAmount: 5,
          border: {
            tabletView: {
              bottom: {
                width: '1px',
                style: 'solid',
                color: '#DAD9F1',
              },
              top: {
                width: '1px',
                style: 'solid',
                color: '#DAD9F1',
              },
            },
            top: {
              width: '1px',
              style: 'solid',
              color: '#DAD9F1',
            },
            bottom: {
              width: '1px',
              style: 'solid',
              color: '#6E69C2',
            },
          },
          header: {
            image: {
              maxWidth: 'auto',
              maxHeight: 'auto',
              width: '228px',
              height: 'auto',
              tabletView: {
                width: '158px',
              },
              desktopView: {
                width: '228px',
              },
            },
            showDetail: {
              border: {
                top: '1px solid #fff',
              },
            },
          },
          attribute: {
            border: {
              bottom: '#DAD9F1',
            },
            odd: {
              background: {
                color: '#F2F2F3',
              },
            },
          },
        },
      },
      layout: {
        margin: {
          top: '32px',
          right: '32px',
          bottom: '20px',
        },
        padding: {
          mobile: '20px',
          desktop: '0',
        },
      },
      filters: {
        padding: {
          top: '32px',
          right: '16px',
          bottom: '32px',
          left: '16px',
        },
        margin: {
          right: '2rem',
        },
        borderRadius: '30px',
        background: {
          color: '#F2F2F3',
        },
        width: {
          desktop: '280px',
          mobile: '100%',
        },
        height: '100%',
        fontSize: '1.625rem',
        accordion: {
          header: {
            padding: '12px',
          },
          content: {
            padding: {
              top: '0',
              right: '0',
              bottom: '0',
              left: '12px',
            },
          },
          maxWidth: '280px',
          fontSize: '1.25rem',
          divider: {
            border: {
              top: '1px solid #DAD9F1',
              right: '0',
              bottom: '0',
              left: '0',
            },
            margin: {
              top: '0',
              right: '12px',
              bottom: '0',
              left: '12px',
            },
          },
          label: {
            color: '#333333',
          },
        },
        option: {
          marginRight: '0.5rem',
        },
      },
      noResults: {
        padding: '20px',
        font: {
          size: {
            desktop: '41px',
            mobile: '30px',
          },
          weight: '400',
          lineHeight: {
            desktop: '49px',
            mobile: '38px',
          },
        },
        minHeight: '1480px',
      },
      personalFigures: {
        padding: {
          desktop: '0',
          mobile: '20px',
        },
        margin: {
          top: '40px',
        },
        personalFigure: {
          padding: '20px',
          margin: {
            right: '30px',
            bottom: '30px',
          },
          background: '#FDDCD8',
          borderRadius: '30px',
          height: '166px',
          title: {
            font: {
              size: '26px',
              weight: '400',
              lineHeight: '36px',
            },
            padding: {
              mobile: '20px 0 0 0',
              highRes: '0',
            },
          },
          content: {
            font: {
              size: '41px',
              weight: '700',
              lineHeight: '60px',
            },
          },
          unit: {
            font: {
              size: '26px',
              weight: '400',
              lineHeight: '36px',
            },
          },
          financialSavings: {
            background: 'url(/assets/motability/common/financial-saving.svg) #99DBCA no-repeat;',
          },
          co2Savings: {
            background: 'url(/assets/motability/common/co2-saving.svg) #DAD9F1 no-repeat;',
          },
          InfoPopup: {
            top: '20px',
            right: '20px',
          },
        },
      },
      footer: {
        heading: {
          font: {
            size: '16px',
            weight: '400',
          },
          margin: {
            bottom: '20px',
          },
        },
        text: {
          font: {
            size: '13px',
          },
          color: '#000000',
        },
      },
      findYourCar: {
        margin: {
          top: '52px',
        },
        border: {
          top: '1px solid #DAD9F1',
          right: '0',
          bottom: '0',
          left: '0',
        },
        padding: {
          desktop: '0',
          mobile: '0 20px',
        },
        title: {
          font: {
            size: '41px',
            weight: '400',
            lineHeight: '49px',
          },
          margin: {
            top: '32px',
          },
        },
        description: {
          font: {
            size: '20px',
            weight: '400',
            lineHeight: '28px',
            color: '#333333',
          },
          margin: {
            top: '20px',
          },
        },
      },
    },
  },
  tools: {
    evTree: {
      content: {
        maxWidth: '1236px',
        margin: {
          top: '0',
          right: 'auto',
          bottom: '0',
          left: 'auto',
        },
        tabletView: {
          padding: {
            top: '1.75rem',
            right: '3rem',
            bottom: '3.25rem',
            left: '3rem',
          },
        },
        desktop: {
          padding: {
            top: '3.25rem',
            right: '3rem',
            bottom: '3.25rem',
            left: '3rem',
          },
        },
      },
      question: {
        container: {
          alignItem: 'flex-start',
          width: '280px',
          margin: {
            desktop: {
              top: '42px',
              bottom: '0px',
            },
            mobile: {
              top: '32px',
              bottom: '32px',
            },
          },
        },
      },
    },
  },
  layout: {
    pageHeader: {
      backgroundColor: '#213E8F',
      height: {
        mobile: '56px',
        desktop: '80px',
      },
      logo: {
        imageSrc: '/assets/motability/common/logo-header.svg',
        padding: {
          left: {
            desktop: '150px',
            mobile: '0',
          },
        },
        width: {
          desktop: 'auto',
          mobile: '114px',
        },
        justifyContent: 'center',
      },
    },
    background: {
      image: {
        desktop: {
          src: '/assets/motability/common/mo-car-charging.png',
          height: '328px',
          width: '479px',
          display: 'inherit',
          size: 'containt',
          alignSelf: 'top',
          background: {
            positionX: 'top',
          },
          position: 'top',
          margin: {
            left: '61px',
          },
        },
        tablet: {
          display: 'none',
        },
        mobile: {
          display: 'none',
        },
        startPage: {
          margin: {
            top: '84px',
          },
        },
        questionPage: {
          margin: {
            top: '51px',
            bottom: '84px',
          },
        },
      },
    },
    map: {
      circle: {
        strokeColor: '#1D2058',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#1D205825',
        fillOpacity: 0.8,
      },
      order: {
        desktop: 2,
        tablet: 2,
        mobile: 2,
      },
      desktop: {
        height: '457px',
        width: '479px',
        display: 'inherit',
        margin: {
          left: '61px',
          top: '-35px',
        },
      },
      tablet: {
        display: 'inherit',
      },
      mobile: {
        display: 'inherit',
      },
    },
    survey: {
      step: {
        margin: {
          top: '1.25rem',
        },
      },
    },
  },
  viewports: {
    desktop: '992px',
    tablet: '768px',
    highRes: '1199px',
  },
};

export { motability };
