import React, { useContext, useState } from 'react';
import { JourneyType } from 'shared/types';

const JourneyContext = React.createContext({});

interface Props {
  children: React.ReactNode;
}

const JourneyTypeProvider: React.FC<Props> = ({ children }) => {
  const [journeyData, setJourneyData] = useState(JourneyType.JOURNEY_PROVIDER_EMPTY);
  return <JourneyContext.Provider value={{ journeyData, setJourneyData }}>{children}</JourneyContext.Provider>;
};

type UseJourney = () => {
  journeyData: string;
  setJourneyData: React.Dispatch<React.SetStateAction<string>>;
};

const useJourney: UseJourney = () =>
  useContext(JourneyContext) as {
    journeyData: string;
    setJourneyData: React.Dispatch<React.SetStateAction<string>>;
  };

export { JourneyTypeProvider, useJourney };
