import { createLogger, format, transports, Logger } from 'winston';

const LOG_LEVEL = process.env.LOG_LEVEL ?? 'info';

const { combine, timestamp, printf } = format;

interface LogFormat {
  level: string;
  message: string;
  timestamp: string;
}

const logFormat = printf(({ level, message, timestamp }: LogFormat): string => {
  return `${timestamp} [${level}]: ${message}`;
});

const logger: Logger = createLogger({
  level: LOG_LEVEL,
  format: combine(timestamp(), logFormat),
  transports: [new transports.Console()],
});

export { logger, logFormat };
