import { Brand } from 'shared/types';
import DefaultHero from 'features/Hero/Hero';
import { VolkswagenHero } from 'features/Hero/Brand/VolkswagenHero';

const Hero = {
  [Brand.VOLVO]: DefaultHero,
  [Brand.VOLKSWAGEN]: VolkswagenHero,
  [Brand.AUDI]: DefaultHero,
  [Brand.MOTABILITY]: DefaultHero,
  [Brand.DRIVVNQA]: DefaultHero,
  [Brand.WAYLANDS]: DefaultHero,
};

export { HeroEnabledForBrand, HeroContentPlacement } from './constants';

export type { HeroType, HeroInterface, HeroEnabledForBrandType } from './types';

export { heroTheme } from './theme';

export default Hero;
