import { MODERN_BROWSERSLIST_TARGET } from 'next/dist/shared/lib/constants';
import { Brand } from 'shared/types';

export const VALID_VRM_PATTERN =
  /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)/;

export enum TranslationKey {
  LANDING_PAGE = 'landing-page',
  COMPARATOR = 'comparator',
  DECISION_TREE = 'ev-tree',
  DETAILED_COMPARATOR = 'detailed-comparator',
  USED_COMPARATOR = 'used-comparator',
  COMMON = 'common',
}

export enum Product {
  COMPARATOR = 'comparator',
  EV_TREE = 'ev-tree',
  DETAILED_COMPARATOR = 'detailed-comparator',
  USED_COMPARATOR = 'used-comparator',
  LANDING_PAGE = 'landing-page',
  ALL = 'all',
}

export const MAX_ZOOM_IN = 14;

export const DEFAULT_GOOGLE_MAP_LATITUDE = 55.37805100001;

export const DEFAULT_GOOGLE_MAP_LONGITUDE = -3.435973;

export const INITIAL_GOOGLE_MAP_UK_ZOOM = 6;

export const GROUP_ZOOM_CHARGER_POINT = {
  [Brand.MOTABILITY]: [3, 5, 6, 10],
  [Brand.VOLVO]: [],
};

export const DEFAULT_GOOGLE_MAP_ZOOM = 13;

export const DEFAULT_ZOOM_RADIUS = 10;

export const DEFAULT_ZOOM_LEVEL_UK = 6;

export const DEFAULT_INDEX_REGULAR_DESTINATIONS_QUESTION = {
  [Brand.MOTABILITY]: [5, 6],
  [Brand.VOLVO]: [],
  [Brand.WAYLANDS]: [],
};

export const DEFAULT_INDEX_REGULAR_HOME_POSTCODE = {
  [Brand.MOTABILITY]: [3, 7, 10],
  [Brand.VOLVO]: [],
};

export const QUESTION_ZOOM_LEVEL_UK = {
  [Brand.MOTABILITY]: 8,
  [Brand.VOLVO]: 5,
};

export const QUESTION_LONG_TRIP_INDEX = 9;

export const DEFAULT_NEARBY_CHARGING_STATIONS_RADIUS = 5000;

export const MilesToMeter = 1609.344;

export const DEFAULT_RADIUS = MilesToMeter * 10;

export const GoogleIconsLibrary = {
  [Brand.MOTABILITY]: true,
  [Brand.AUDI]: false,
  [Brand.DRIVVN]: false,
  [Brand.DRIVVNQA]: false,
  [Brand.VOLVO]: false,
  [Brand.OTHER]: false,
  [Brand.WAYLANDS]: false,
};

export const ValidProducts = ['landing-page', 'ev-tree', 'comparator', 'detailed-comparator'];

export const ProductsWithFullMenu = ['ev-tree', 'comparator', 'detailed-comparator'];

export const EnableMenuByBrand = [Brand.VOLVO, Brand.WAYLANDS];
