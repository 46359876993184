import { VehicleOptionHeadings } from 'features/detailed-comparator/components/DataTable/constants';
import { TitleAndDescriptionType } from 'features/detailed-comparator/components/DetailedComparator/TitleAndDescription/types';
import { HeroType } from 'features/Hero';
import { RadioType } from 'shared/components/Radio/types';

export type { ParsedUrlQuery } from 'node:querystring';

export enum AnalyticsEventType {
  EVENT = 'event',
  NAVIGATION = 'nav',
}

export interface AnalyticsData {
  eventType: AnalyticsEventType;
  eventPath: string;
}

export enum JourneyTypeParam {
  PRIVATE = 'private',
  BUSINESS_COMPANY = 'business-company',
  BUSINESS_FLEET = 'business-fleet',
}

export enum VehicleQueryParams {
  VEHICLE_ID = 'vehicleId',
  CURRENT_MILEAGE = 'currentMileage',
  REGISTRATION_YEAR = 'registrationYear',
  IS_VRM = 'isVRM',
}

export enum JourneyType {
  PRIVATE = 'private',
  BUSINESS = 'business',
  TOTAL_COST_OF_OWNERSHIP_BREAKDOWN = 'totalCostOfOwnershipBreakdown',
  BUSINESS_COMPANY_CAR_DRIVER = 'business.companyCarDriver',
  BUSINESS_FLEET_MANAGER = 'business.fleetManager',
  JOURNEY_PROVIDER_EMPTY = 'None',
}

export enum BusinessJourneyType {
  COMPANY_CAR_DRIVER = 'companyCarDriver',
  FLEET_MANAGER = 'fleetManager',
}

export enum Brand {
  DRIVVN = 'drivvn',
  DRIVVNQA = 'drivvnqa',
  FORD = 'ford',
  VOLVO = 'volvo',
  WAYLANDS = 'waylands',
  AUDI = 'audi',
  MOTABILITY = 'motability',
  OTHER = 'other',
  VOLKSWAGEN = 'volkswagen',
}

export enum MultiInputType {
  WEEKEND_MILES_INPUT = 'weekend_miles_input',
  WEEKEND_TRIPS_INPUT = 'weekend_trips_input',
  EMAIL = 'email',
  VRM = 'vrm',
  HOME_POSTCODE = 'home_postcode',
  REGULAR_DESTINATIONS = 'regular_destinations',
  LONG_TRIPS_DESTINATION = 'long_trips_destination_input',
  ACTION_LINK_SHOW_FULL_MAP = 'action_link_show_full_map',
  LONG_TRIPS_COUNT = 'long_trips_count_input',
}

export enum Arrow {
  EXPAND = 'expand',
  COLLAPSE = 'collapse',
  RIGHT = 'right',
}

export const BrandDefaults = {
  volvo: {
    left: '99638',
    right: '99514',
  },
  waylands: {
    left: '99638',
    right: '99514',
  },
};

type MobileAndDesktop = { mobile: string; desktop: string };

interface ButtonType {
  borderRadius: string;
  fontWeight: string;
  backgroundColor: string;
  fontColor: string;
  borderWidth: string;
  borderStyle: string;
  borderColor: string;
  transition: string;
  minHeight: string;
  minWidth: string;
  hover: {
    fontColor: string;
    backgroundColor: string;
    borderColor: string;
  };
  mobile: {
    minHeight: string;
    minWidth: string;
  };
  icon: {
    color: string;
    hover: {
      color: string;
    };
    appended: {
      position: {
        right: string;
      };
      hover: {
        position: {
          right: string;
        };
      };
    };
  };
}

interface ButtonBase extends ButtonType {
  font: {
    family: string;
  };
}

export type Colors = {
  [key: string]: string;
};

interface ButtonVariantType {
  backgroundColor: string;
  border: {
    width: string;
    style: string;
    radius: string;
    color: string;
  };
  font: {
    color: string;
    weight: string;
    size: string;
    decoration: string;
    decorationThickness: string;
  };
  state: {
    hover: {
      borderColor: string;
      backgroundColor: string;
      fontColor: string;
    };
    active: {
      transform: string;
      font: {
        color: string;
      };
    };
    focussed: {
      backgroundColor: string;
      fontColor: string;
      outline: {
        color: string;
        style: string;
        width: string;
        offset: string;
      };
    };
    disabled: {
      color: string;
      borderColor: string;
      backgroundColor: string;
    };
  };
  icon?: {
    color: string;
    hover: {
      color: string;
    };
    appended: {
      position: {
        right: string;
      };
      hover: {
        position: {
          right: string;
        };
      };
    };
  };
}

interface FontSize {
  heading1: string;
  heading2: string;
  heading3: string;
  heading4: string;
  copy1?: string;
  copy2?: string;
  copy3?: string;
}

export interface LineHeight {
  heading1: string;
  heading2: string;
  heading3: string;
  heading4: string;
  copy1?: string;
  copy2?: string;
  copy3?: string;
}

export interface LineHeights {
  mobile: LineHeight;
  desktop: LineHeight;
}

export interface BaseTheme {
  alpha: {
    primary: string;
  };
  favicons: {
    [key: string]: string;
  };
  colors: {
    primary: string;
    secondary: string;
    tertiary: string;
    quaternary: string;
    quinary: string;
    senary: string;
  };
  background: {
    primary: string;
    secondary: string;
  };
  border: {
    primary: string;
    radius: string;
  };
  spacing: {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
    xxl: string;
    xxxl: string;
    xxxxl: string;
    xxxxxl: string;
    xxxxxxl: string;
  };
  fonts: {
    regular: string;
    bold: string;
    light: string;
    medium: string;
  };
  fontSizes: {
    sm: string;
    md: string;
    lg: string;
    xl: string;
    xxl: string;
    xxxl: string;
    xxxxl: string;
    xxxxxl: string;
    desktop: FontSize;
    mobile: FontSize;
  };
  lineHeight: LineHeights;
  buttons: {
    globals: {
      font: string;
      color: string;
      transform: string;
      border: {
        width: string;
        style: string;
        radius: string;
        color: string;
      };
      variants: {
        large: {
          width: string;
          height: string;
          padding: string;
        };
        medium: {
          width: string;
          height: string;
          padding: string;
        };
        small: {
          width: string;
          height: string;
          padding: string;
        };
      };
      transition: string;
    };
    primary: ButtonVariantType;
    secondary: ButtonVariantType;
    tertiary: ButtonVariantType;
  };
  form: {
    inputs: {
      radio: RadioType;
    };
  };
  progress: {
    0: {
      background: string;
      border: string;
      bar: string;
    };
    1: {
      background: string;
      border: string;
      bar: string;
    };
    border: {
      attributes: string;
      radius: string;
    };
  };
  components: {
    formItem: {
      margin: string;
      label: {
        color: string;
      };
      hint: {
        color: string;
      };
      input: {
        color: string;
        padding: string;
      };
      lastItem: {
        margin: {
          bottom: string;
        };
      };
    };
    simpleComparator: {
      vehicleSummary: {
        title: {
          font: MobileAndDesktop;
          margin: MobileAndDesktop;
        };
        description: {
          font: MobileAndDesktop;
          margin: MobileAndDesktop;
        };
        price: {
          font: MobileAndDesktop;
          margin: MobileAndDesktop;
        };
        changeVehicleButton: {
          icon: { color: string };
        };
        attribute: {
          title: { font: string };
          value: { font: string };
        };
      };
      runningCosts: {
        title: {
          font: MobileAndDesktop;
        };
        price: {
          font: MobileAndDesktop;
        };
        infoLink: {
          icon: { color: string };
        };
      };
      showcase: {
        attributeBar: {
          backgroundColor: string;
          color: string;
          flexGap: MobileAndDesktop;
          font: MobileAndDesktop;
          minHeight: MobileAndDesktop;
          minWidth: MobileAndDesktop;
          padding: MobileAndDesktop;
        };
        content: {
          padding: MobileAndDesktop;
          vehicle: {
            titles: { flexGap: string };
            title: { font: MobileAndDesktop };
            value: { font: MobileAndDesktop };
          };
        };
        footer: {
          flexGap: string;
          title: { font: MobileAndDesktop };
          subTitle: { font: MobileAndDesktop };
        };
        rowPadding: MobileAndDesktop;
        rowBorder: string;
        wrapper: { flexGap: MobileAndDesktop; padding: { mobile: ''; desktop: string } };
      };
      calculations: {
        modal: {
          header: {
            flexGap: string;
            font: MobileAndDesktop;
            backgroundColor: string;
            height: MobileAndDesktop;
            padding: MobileAndDesktop;
          };
          content: { padding: MobileAndDesktop };
          vehicles: {
            gridColumnGap: string;
            font: MobileAndDesktop;
            borderBottom: string;
            padding: MobileAndDesktop;
          };
          costs: {
            gridColumnGap: string;
            gridRowGap: string;
            padding: string;
            cost: { font: MobileAndDesktop };
            title: { font: MobileAndDesktop };
            subTitle: { font: MobileAndDesktop };
          };
          assets: {
            backgroundColor: string;
            flexGap: string;
            title: { font: MobileAndDesktop };
            value: { font: MobileAndDesktop };
            padding: MobileAndDesktop;
            asset: { gridColumnGap: string; gridRowGap: string };
          };
          attributes: {
            gridColumnGap: string;
            gridRowGap: string;
            padding: string;
            borderBottom: string;
            title: { flexGap: string; font: MobileAndDesktop };
            value: { font: MobileAndDesktop };
          };
          footer: {
            flexGap: string;
            title: { font: MobileAndDesktop };
            body: { font: MobileAndDesktop };
            padding: MobileAndDesktop;
          };
        };
      };
      toggle: {
        width: string;
        height: string;
        backgroundColor: string;
        insert: {
          width: string;
          height: string;
        };
      };
      toggler: {
        font: string;
        fontColor: string;
        active: {
          font: string;
          fontColor: string;
        };
      };
    };
    detailedComparator: {
      attributes: {
        title: { font: MobileAndDesktop; marginBottom: MobileAndDesktop };
        value: { padding: { desktop: string } };
        tooltipLabel: { font: string; color: string };
        tooltipContent: { font: string; color: string };
        attributeTitle: { font: string };
        attributeValue: {
          font: string;
          alignItems: MobileAndDesktop;
          justifyContent: MobileAndDesktop;
          padding: string;
        };
        list: { padding: MobileAndDesktop; flexGap: MobileAndDesktop };
        borderBottom: string;
        borderTop: string;
        note: {
          title: { font: string; marginBottom: string; color: string };
          body: { font: string; color: string };
          list: { gap: string };
        };
        marginBottom: MobileAndDesktop;
        columnGap: string;
        rowGap: string;
        minHeight: MobileAndDesktop;
      };
      tabDefaultHeader: {
        marginBottom: MobileAndDesktop;
        header: {
          defaultFont: {
            mobile: string;
            desktop: string;
          };
          boldFont: MobileAndDesktop;
          margin: MobileAndDesktop;
        };
        body: { defaultFont: { mobile: string } };
      };
    };
    container?: {
      maxWidth?: string;
      padding?: string;
    };
    steps: {
      active: {
        background: string;
      };
      completed: {
        background: string;
      };
      incomplete: {
        background: string;
      };
      alignItems: string;
      icon: {
        src: {
          desktop: string;
          mobile: string;
        };
        margin: {
          bottom: {
            mobile: string;
            tablet: string;
          };
          left: {
            mobile: string;
            tablet: string;
          };
        };
      };
      width: string;
      desktop: {
        width: string;
      };
      tablet: {
        width: string;
        position: string;
        margin: {
          top: string;
          bottom: string;
        };
      };
      borderRadius: string;
      step: {
        marginRight: {
          mobile: string;
          tablet: string;
        };
        height: {
          mobile: string;
          tablet: string;
        };
      };
      margin: {
        top: string;
        bottom: string;
      };
    };
    landingPage: {
      header: {
        contentBackgroundColor: string;
        color: string;
        font: {
          family: string;
        };
      };
      subtitle: {
        color: string;
        fontSize: string;
      };
    };
    footer: {
      padding: {
        top: string;
      };
    };
    pageFooter: {
      border: {
        top: string;
      };
      background: {
        color: string;
      };
      font: {
        color: string;
        family: string;
        size: string;
      };
      padding: {
        right: {
          mobile: string;
          tablet: string;
        };
        left: {
          mobile: string;
          tablet: string;
        };
        top: {
          mobile: string;
          tablet: string;
        };
        bottom: {
          mobile: string;
          tablet: string;
        };
      };
      iconHeight: string;
      linkWidth: string;
      nextSteps: {
        background: {
          color: string;
        };
        font: {
          family: string;
          color: string;
        };
        title: {
          padding: string;
          font: {
            family: string;
            color: string;
            size: string;
          };
        };
        icon: {
          margin: string;
        };
        link: {
          font: {
            family: string;
          };
          color: string;
        };
        margin: {
          top: string;
          right: string;
          bottom: string;
          left: string;
        };
      };
      copy: {
        border: {
          width: string;
          style: string;
          color: string;
        };
        font: {
          family: string;
          color: string;
        };
        textAlign: string;
        lineHeight: string;
        padding: {
          top: string;
          bottom: string;
        };
        width: string;
        margin: {
          left: string;
        };
      };
      footerBottom: {
        flex: {
          direction: string;
          wrap: string;
        };
        links: {
          color: string;
          separator: {
            content: string;
            spacing: {
              mobile: string;
              desktop: string;
            };
          };
          order: {
            mobile: string;
            tablet: string;
          };
          justifyContent: string;
          marginBottom: string;
          link: {
            margin: {
              top: string;
              right: string;
              bottom: string;
              left: string;
            };
            icon: string;
            textDecoration: string;
            cursor: string;
          };
          padding: {
            left: string;
            right: string;
            bottom: string;
          };
          img: {
            padding: {
              right: string;
            };
          };
          span: {
            margin: {
              bottom: string;
            };
          };
        };
        copy: {
          order: string;
        };
        disclaimer: {
          lineHeight: string;
        };
        disclaimerLine1: {
          order: {
            mobile: string;
            tablet: string;
          };
          padding: {
            left: string;
          };
          img: {
            padding: {
              left: string;
            };
          };
          color: string;
        };
        disclaimerLine2: {
          marginBottom: {
            mobile: string;
            desktop: string;
          };
          order: string;
        };
      };
    };
    form: {
      header: {
        font: string;
        margin: {
          mobile: string;
          desktop: string;
        };
        padding: string;
        backgroundColor: string;
      };
      borderColor: string;
      logo: {
        display: string;
        width: string;
      };
      margin: {
        bottom: string;
      };
      paddingLeft: string;
      backgroundColor: string;
      alignItems: string;
      label: {
        fontWeight: string;
        marginBottom: string;
        textAlign: string;
        fontSize: string;
      };
      tab: {
        margin: {
          right: string;
        };
        color: string;
        selected: {
          color: string;
          fontWeight: string;
        };
        font: {
          size: string;
        };
        header: {
          justifyContent: string;
        };
      };
      select: {
        color: string;
        backgroundColor: string;
        outlineFocus: string;
        borderBottom: string;
        borderBottomFocus: string;
        label: {
          font: {
            family: string;
            weight: string;
          };
        };
        font: string;
        height: string;
        icon: {
          top: string;
          right: string;
          width: string;
          height: string;
          focusColor: string;
          hoverColor: string;
          iconWidth: string;
          iconHeight: string;
          color: string;
        };
        disabledOpacity: string;
      };
      content: {
        marginTop: string;
        marginBottom: string;
        marginLeft: string;
        marginRight: string;
      };
      inputField: {
        width: string;
        height: string;
        marginBottom: {
          desktop: string;
          mobile: string;
        };
        border: string;
        fontWeight: string;
        textAlign: string;
        fontSize: string;
        background: string;
        borderRadius: string;
        placeholder: {
          color: string;
        };
        registration: {
          font: {
            size: string;
          };
          textAlign: string;
        };
      };
      buttons: {
        flexDirection: string;
        justifyContent: string;
        maxWidth: string;
      };
      errorFontSize: string;
      errorIconWidth: string;
      viewports: {
        mobile: {
          spacing: string;
          error: {
            flexDirection: string;
            paddingLeft: string;
            paddingRight: string;
            textMarginLeft: string;
            textMarginTop: string;
          };
        };
        desktop: {
          spacing: string;
          flexDirection: string;
          justifyContent: string;
          alignItems: string;
          error: {
            flexDirection: string;
            paddingLeft: string;
            paddingRight: string;
            marginLeft: string;
            textMarginLeft: string;
            textMarginTop: string;
          };
        };
      };
      border: {
        top: string;
        left: string;
        right: string;
        bottom: string;
      };
      disclaimer: {
        before: {
          content: string;
          textDecoration: string;
          textDecorationColor: string;
        };
      };
      vehicle: {
        font: string;
        margin: {
          top: {
            mobile: string;
            desktop: string;
          };
          bottom: {
            mobile: string;
            desktop: string;
          };
        };
        loader: {
          marginTop: string;
          fill: string;
        };
        image: {
          fill: string;
          width: string;
          mobile: {
            transform: string;
          };
        };
        display: string;
        flexDirection: string;
        justifyContent: string;
        body: {
          width: {
            mobile: string;
            desktop: string;
          };
          background: string;
        };
        label: {
          background: string;
          textAlign: string;
          display: string;
          alignItems: string;
          borderRadius: string;
          width: string;
          height: string;
          color: string;
          border: {
            width: string;
            style: string;
            color: string;
          };
          font: {
            weight: string;
            size: string;
          };
          padding: {
            left: string;
            right: string;
            top: string;
            bottom: string;
          };
          content: {
            padding: {
              top: string;
              bottom: string;
            };
            input: {
              display: string;
              justifyContent: string;
              alignItems: string;
            };
          };
          footer: {
            textAlign: string;
            padding: {
              left: string;
              right: string;
              top: string;
              bottom: string;
            };
            color: string;
          };
          vrm: {
            margin: {
              right: string;
            };
          };
        };
        group: {
          justifyContent: string;
          display: string;
          gap: string;
          margin: {
            top: string;
          };
          mobile: {
            margin: {
              top: string;
            };
          };
        };
        btn: {
          width: string;
          disable: {
            background: string;
            color: string;
          };
          hover: {
            background: string;
            color: string;
          };
        };
        errorMessage: {
          color: string;
          font: {
            size: string;
            weight: string;
          };
        };
      };
    };
    buttons: {
      base: ButtonBase;
      primary: ButtonType;
      secondary: ButtonType;
      tertiary: ButtonType;
      invertedPrimary: ButtonType;
      linkAsPrimary: ButtonType;
      disable: {
        opacity: string;
        backgroundColor: string;
        color: string;
        hover: {
          color: string;
        };
      };
      back: {
        secondary: {
          background: string;
          color: string;
        };
        icon: {
          source: string;
        };
      };
      backChevon: {
        padding: {
          top: string;
          right: string;
          bottom: string;
          left: string;
        };
        margin: {
          top: string;
        };
        img: {
          height: string;
          padding: {
            right: string;
          };
        };
        text: {
          fontSize: string;
          lineHeight: string;
        };
        alignItem: string;
        width: {
          mobile: string;
          tablet: string;
        };
      };
      evTree: {
        width: {
          mobile: string;
          tablet: string;
        };
        text: {
          lineHeight: string;
        };
      };
      trip: {
        backgroundColor: string;
        border: string;
        color: string;
        height: string;
        lineHeight: string;
        margin: {
          right: string;
          bottom: string;
        };
        hover: {
          backgroundColor: string;
        };
        before: {
          content: string;
          margin: {
            right: string;
          };
          color: string;
        };
        outlineOffset: string;
      };
    };
    modal: {
      closeButton: {
        border: string;
        borderRadius: string;
        position: {
          top: string;
          right: string;
        };
      };
      backgroundColor: string;
      background: {
        color: string;
      };
      back: {
        width: string;
        height: string;
      };
    };
    testTypeInformation: {
      padding: MobileAndDesktop;
      header: {
        color: string;
        font: string;
        margin: MobileAndDesktop;
        height: MobileAndDesktop;
      };
      title: {
        color: string;
        font: string;
        margin: string;
        justifyContent: string;
      };
      body: {
        color: string;
        font: string;
        margin: string;
      };
      link: {
        font: {
          lineHeight: string;
        };
      };
      button: {
        width: string;
      };
    };
    dataTableColumn: {
      width: {
        mobile: string;
        desktop: string;
      };
    };
    vehicleConfirm: {
      image: {
        width: {
          mobile: string;
          desktop: string;
        };
      };
      findAnotherVehicle: {
        font: string;
        color: string;
        cursor: string;
        marginTop: string;
        textDecoration: string;
      };
      details: {
        vrm: {
          font: string;
        };
        description: {
          font: string;
          color: string;
          margin: {
            top: string;
          };
        };
      };
      currentMileage: {
        maxWidth: string;
        width: string;
        background: string;
        display: string;
        flexDirection: string;
        alignItems: string;
        padding: string;
        description: {
          font: string;
          textAlign: string;
          maxWidth: {
            mobile: string;
            desktop: string;
          };
          color: string;
          marginTop: string;
        };
        form: {
          label: {
            font: string;
            textAlign: string;
            marginBottom: string;
            alignment: string;
            color: {
              default: string;
              optional: string;
            };
          };
          input: {
            height: string;
            width: {
              mobile: string;
              desktop: string;
            };
            textAlign: string;
            color: string;
            border: {
              width: string;
              style: string;
              color: string;
            };
            font: string;
            padding: string;
          };
        };
      };
      actionButtons: {
        display: string;
        flexDirection: string;
        marginBottom: string;
        marginTop: string;
        width: {
          mobile: string;
          desktop: string;
        };
        alignItems: string;
        cancel: {
          margin: string;
        };
      };
    };
    vehicleOption: {
      superscript: { font: string };
      showIcon: {
        prepend: boolean;
        append: boolean;
      };
      minHeight: string;
      padding: string;
      backgroundColor: string;
      unSelected: { backgroundColor: string };
      border: string;
      borderRadius: string;
      width: {
        mobile: string;
        desktop: string;
      };
      changeVehicle: {
        mobile: {
          justification: string;
        };
      };
      compareButton: {
        height: string;
        padding: string;
        iconHeight: string;
        iconWidth: string;
      };
      vehicleSummaryDetails: {
        backgroundColor: string;
        order: string;
        alignSelf: string;
        display: string;
        link: {
          display: string;
          height: string;
        };
        font: MobileAndDesktop;
        color: string;
      };
      image: {
        mobile: {
          height: string;
          stickyHeight: string;
          stickyWidth: string;
          width: string;
        };
        height: string;
        width: string;
      };
      price: {
        fontFamily: string;
        fontWeight: string;
      };
      hr: {
        color: string;
      };
      testStandard: {
        link: {
          color: string;
        };
        wrapper: {
          order: string;
          width: string;
          color: string;
          padding: string;
        };
        font: string;
        type: { font: string };
      };
    };
    vehicleSummary: {
      icon: string;
      width: string;
      height: string;
      title: { font: MobileAndDesktop; margin: MobileAndDesktop };
      description: { margin: MobileAndDesktop };
      changeButton: { margin: MobileAndDesktop };
      shopButton: { margin: MobileAndDesktop };
      closeButton: { icon: { backgroundColor: string } };
    };
    findVehicleForm: {
      padding: { mobile: string; desktop: string };
      font: string;
      buttons: {
        margin: MobileAndDesktop;
        first: {
          margin: MobileAndDesktop;
        };
      };
    };
    vehicleOptionsForm: {
      margin: string;
      mobile: {
        padding: {
          top: string;
          right: string;
          bottom: string;
          left: string;
        };
      };
      padding: string;
      title: {
        font: string;
        color: string;
      };
      description: {
        mobile: {
          margin: {
            top: string;
          };
        };
        font: string;
        color: string;
        width: string;
        marginTop: string;
      };
      options: {
        mobile: {
          margin: {
            top: string;
          };
        };
        option: {
          margin: {
            top: string;
            right: string;
            bottom: string;
          };
        };
        container: {
          border: string;
          borderRadius: string;
          width: {
            desktop: string;
            mobile: string;
          };
          height: string;
          hover: {
            border: string;
            padding: {
              top: string;
              right: string;
              bottom: string;
              left: string;
            };
            backgroundColor: string;
            color: string;
          };
          icon: {
            registrationNumber: {
              image: {
                default: string;
                hover: string;
                height: string;
              };
            };
            vehiclesList: {
              image: {
                default: string;
                hover: string;
                height: string;
              };
            };
            marginTop: string;
          };
          description: {
            font: string;
            color: string;
            marginTop: string;
          };
        };
      };
      button: {
        mobile: {
          marginTop: string;
        };
        marginTop: { mobile: string; desktop: string };
        width: string;
        height: string;
        background: string;
        color: string;
        hover: {
          background: string;
          color: string;
        };
      };
    };
    attributes: {
      alignItems: string;
      icon: {
        color: string;
      };
      title: {
        color: string;
      };
      tile?: {
        hover?: {
          borderColor?: string;
          backgroundColor?: string;
          outline?: string;
        };
      };
    };
    tabHeader: {
      color: MobileAndDesktop;
      font: string;
      backgroundColor: MobileAndDesktop;
      chevronIcon: { color: string; height: string; width: string };
      height: MobileAndDesktop;
      flexGap: string;
      justifyContent: string;
      borderBottom: string;
      margin: MobileAndDesktop;
      minHeight: string;
      spacing: {
        xxxl: string;
      };
      item: {
        font: string;
        hover: {
          borderBottomColor: string;
        };
        minHeight: string;
        minWidth: MobileAndDesktop;
        textTransform: string;
        border: {
          borderTop: string;
          borderLeft: string;
          borderRight: string;
          borderBottom: string;
          lastItem: {
            borderRight: string;
          };
        };
        padding: { desktop: string };
        svg: {
          fill: string;
          stroke: string;
        };
        width: { desktop: string };
      };
      itemDisabled: {
        border: {
          borderRight: string;
        };
        svg: {
          fill: string;
          stroke: string;
        };
      };
      itemActive: {
        underlineSpacing: string;
        underline: string;
        backgroundColor: string;
        color: MobileAndDesktop;
        border: {
          borderTop: string;
          borderLeft: string;
          borderRight: string;
          borderBottom: string;
        };
        svg: {
          fill: string;
          stroke: string;
          opacity: string;
        };
        active: {
          color: string;
        };
      };
      heading: {
        borderBottom: string;
      };
    };
    toggle: {
      position: string;
      margin: {
        mobile: {
          top: string;
          bottom: string;
          left: string;
        };
        desktop: {
          left: string;
        };
      };
      button: {
        margin: {
          mobile: {
            right: string;
            left: string;
          };
          desktop: {
            right: string;
            left: string;
          };
        };
        padding: {
          mobile: {
            top: string;
            bottom: string;
          };
          desktop: {
            top: string;
            bottom: string;
          };
        };
        border: {
          bottom: {
            width: string;
            style: string;
            color: string;
          };
        };
        focus: {
          outline: string;
        };
        active: {
          font: {
            color: string;
          };
          border: {
            color: string;
          };
        };
      };
      font: {
        color: string;
        family: string;
      };
    };
    prompt: {
      flexDirection: string;
      alignItems: string;
      textAlign: string;
      margin: string;
      content: {
        margin: string;
      };
      font: {
        family: string;
        weight: string;
      };
      buttons: {
        width: string;
        direction: string;
        margin: string;
        button: {
          margin: string;
        };
      };
    };
    ownLeaseToggle: {
      wrapper: {
        gap: string;
        font: string;
      };
    };
    runningCostTable: {
      margin: MobileAndDesktop;
      attributesHeading: {
        backgroundColor: string;
        title: {
          font: MobileAndDesktop;
          flexDirection: MobileAndDesktop;
          alignItems: MobileAndDesktop;
          padding: {
            mobile: string;
            desktop: string;
          };
          gap: MobileAndDesktop;
          borderBottom: MobileAndDesktop;
        };
        superscript: { font: string };
        subheading: {
          fontFamily: string;
          font: string;
          color: string;
        };
        value: {
          font: {
            mobile: string;
            desktop: string;
          };
          borderBottom: MobileAndDesktop;
          padding: MobileAndDesktop;
          justifyContent: MobileAndDesktop;
        };
        font: {
          family: string;
          weight: string;
        };
        MobileAlignItems: string;
        DesktopAlignItems: string;
        headerBorder: {
          borderBottom: { mobile: string; desktop: string; mobileFleetManagerSelected: string };
        };
        margin: MobileAndDesktop;
      };
      tableRow: {
        border: string;
        borderTop: string;
      };
      tableCell: {
        borderBottom: string;
        margin: string;
      };
      inputCell: {
        borderTop: string;
        borderBottom: string;
        borderLeft: string;
        borderRight: string;
        borderRadius: string;
        width: MobileAndDesktop;
        height: MobileAndDesktop;
        focus: {
          outline: string;
          borderBottom: string;
        };
        textAlign: string;
        padding: string;
      };
      title: {
        mobile: {
          margin: {
            bottom: string;
            left: string;
          };
        };
        tablet: {
          margin: {
            bottom: string;
            left: string;
          };
        };
      };
    };
    recalculateForm: {
      backgroundColor: string;
      wrapper: {
        border: {
          open: {
            width: string;
            style: string;
            color: string;
            radius: string;
          };
          closed: {
            width: string;
            style: string;
            color: string;
          };
        };
        toggle: {
          height: string;
          color: string;
          textDecoration: string;
          iconSize: string;
          font: string;
        };
        margin: {
          top: string;
          bottom: string;
        };
        form: {
          mobileTitle: {
            font: string;
          };
          border: {
            width: string;
            style: string;
            color: string;
            radius: string;
          };
          padding: string;
          inner: {
            padding: string[];
          };
          close: {
            border: {
              bottom: string;
            };
            minWidth: string;
          };
          cta: {
            alignSelf: string;
          };
        };
      };
      input: {
        height: string;
        padding: string;
      };
    };
    productCard: {
      title: {
        font: {
          family: string;
          size: string;
          color: string;
        };
      };
      intro: {
        font: {
          size: string;
          color: string;
        };
      };
      buttons: {
        font: {
          family: string;
        };
      };
    };
    hero: {
      ['landing-page']: HeroType;
      ['detailed-comparator']: HeroType;
    };
    titleAndDescription: TitleAndDescriptionType;
    infoPopup: {
      boxShadow: string;
      borderRadius: string;
      messageRunningCosts: string;
      background: string;
      arrowBackground: string;
      color: string;
      icon?: string;
      minWidth: string;
      maxWidth: string;
      padding: string;
      text: {
        width: string;
        textAlign: string;
        color: string;
        fontSize: string;
        whiteSpace: string;
        font: string;
      };
      close: {
        width: string;
        display: string;
      };
      message: {
        left: string;
        runningCost: {
          width: string;
          left: string;
        };
      };
      border: {
        color: string;
      };
      marginTop: string;
      image?: {
        height: string;
        width: string;
      };
    };
    monthlyCost: {
      color: string;
      font: {
        size: string;
        family: string;
        weight: string;
      };
      heading: {
        title: {
          font: { mobile: string };
          color: string;
          padding: MobileAndDesktop;
        };
        description: {
          color: string;
          font: { mobile: string };
        };
      };
      padding: {
        left: string;
      };
      inputs: {
        margin: string;
      };
      totals: {
        ownTypeOwn: {
          backgroundColor: MobileAndDesktop;
          innerBackgroundColor: string;
          padding: {
            mobile: string;
            desktop: string;
          };
          heading: { padding: MobileAndDesktop };
          titleAndValuesGap: string;
          title: {
            mobile: {
              font: string;
              margin: string;
            };
          };
          value: {
            mobile: {
              font: string;
              margin: string;
            };
          };
        };
        heading: {
          padding: MobileAndDesktop;
          flexGap: MobileAndDesktop;
        };
        value: {
          padding: MobileAndDesktop;
        };
        gap: MobileAndDesktop;
      };
    };
    businessToggle: {
      button: {
        font: string;

        border: {
          top: string;
          right: string;
          bottom: string;
          left: string;
        };

        hover: {
          borderBottom: string;
        };

        isSelected: {
          borderBottom: string;
        };
      };
      margin: {
        mobile: string;
        desktop: string;
      };
    };
    survey: {
      layout: {
        content: {
          width: string;
          maxWidth: string;
        };
        header: {
          padding: {
            right: string;
            left: string;
          };
        };
      };
      question: {
        width: string;
        description: {
          fontFamily: string;
          fontSize: {
            lg: string;
            xxl: string;
          };
          color: string;
          margin: {
            desktop: {
              top: string;
            };
            mobile: {
              top: string;
            };
          };
          footer: {
            margin: {
              top: string;
            };
            font: {
              size: string;
              weight: string;
            };
            lineHeight: string;
            color: string;
          };
        };
        link: {
          fontSize: string;
          color: string;
          margin: {
            top: string;
          };
          lineHeight: string;
        };
        fullMapLink: {
          fontSize: string;
          color: string;
          margin: {
            top: string;
          };
          lineHeight: string;
          displayDesktop: string;
        };
        title: {
          color: string;
          lineHeight: string;
        };
        options: {
          padding: {
            mobile: {
              top: string;
              right: string;
              bottom: string;
              left: string;
            };
            table: {
              top: string;
              right: string;
              bottom: string;
              left: string;
            };
          };
          minHeight: {
            mobile: string;
            tablet: string;
          };
        };
        multiInput: {
          padding: {
            mobile: {
              top: string;
              right: string;
              bottom: string;
              left: string;
            };
            table: {
              top: string;
              right: string;
              bottom: string;
              left: string;
            };
          };
        };
        inputType: {
          display: string;
          flexDirection: string;
          maxWidth: string;
          margin: {
            top: string;
            right: string;
            bottom: string;
            left: string;
          };
          color: string;
          input: {
            display: string;
            height: string;
            backgroundColor: string;
            border: string;
            borderRadius: string;
            padding: {
              top: string;
              right: string;
              bottom: string;
              left: string;
            };
            font: {
              size: string;
            };
            lineHeight: string;
            active: {
              outline: string;
            };
            color: string;
            destination: {
              width: string;
            };
            tripsCount: {
              width: string;
            };
          };
          label: {
            padding: {
              top: string;
              bottom: string;
            };
            font: {
              size: string;
            };
            lineHeight: string;
            color: string;
          };
          error: {
            margin: {
              top: string;
            };
            font: {
              size: string;
            };
            lineHeight: string;
            color: string;
            width: {
              max: string;
            };
          };
          form: {
            email: {
              width: string;
              margin: {
                bottom: string;
              };
            };
            vrm: {
              width: string;
            };
            homePostcode: {
              width: {
                mobile: string;
                desktop: string;
              };
            };
            regularDestination: {
              width: {
                mobile: string;
                desktop: string;
              };
            };
          };
        };
        option: {
          border: string;
          width: string;
          font: {
            size: string;
          };
          padding: {
            tablet: string;
            mobile: string;
          };
          margin: {
            desktop: {
              bottom: string;
            };
            mobile: {
              bottom: string;
            };
          };
          borderRadius: string;
          tablet: {
            width: string;
          };
          active: {
            background: string;
          };
          input: {
            display: string;
            label: {
              border: string;
              lineHeight: string;
              padding: {
                mobile: {
                  top: string;
                  right: string;
                  bottom: string;
                  left: string;
                };
                tablet: {
                  top: string;
                  right: string;
                  bottom: string;
                  left: string;
                };
              };
            };
            checked: {
              border: string;
              fontWeight: {
                tablet: string;
                mobile: string;
              };
            };
            radio: {
              height: {
                tablet: string;
                mobile: string;
              };
              width: {
                tablet: string;
                mobile: string;
              };
              display: {
                tablet: string;
                mobile: string;
              };
              border: {
                radius: string;
                style: string;
                width: string;
                color: string;
              };
              borderChecked: {
                background: string;
                color: string;
              };
            };
            verticalRadio: {
              color: string;
              margin: {
                right: string;
                bottom: {
                  desktop: string;
                  mobile: string;
                };
              };
              border: string;
              padding: {
                top: string;
                right: string;
                bottom: string;
                left: string;
              };
            };
          };
          alignItems: string;
          color: string;
        };
        label: {
          active: {
            webkitTapHighLightColor: string;
          };
        };
        boxTrip: {
          margin: {
            top: string;
            bottom: string;
          };
          display: string;
        };
      };
      startPage: {
        margin: {
          top: string;
          right: string;
          bottom: string;
          left: string;
        };
        width: string;
        height: string;
        header: {
          title: {
            margin: {
              top: string;
              bottom: string;
            };
            font: {
              color: string;
              size: string;
            };
            lineHeight: string;
          };
          subtitile: {
            font: {
              color: string;
              size: string;
            };
            lineHeight: string;
          };
        };
        button: {
          padding: {
            top: string;
            right: string;
            bottom: string;
            left: string;
          };
          font: {
            size: string;
          };
          lineHeight: string;
          outlineOffset: string;
        };
        tabletView: {
          justifyContent: string;
          width: string;
          height: string;
          header: {
            minWidth: string;
          };
          margin: {
            top: string;
            right: string;
            bottom: string;
            left: string;
          };
        };
        outlineOffset: string;
      };
      MapModal: {
        content: {
          height: string;
          maxHeight: string;
        };
        inner: {
          borderRadius: string;
          maxWidth: string;
          maxHeight: string;
        };
      };
    };
    results: {
      enhancedResults: {
        header: {
          color: string;
        };
        imgSrc: string;
        additionalLink: {
          font: {
            size: string;
            weight: string;
            lineHeight: string;
            color: string;
          };
        };
        section: {
          font: {
            color: string;
          };
        };
      };
      attributeHeader: {
        justifyContent: string;
        border: {
          bottom: string;
        };
        attribute: {
          minHeight: string;
          padding: {
            top: string;
            right: string;
            bottom: string;
            left: string;
          };
          evenAttribute: {
            background: {
              color: string;
            };
            border: {
              radius: {
                topLeft: string;
                topRight: string;
                bottomRight: string;
                bottomLeft: string;
              };
            };
          };
          left: {
            flex: string;
            width: string;
            tabletView: {
              width: string;
            };
            desktopView: {
              width: string;
            };
          };
          icon: {
            justifyContent: string;
            alignItems: string;
            flex: string;
            globalSvg: {
              fill: string;
              transform: string;
            };
          };
          text: {
            title: {
              margin: {
                bottom: string;
              };
              font: {
                weight: string;
                size: string;
              };
              textAlign: string;
            };
          };
        };
      };
      attribute: {
        figureAmount: {
          font: string;
        };
        figureUnit: {
          font: string;
          color: string;
        };
        icon: {
          globalSvg: {
            fill: string;
            transform: string;
          };
          infoPopup: {
            margin: {
              top: string;
              right: string;
              bottom: string;
              left: string;
            };
          };
        };
      };
      vehicle: {
        margin: {
          left: string;
          right: string;
          top: string;
        };
        title: {
          display: string;
          flexFlow: string;
          justifyContent: string;
          alignItems: string;
          padding: {
            top: string;
            right: string;
            bottom: string;
            left: string;
          };
          model: {
            font: string;
          };
          fuelAndTransmission: {
            display: string;
            marginLeft: string;
            fontSize: string;
            lintHeight: string;
            color: string;
          };
          price: {
            display: string;
            startingFrom: {
              font: string;
            };
            value: {
              fontMobile: string;
              fontDesktop: string;
            };
          };
          font: { desktop: string; mobile: string };
        };
        inner: {
          attributeAmount: number;
          border: {
            tabletView: {
              bottom: {
                width: string;
                style: string;
                color: string;
              };
              top: {
                width: string;
                style: string;
                color: string;
              };
            };
            top: {
              width: string;
              style: string;
              color: string;
            };
            bottom: {
              width: string;
              style: string;
              color: string;
            };
          };
          header: {
            image: {
              maxWidth: string;
              maxHeight: string;
              width: string;
              height: string;
              tabletView: {
                width: string;
              };
              desktopView: {
                width: string;
              };
            };
            showDetail: {
              border: {
                top: string;
              };
            };
          };
          attribute: {
            border: {
              bottom: string;
            };
            odd: {
              background: {
                color: string;
              };
            };
            title: {
              font: string;
            };
            amount: {
              font: string;
            };
            unit: {
              font: string;
            };
            marginBottom: string;
          };
          icon: {
            fill: string;
          };
        };
        borderBottom: string;
      };
      layout: {
        margin: {
          top: string;
          right: string;
          bottom: string;
        };
        padding: {
          mobile: string;
          desktop: string;
        };
      };
      filters: {
        padding: {
          top: string;
          right: string;
          bottom: string;
          left: string;
        };
        margin: {
          right: string;
        };
        borderRadius: string;
        background: {
          color: string;
        };
        width: {
          desktop: string;
          mobile: string;
        };
        height: string;
        fontSize: string;
        accordion: {
          header: {
            padding: string;
          };
          content: {
            padding: {
              top: string;
              right: string;
              bottom: string;
              left: string;
            };
          };
          maxWidth: string;
          fontSize: string;
          divider: {
            border: {
              top: string;
              right: string;
              bottom: string;
              left: string;
            };
            margin: {
              top: string;
              right: string;
              bottom: string;
              left: string;
            };
          };
          label: {
            color: string;
          };
        };
        option: {
          marginRight: string;
        };
      };
      noResults: {
        padding: string;
        font: {
          size: {
            desktop: string;
            mobile: string;
          };
          weight: string;
          lineHeight: {
            desktop: string;
            mobile: string;
          };
        };
        minHeight: string;
      };
      personalFigures: {
        padding: {
          desktop: string;
          mobile: string;
        };
        margin: {
          top: string;
        };
        personalFigure: {
          padding: string;
          margin: {
            right: string;
            bottom: string;
          };
          background: string;
          borderRadius: string;
          height: string;
          title: {
            font: {
              size: string;
              weight: string;
              lineHeight: string;
            };
            padding: {
              mobile: string;
              highRes: string;
            };
          };
          content: {
            font: {
              size: string;
              weight: string;
              lineHeight: string;
            };
          };
          unit: {
            font: {
              size: string;
              weight: string;
              lineHeight: string;
            };
          };
          financialSavings: {
            background: string;
          };
          co2Savings: {
            background: string;
          };
          InfoPopup: {
            top: string;
            right: string;
          };
        };
      };
      footer: {
        heading: {
          font: {
            size: string;
            weight: string;
          };
          margin: {
            bottom: string;
          };
        };
        text: {
          font: {
            size: string;
          };
          color: string;
        };
        margin: {
          mobile: string;
          desktop: string;
        };
      };
      findYourCar: {
        margin: {
          top: string;
        };
        border: {
          top: string;
          right: string;
          bottom: string;
          left: string;
        };
        padding: {
          desktop: string;
          mobile: string;
        };
        title: {
          font: {
            size: string;
            weight: string;
            lineHeight: string;
          };
          margin: {
            top: string;
          };
        };
        description: {
          font: {
            size: string;
            weight: string;
            lineHeight: string;
            color: string;
          };
          margin: {
            top: string;
          };
        };
      };
    };
    loader: {
      text: {
        fontSize: string;
      };
      icon: {
        fill: string;
      };
    };
    nextStepsRedesign: {
      color: string;
      backgroundColor: string;
      marginMobile: string;
      marginDesktop: string;
      paddingMobile: string;
      paddingDesktop: string;
      title: {
        fontMobile: string;
        fontDesktop: string;
        marginMobile: string;
        marginDesktop: string;
      };
      stepTitle: { margin: string; font: string };
      stepDescription: { margin: string; font: string };
      stepCTA: {
        paddingMobile: string;
        paddingDesktop: string;
      };
      link: { hoverColor: string };
    };
    navMenu: {
      trigger: {
        font: MobileAndDesktop;
      };
      header: {
        height: string;
        padding: string;
        backgroundColor: string;
        borderBottom: string;
        font: string;
      };
      logo: { margin: string };
      closeButton: {
        borderRadius: string;
        margin: string;
        width: string;
        height: string;
        padding: string;
        hover: {
          backgroundColor: string;
        };
        icon: {
          width: string;
          height: string;
          backgroundColor: string;
        };
      };
      backButton: {
        borderRadius: string;
        padding: string;
        margin: string;
        hover: {
          backgroundColor: string;
        };
        icon: {
          width: string;
          height: string;
          backgroundColor: string;
        };
      };
      linksWrapper: {
        padding: string;
        atStart: { borderBottom: string; padding: string };
      };
      link: {
        listItem: {
          padding: string;
          height: string;
          hover: {
            backgroundColor: string;
          };
        };
        icon: { width: string; height: string; backgroundColor: string };
        font: string;
      };
    };
  };
  tools: {
    detailedComparator: {
      tabs: {
        default: {
          title: {
            font: {
              family: string;
              size: string;
            };
          };
        };
      };
    };
    evTree: {
      content: {
        maxWidth: string;
        margin: {
          top: string;
          right: string;
          bottom: string;
          left: string;
        };
        tabletView: {
          padding: {
            top: string;
            right: string;
            bottom: string;
            left: string;
          };
        };
        desktop: {
          padding: {
            top: string;
            right: string;
            bottom: string;
            left: string;
          };
        };
      };
      question: {
        container: {
          alignItem: string;
          width: string;
          margin: {
            desktop: {
              top: string;
              bottom: string;
            };
            mobile: {
              top: string;
              bottom: string;
            };
          };
        };
      };
    };
  };
  showcase: {
    background: string;
    font?: {
      mobile: string;
      desktop: string;
    };
  };
  vrmLookup: {
    background: string;
  };
  modal: {
    default: {
      close: {
        color: string;
        opacity: string;
      };
    };
    totalCostOfOwnership: {
      close: {
        color: string;
        opacity: string;
      };
    };
  };
  layout: {
    pageHeader: {
      backgroundColor: string;
      height: {
        desktop: string;
        mobile: string;
      };
      logo: {
        imageSrc: string;
        width: {
          desktop: string;
          tablet: string;
          mobile: string;
        };
        height: string;
        padding: {
          left: {
            desktop: string;
            mobile: string;
          };
        };
        margin: string;
        justifyContent: string;
        alignItems: string;
        container: {
          display: string;
          gridTemplateAreas: string;
          gridTemplateColumns: MobileAndDesktop;
          gridColumnGap: MobileAndDesktop;
          width: string;
          height: MobileAndDesktop;
          before: { height: string; backgroundColor: string };
        };
      };
    };
    background: {
      image: {
        desktop: {
          src: string;
          height: string;
          width: string;
          display: string;
          alignSelf: string;
          background: {
            positionX: string;
          };
          size: string;
          position: string;
          margin: {
            left: string;
          };
        };
        tablet: {
          display: string;
        };
        mobile: {
          display: string;
        };
        startPage: {
          margin: {
            top: string;
          };
        };
        questionPage: {
          margin: {
            top: string;
            bottom: string;
          };
        };
      };
    };
    map: {
      circle: {
        strokeColor: string;
        strokeOpacity: number;
        strokeWeight: number;
        fillColor: string;
        fillOpacity: number;
      };
      order: {
        desktop: number;
        tablet: number;
        mobile: number;
      };
      desktop: {
        height: string;
        width: string;
        display: string;
        margin: {
          left: string;
          top: string;
        };
      };
      tablet: {
        display: string;
      };
      mobile: {
        display: string;
      };
      marker: {
        startPoint: string;
        endPoint: string;
        currentLocation: string;
      };
      polyline: {
        color: string;
      };
    };
    survey: {
      step: {
        margin: {
          top: string;
        };
      };
    };
  };
  NEDCFlag?: {
    color: string;
  };
  viewports: {
    xsMobile: string;
    mobile: string;
    xsTablet: string;
    tablet: string;
    desktop: string;
    highRes: string;
  };
}

export type Theme<T> = T extends object
  ? {
      [P in keyof T]?: Theme<T[P]>;
    }
  : T;

export interface Config {
  publicRuntimeConfig: {
    PUBLIC_GOOGLE_MAPS_API_KEY: string;
  };
}

export interface AttributeFigureAmountDisplayType {
  [key: string]: string;
}

export interface SplitModelByBrandType {
  [key: string]: boolean;
}

export interface DisplayStartingFromByBrandType {
  [key: string]: boolean;
}

export enum BodyStyleAttribute {
  SUV = 'SUV',
  SALOON = 'Saloon',
  ESTATE = 'Estate',
  HATCHBACK = 'Hatchback',
  COUPE = 'Coupe',
  MPV = 'MPV',
  CONVERTIBLE = 'Convertible',
}

export enum Attribute {
  NONE = 'None',
  EMISSIONS = 'Emissions',
  FUEL_COST = 'Fuel Cost',
  ROAD_TAX = 'Road Tax',
  CLEAN_AIR_ZONE_CHARGES = 'Clean Air Zone Charges',
  MAINTENANCE = 'Maintenance',
  ELECTRIC_DRIVING_RANGE = 'Electric Driving Range',
  FUEL_EFFICIENCY = 'Fuel Efficiency',
  PERFORMANCE = 'Performance',
  BENEFIT_IN_KIND = 'Benefit in Kind',
  RESIDUAL_VALUE = 'Residual Value',
  EMPLOYERS_NATIONAL_INSURANCE = 'Employers National Insurance',
  EURO_EMISSION_STANDARD = 'Euro Emission Standard',
  TOP_SPEED = 'Top Speed',
  ACCELERATION = 'Acceleration',
  SEATS = 'Seats',
  MILES_PER_KWH = 'Miles Per kWh',
  BODY_STYLE = 'Body Style',
  ADVANCED_PAYMENT = 'Advanced Payment',
  TEST_TYPE = 'Test Type',
  MODEL = 'Model',
  PRICE = 'Price',
  MONTHLY_COST = 'Monthly cost',
}

export enum Tab {
  KEY_SPECIFICATION = 'key-specification',
  ENVIRONMENTAL = 'environmental',
  TECHNOLOGY = 'technology',
  PERFORMANCE = 'performance',
  SAFETY = 'safety',
  RUNNING_COSTS = 'running-costs',
}

export enum DataBubbles {
  ELECTRIC_DRIVING_RANGE = Attribute.ELECTRIC_DRIVING_RANGE,
  CLEAN_AIR_ZONE_CHARGES = Attribute.CLEAN_AIR_ZONE_CHARGES,
  EMISSIONS = Attribute.EMISSIONS,
  EURO_EMISSION_STANDARD = Attribute.EURO_EMISSION_STANDARD,
  PERFORMANCE = Attribute.PERFORMANCE,
  TOP_SPEED = Attribute.TOP_SPEED,
  FUEL_EFFICIENCY = Attribute.FUEL_EFFICIENCY,
  ACCELERATION = Attribute.ACCELERATION,
  MILES_PER_KWH = Attribute.MILES_PER_KWH,
}

export interface TabItem {
  label: string;
  value: string;
  Icon: string;
  init?: boolean;
}

export interface CarCost {
  type: JourneyType;
  monthlyPrice: string;
  percentage: number;
}

export interface CarAttribute {
  type: Attribute;
  value: string;
  percentage: number;
  unit: string;
  error?: string;
}

export interface CarBodyStyleAttribute {
  type: Attribute;
  value: string;
  unit: string;
}

export interface RoadTaxAttribute {
  type: Attribute;
  values: CarAttribute[];
  error?: string;
}

export interface FuelCostAttribute {
  type: Attribute;
  values: CostAttribute[];
  error?: string;
}

interface CostAttribute {
  type: string;
  value: string;
  percentage: number;
  unit: string;
}

export interface Comparison {
  currentMileage?: number;
  id: number;
  manufacturer: string;
  model: string;
  shortModel: string;
  description: string;
  totalPrice: string;
  transmission: string;
  fuel: string;
  fuelTypeCategory: FuelCategory;
  bodyStyle: string;
  costs: CarCost[];
  attributes: CarAttribute[] | RoadTaxAttribute[] | FuelCostAttribute[];
  options?: VehicleOptions;
  testType: TestType;
  vrm?: string;
  registrationYear?: number;
}

export interface VehicleOption {
  title: VehicleOptionHeadings;
  content: string;
  type: string;
  ordering: number;
}

export type VehicleOptions = VehicleOption[];

export type VehicleComparisonOptions = VehicleOptions[];

export interface CostComparisons {
  model: string;
  cost: string;
  percentage: number;
  fuel: string;
  shortModel: string;
  manufacturer: string;
}

export enum Position {
  LEFT = 0,
  RIGHT = 1,
}

export enum FuelCategory {
  HYBRID = 'Hybrid',
  ELECTRIC = 'Electric',
  PETROL = 'Petrol',
  DIESEL = 'Diesel',
}

export interface Trim {
  id: string;
  name: string;
  modelId: string;
}

export interface Model {
  id: number;
  name: string;
  trims: Trim[];
}

export interface CarCategory {
  category: FuelCategory;
  models: Model[];
}

export enum BodyType {
  ESTATE = 'Estate',
  SUV = 'SUV',
  MPV = 'MPV',
}

export enum FuelType {
  PLUG_IN_HYBRID = 'Plug-in hybrid',
  ELECTRIC = 'Pure electric',
}

export enum OptionType {
  PRICE = 'price',
  STRING = 'string',
  BOOLEAN = 'boolean',
}

export interface Logger {
  debug: (message: string) => void;
  error: (message: string) => void;
  info: (message: string) => void;
  warn: (message: string) => void;
}

export enum KeyboardKey {
  ESCAPE = 'Escape',
  ESC = 'Esc',
  ENTER = 'Enter',
  SPACE = 'Space',
  BACKSPACE = 'Backspace',
  LEFT = 'ArrowLeft',
  RIGHT = 'ArrowRight',
  DELETE = 'Delete',
}

export enum CostData {
  PRIVATE = 0,
  COMPANY_CAR_DRIVER = 1,
  FLEET_MANAGER = 2,
}

export enum Months {
  ONE_YEAR = 12,
  THREE_YEAR = 36,
}

export enum FormTypes {
  CHANGE_VEHICLE = 'change-vehicle',
  ADD_MY_VEHICLE = 'add-my-vehicle',
}

export enum VrmLookupFormSteps {
  ENTER_REG = 'ENTER_REG',
  CONFIRM_VEHICLE = 'CONFIRM_VEHICLE',
}

export interface Locales {
  [key: string]: string;
}

export enum PillVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  QUATERNARY = 'quaternary',
}

export interface ComparisonResponse {
  comparisons: Comparison[];
}

export interface PlausibleConfiguration {
  enabled: boolean;
  domain: string;
}

declare global {
  interface Window {
    parentIFrame: any;
  }
}

export interface InitialFormValues {
  averageMileage: number;
  fuelPrice: string;
  electricityRate: string;
  cleanAirZone: {
    defaultEntries: number;
    maxEntriesPerYear: number;
  };
  financePeriodInYears: number;
  taxRate: number;
  leaseTerm: {
    min: number;
    max: number;
  };
  mileage: {
    min: number;
    max: number;
  };
}

export enum FormItems {
  AVERAGE_MILEAGE = 'Average Mileage',
  FUEL_PRICE = 'Fuel Price',
  CLEAN_AIR_ZONE_ENTRIES = 'Clean Air Zone Entries',
  ELECTRICITY_RATE = 'Electricity Rate',
  TAX_RATE = 'Tax Rate',
  OWN_TERM = 'Own Term',
  LEASE_TERM = 'Lease Term',
}

export enum LoggerLevel {
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

export enum TestTypes {
  WLTP = 'WLTP',
  NEDC = 'NEDC',
}

export enum SyntheticTestTypes {
  PHEV = 'PHEV',
  EV = 'EV',
}

export type TestType = `${TestTypes}`;
export type ModalTestType = `${TestTypes}` | `${SyntheticTestTypes}`;
