import { BaseTheme, Theme } from 'shared/types';
import { HeroContentPlacement } from 'features/Hero/constants';
import { radioTheme } from 'shared/components/Radio/theme';

enum Colours {
  primary = '#2861a4',
  secondary = '#fff',
  tertiary = '#707070',
  quaternary = '#102b4e',
  quinary = '#08095b',
  senary = '#000000',
}

enum Fonts {
  FordAntennaRegular = 'Ford Antenna',
  FordAntennaLight = 'Ford Antenna Light',
  FordAntennaCondRegular = 'Ford Antenna Cond',
  FordAntennaCondBold = 'Ford Antenna Cond Bold',
  FordAntennaCondLight = 'Ford Antenna Cond Light',
}

const lineHeight = {
  desktop: {
    heading1: '4.429rem', // 52px
    heading2: '2.857rem', // 32px
    heading3: '2.143', // 30px
    heading4: '1.786rem', // 30px
    copy1: '1.5rem', // 16px
    copy3: '1.286rem', // 12px
    copy2: '1.429rem', // 14px
  },
  mobile: {
    heading1: '4.429rem', // 52px
    heading2: '2.857rem', // 32px
    heading3: '2.143', // 30px
    heading4: '1.786rem', // 30px
  },
};

const ford: Theme<BaseTheme> = {
  favicons: {
    16: 'favicon-16x16.v2.svg',
    32: 'favicon-32x32.png',
    192: 'favicon-192x192.png',
  },
  colors: {
    primary: Colours.primary,
    secondary: Colours.secondary,
    tertiary: Colours.tertiary,
    quaternary: Colours.quaternary,
    quinary: Colours.quinary,
    senary: Colours.senary,
  },
  background: {
    primary: '#fff',
    secondary: '#FAFAFA',
  },
  border: {
    primary: '#CDCDCD',
    radius: '100px',
  },
  fonts: {
    regular: Fonts.FordAntennaRegular,
    bold: Fonts.FordAntennaCondBold,
    light: Fonts.FordAntennaLight,
  },
  form: {
    inputs: {
      radio: radioTheme(lineHeight, Colours),
    },
  },
  progress: {
    0: {
      background: '#E9F0E7',
      border: '#E0F1C6',
      bar: '#68875C',
    },
    1: {
      background: '#EAF7FF',
      border: '#C0DEF0',
      bar: Colours.primary,
    },
    border: {
      attributes: '0.5px solid',
      radius: '2.25rem',
    },
  },
  components: {
    steps: {
      active: {
        background: Colours.tertiary,
      },
      completed: {
        background: Colours.primary,
      },
      incomplete: {
        background: '#D5D5D5',
      },
    },
    landingPage: {
      header: {
        contentBackgroundColor: '#fafafa',
        font: {
          family: Fonts.FordAntennaRegular,
        },
      },
    },
    pageFooter: {
      font: {
        family: Fonts.FordAntennaLight,
        color: '#141414',
      },
      background: {
        color: '#fafafa',
      },
      padding: {
        right: {
          mobile: '24px',
          tablet: '24px',
        },
        left: {
          mobile: '24px',
          tablet: '24px',
        },
        top: {
          mobile: '24px',
          tablet: '24px',
        },
        bottom: {
          mobile: '24px',
          tablet: '24px',
        },
      },
      iconHeight: '90px',
      linkWidth: '120px',
      nextSteps: {
        background: {
          color: '#fff',
        },
        font: {
          family: Fonts.FordAntennaCondBold,
          color: '#000000',
        },
        title: {
          padding: '0',
          font: {
            family: Fonts.FordAntennaCondRegular,
            color: '#000000',
            size: '2rem',
          },
        },
        icon: {
          margin: '30px auto',
        },
        link: {
          font: {
            family: Fonts.FordAntennaLight,
          },
          color: Colours.primary,
        },
        margin: {
          top: '20px',
          right: '40px',
          bottom: '20px',
          left: '40px',
        },
      },
      copy: {
        font: {
          family: Fonts.FordAntennaLight,
          color: Colours.tertiary,
        },
      },
      footerBottom: {
        links: {
          order: {
            mobile: '1',
            tablet: '1',
          },
          justifyContent: 'center',
          marginBottom: '0px',
          link: {
            margin: {
              top: '4px',
              right: '8px',
              bottom: '8px',
              left: '8px',
            },
          },
          padding: {
            left: '24px',
            right: '24px',
          },
        },
        copy: {
          order: '2',
        },
        disclaimerLine1: {
          order: {
            mobile: '3',
            tablet: '3',
          },
          color: '#141414',
        },
        disclaimerLine2: {
          order: '4',
        },
      },
    },
    form: {
      tab: {
        selected: {
          fontWeight: 'bold',
        },
      },
      select: {
        color: Colours.primary,
        label: {
          font: {
            family: Fonts.FordAntennaRegular,
          },
        },
      },
      vehicle: {
        font: `400 16px/19px ${Fonts.FordAntennaRegular}`,
        margin: {
          top: {
            mobile: '10px',
            desktop: '10px',
          },
          bottom: { mobile: '90px', desktop: '90px' },
        },
        loader: {
          fill: '#A7A8A9',
        },
        image: {
          fill: '#A7A8A9',
        },
        display: 'flex',
        justifyContent: 'center',
        body: {
          width: {
            desktop: '450px',
            mobile: '450px',
          },
          background: '#FAFAFA',
        },
        label: {
          background: '#0158C1',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          width: '31px',
          height: '43px',
          borderRadius: '4px 0px 0px 4px',
          color: '#FFFFFF',
          border: {
            width: '1px',
            style: 'solid',
            color: '#000000',
          },
          font: {
            weight: '800',
            size: '14px',
          },
          padding: {
            left: '2px',
            right: '2px',
            top: '2px',
            bottom: '2px',
          },
          footer: {
            textAlign: 'center',
            padding: {
              top: '23px',
              right: '23px',
              bottom: '7px',
              left: '23px',
            },
            color: '#696969',
          },
          content: {
            input: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
            padding: {
              top: '31px',
              bottom: '10px',
            },
          },
          vrm: {
            margin: {
              right: '13px',
            },
          },
        },
        group: {
          justifyContent: 'center',
          display: 'flex',
          margin: {
            top: '62px',
          },
        },
        btn: {
          width: '137px',
          disable: {
            background: '#D0D0D0',
            color: '#696969',
          },
          hover: {
            background: '#000000',
            color: '#FFFFFF',
          },
        },
        errorMessage: {
          color: '#bf2012',
          font: {
            size: '14px',
            weight: '400',
          },
        },
      },
      inputField: {
        width: '210px',
        height: '43px',
        marginBottom: {
          desktop: '0',
          mobile: '0.5rem',
        },
        border: '1px solid #000000',
        fontWeight: 'normal',
        fontSize: '24px',
        textAlign: 'center',
        background: '#FBDA2D',
        borderRadius: '3px',
        registration: {
          font: {
            size: '1rem',
          },
          textAlign: 'left',
        },
      },
    },
    buttons: {
      base: {
        font: {
          family: Fonts.FordAntennaRegular,
        },
        borderRadius: '2rem',
        fontWeight: '500',
        backgroundColor: Colours.quaternary,
        fontColor: '#ffffff',
        borderWidth: '0.1rem',
        borderStyle: 'solid',
        borderColor: Colours.quaternary,
        transition: 'all 200ms ease-in-out',
        minWidth: '0px',
        minHeight: '43px',
        hover: {
          fontColor: '#ffffff',
          backgroundColor: Colours.primary,
        },
        mobile: {
          minWidth: '0px',
          minHeight: '43px',
        },
        icon: {
          appended: {
            position: {
              right: '-5px',
            },
            hover: {
              position: {
                right: '-5px',
              },
            },
          },
        },
      },
      backChevon: {
        width: {
          mobile: '272px',
        },
      },
      primary: {
        borderRadius: '4px',
        fontWeight: '500',
        backgroundColor: Colours.quaternary,
        fontColor: '#ffffff',
        borderWidth: '0.1rem',
        borderStyle: 'solid',
        borderColor: Colours.quaternary,
        transition: 'all 200ms ease-in-out',
        hover: {
          fontColor: '#ffffff',
          backgroundColor: Colours.primary,
          borderColor: Colours.primary,
        },
      },
      secondary: {
        borderRadius: '4px',
        fontWeight: '500',
        backgroundColor: '#fff',
        fontColor: Colours.primary,
        borderWidth: 'none',
        borderStyle: 'solid',
        borderColor: Colours.primary,
        transition: 'all 200ms ease-in-out',
        hover: {
          fontColor: '#fff',
          backgroundColor: Colours.primary,
          borderColor: Colours.primary,
        },
        icon: {
          color:
            'brightness(0) saturate(100%) invert(32%) sepia(92%) saturate(1130%) hue-rotate(187deg) brightness(84%) contrast(88%)',
          hover: {
            color:
              'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7484%) hue-rotate(41deg) brightness(94%) contrast(88%)',
          },
        },
      },
      tertiary: {
        borderRadius: '4px',
        fontWeight: 'normal',
        backgroundColor: 'transparent',
        fontColor: Colours.primary,
        borderWidth: 'none',
        borderStyle: '',
        borderColor: '',
        transition: 'none',
        hover: {
          fontColor: '#141414',
          backgroundColor: 'transparent',
        },
      },
      invertedPrimary: {
        borderRadius: '4px',
        fontWeight: '500',
        backgroundColor: 'transparent',
        fontColor: '#ffffff',
        borderWidth: '0.1rem',
        borderStyle: 'solid',
        borderColor: '#ffffff',
        transition: 'all 200ms ease-in-out',
        hover: {
          fontColor: Colours.quaternary,
          backgroundColor: '#ffffff',
        },
      },
    },
    modal: {
      closeButton: {
        border: '0',
        borderRadius: '0',
      },
      background: {
        color: '#333333',
      },
    },
    testTypeInformation: {
      title: {
        color: 'black',
        font: '',
      },
      body: {
        color: Colours.tertiary,
        font: '',
      },
      link: {
        font: {
          lineHeight: '18px',
        },
      },
    },
    dataTableColumn: {
      width: {
        mobile: '50%',
        desktop: '33.33%',
      },
    },
    vehicleConfirm: {
      findAnotherVehicle: {
        font: `600 1.143rem/18px ${Fonts.FordAntennaRegular}`,
        color: Colours.primary,
        cursor: 'pointer',
        marginTop: '12px',
      },
      details: {
        vrm: {
          font: 'inherit',
        },
        description: {
          font: '1.143rem',
        },
      },
      currentMileage: {
        maxWidth: '447px',
        width: '100%',
        background: '#fafafa',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '7px',
        description: {
          maxWidth: {
            mobile: '332px',
            desktop: '332px',
          },
          color: Colours.tertiary,
          marginTop: '8px',
        },
        form: {
          label: {
            marginBottom: '7px',
          },
          input: {
            height: '43px',
            width: {
              mobile: '177px',
              desktop: '177px',
            },
            border: {
              width: '1px',
              style: 'solid',
              color: '#cdcdcd',
            },
          },
        },
      },
      actionButtons: {
        display: 'flex',
        marginBottom: '22px',
        marginTop: '16px',
      },
    },
    vehicleOption: {
      showIcon: {
        prepend: false,
        append: false,
      },
      minHeight: '212px',
      padding: 'unset',
      backgroundColor: 'white',
      border: '1px dashed #707070',
      width: {
        mobile: '50%',
        desktop: '33.33%',
      },
      vehicleSummaryDetails: {
        backgroundColor: 'white',
        alignSelf: 'flex-start',
        display: 'inline',
        link: {
          display: 'none',
        },
      },
      image: {
        mobile: {
          height: 'auto',
          stickyHeight: 'auto',
          stickyWidth: '50%',
          width: '100%',
        },
        height: 'auto',
        width: '100%',
      },
      price: {
        fontFamily: Fonts.FordAntennaCondBold,
      },
      hr: {
        color: Colours.tertiary,
      },
      testStandard: {
        link: {
          color: Colours.primary,
        },
        wrapper: {
          order: '4',
          width: '100%',
          color: Colours.tertiary,
        },
      },
    },
    vehicleSummary: {
      icon: '/close.svg',
    },
    vehicleOptionsForm: {
      padding: '60px 38px 46px 38px',
      title: {
        font: `400 24px/18px ${Fonts.FordAntennaCondBold}`,
        color: '#000000',
      },
      description: {
        font: `400 16px/20px ${Fonts.FordAntennaRegular}`,
        color: Colours.tertiary,
        marginTop: '50px',
      },
      options: {
        option: {
          margin: {
            top: '77px',
            right: '33px',
          },
        },
        container: {
          border: '1px solid #D5D5D5',
          borderRadius: '4px',
          width: {
            desktop: '221px',
            mobile: '221px',
          },
          height: '198px',
          hover: {
            border: '2px solid #1C6BBA',
          },
          description: {
            font: `400 1.143rem/18px ${Fonts.FordAntennaCondLight}`,
            color: Colours.tertiary,
            marginTop: '40px',
          },
        },
      },
      button: {
        marginTop: { mobile: '117px', desktop: '117px' },
        width: '177px',
        background: Colours.quaternary,
        color: '#FFFFFF',
        hover: {
          background: '#000000',
          color: '#FFFFFF',
        },
      },
    },
    tabHeader: {
      backgroundColor: { mobile: '#FAFAFA', desktop: '#FAFAFA' },
      minHeight: '9.5rem',
      spacing: {
        xxxl: '2rem',
      },
      item: {
        minHeight: '7.625rem',
        textTransform: 'uppercase',
        border: {
          borderTop: '1px solid #FAFAFA',
          borderLeft: '1px solid #FAFAFA',
          borderRight: '1px solid #FAFAFA',
          borderBottom: 'none',
          lastItem: {
            borderRight: '1px solid #707070',
          },
        },
        svg: {
          fill: Colours.tertiary,
          stroke: 'none',
        },
      },
      itemDisabled: {
        border: {
          borderRight: '1px solid #FAFAFA',
        },
        svg: {
          fill: Colours.tertiary,
          stroke: 'none',
        },
      },
      itemActive: {
        backgroundColor: '#ffffff',
        border: {
          borderTop: '1px solid #707070',
          borderLeft: '1px solid #707070',
          borderRight: '1px solid #707070',
          borderBottom: 'none',
        },
        svg: {
          fill: Colours.primary,
          stroke: 'none',
          opacity: '0.5',
        },
        active: {
          color: Colours.senary,
        },
      },
      heading: {
        borderBottom: '1px solid #CDCDCD',
      },
    },
    toggle: {
      position: 'left',
      font: {
        color: '#CDCDCD',
        family: Fonts.FordAntennaLight,
      },
      margin: {
        mobile: {
          top: '8px',
          bottom: '8px',
          left: 'unset',
        },
        desktop: {
          left: 'unset',
        },
      },
      button: {
        margin: {
          mobile: {
            right: '8px',
            left: '8px',
          },
          desktop: {
            right: '12px',
            left: '12px',
          },
        },
        padding: {
          mobile: {
            top: '12px',
            bottom: '12px',
          },
          desktop: {
            top: '12px',
            bottom: '12px',
          },
        },
        border: {
          bottom: {
            width: '2px',
            style: 'solid',
            color: 'transparent',
          },
        },
        focus: {
          outline: `thin dotted ${Colours.quaternary}`,
        },
        active: {
          font: {
            color: 'unset',
          },
          border: {
            color: Colours.senary,
          },
        },
      },
    },
    prompt: {
      font: {
        family: Fonts.FordAntennaCondBold,
        weight: 'bold',
      },
    },
    attributes: {
      alignItems: 'center',
      icon: {
        color: Colours.primary,
      },
      title: {
        color: Colours.tertiary,
      },
    },
    runningCostTable: {
      attributesHeading: {
        title: {
          font: { mobile: Fonts.FordAntennaRegular, desktop: Fonts.FordAntennaRegular },
        },
        subheading: {
          fontFamily: Fonts.FordAntennaRegular,
        },
        value: {
          font: { mobile: Fonts.FordAntennaRegular, desktop: Fonts.FordAntennaRegular },
        },
        font: {
          family: Fonts.FordAntennaRegular,
        },
      },
      tableCell: {
        borderBottom: '0px',
        margin: '0',
      },
      inputCell: {
        borderTop: '1px solid #CDCDCD',
        borderBottom: '1px solid #CDCDCD',
        borderLeft: '1px solid #CDCDCD',
        borderRight: '1px solid #CDCDCD',
      },
      title: {
        mobile: {
          margin: {
            bottom: '1.142rem',
            left: '0',
          },
        },
        tablet: {
          margin: {
            bottom: '0',
            left: '1.142rem',
          },
        },
      },
    },
    productCard: {
      title: {
        font: {
          family: Fonts.FordAntennaCondRegular,
        },
      },
      buttons: {
        font: {
          family: Fonts.FordAntennaCondRegular,
        },
      },
    },
    recalculateForm: {
      input: {
        height: '43px',
      },
    },
    infoPopup: {
      messageRunningCosts: 'inherit',
      background: '#FAFAFA',
      color: '#FAFAFA',
      minWidth: '250px',
      maxWidth: '300px',
      text: {
        width: '',
        textAlign: 'center',
      },
      close: {
        width: '0px',
        display: 'none',
      },
      message: {
        left: '180px',
        runningCost: {
          width: '50px',
          left: '52vw',
        },
      },
      border: {
        color: '#fff',
      },
    },
    monthlyCost: {
      color: Colours.tertiary,
      font: {
        size: '1.2857rem',
        family: Fonts.FordAntennaRegular,
        weight: 'normal',
      },
      heading: {
        description: {
          color: Colours.tertiary,
        },
      },
      padding: {
        left: '2.571rem',
      },
    },
    hero: {
      'landing-page': {
        mobile: {
          height: '462px',
        },
        tablet: {
          height: '583px',
        },
        hiRes: {
          height: '672px',
        },
        ultraHiRes: {
          height: '672px',
        },
        images: {
          altText: 'A C40 connected to a charging point',
          srcSet: ['/assets/ford/', '/assets/ford/', '/assets/ford/', '/assets/ford/', '/assets/ford/'],
          srcSetMimeType: 'image/webp',
          fallback: '/assets/ford/',
          media: ['420px', '760px', '1024px', '1440px', '4000px'],
          fit: 'cover',
          position: {
            default: 'center bottom',
            480: 'center bottom',
            1440: ' center bottom',
            2560: 'center 60%',
          },
        },
        content: {
          display: {
            mobile: { title: HeroContentPlacement.INSIDE, subTitle: HeroContentPlacement.INSIDE },
            desktop: { title: HeroContentPlacement.INSIDE, subTitle: HeroContentPlacement.INSIDE },
          },
          width: {
            default: '272px',
            480: '100%',
          },
          padding: {
            default: {
              top: '24px',
              left: 'unset',
            },
            480: {
              top: '61px',
              left: 'unset',
            },
          },
          title: {
            justifyContent: {
              default: 'center',
              480: 'center',
            },
            text: {
              alignment: {
                default: 'center',
                480: 'center',
              },
            },
            color: '#fff',
            font: {
              family: Fonts.FordAntennaRegular,
              size: {
                default: '5.142rem',
                480: '5.142rem',
              },
            },
          },
          subTitle: {
            margin: {
              top: '1.643rem',
            },
            font: {
              size: '1.429rem',
            },
          },
        },
      },
    },
    businessToggle: {
      button: {
        border: {
          top: 'none',
          right: 'none',
          bottom: '1px solid #FAFAFA',
          left: 'none',
        },
        hover: {
          borderBottom: '1px solid #1C6BBA',
        },
        isSelected: {
          borderBottom: '1px solid #1C6BBA',
        },
      },
    },
    survey: {
      question: {
        option: {
          input: {
            checked: {
              border: '2px solid #1C6BBA',
            },
            radio: {
              borderChecked: {
                background: 'radial-gradient(#1C6BBA 0%, #1C6BBA 40%, transparent 50%, transparent)',
                color: Colours.primary,
              },
            },
          },
        },
      },
    },
    results: {
      attributeHeader: {
        justifyContent: 'center',
        attribute: {
          minHeight: '130px',
          evenAttribute: {
            background: {
              color: 'transparent',
            },
            border: {
              radius: {
                topLeft: '0px',
                topRight: '0px',
                bottomRight: '0px',
                bottomLeft: '0px',
              },
            },
          },
          left: {
            flex: 'inherit',
            width: '172px',
          },
          icon: {
            justifyContent: 'flex-end',
            flex: '1 0 25%',
            globalSvg: {
              fill: Colours.primary,
              transform: 'scale(0.66)',
            },
          },
          text: {
            title: {
              margin: {
                bottom: '4px',
              },
              font: {
                weight: 'bold',
                size: 'null',
              },
              textAlign: 'center',
            },
          },
        },
      },
      attribute: {
        figureAmount: {
          font: `1.2857rem Fonts.FordAntennaRegular`,
        },
        figureUnit: {
          font: '1rem initial',
          color: Colours.tertiary,
        },
        icon: {
          globalSvg: {
            fill: Colours.primary,
            transform: 'scale(0.5)',
          },
        },
      },
      vehicle: {
        margin: {
          left: '0px',
          right: '0px',
        },
        title: {
          display: 'flex',
          justifyContent: 'between',
          alignItems: 'stretch',
          padding: {
            top: '0px',
            right: '15px',
            bottom: '0px',
            left: '15px',
          },
          model: {
            font: `1.2857rem ${Fonts.FordAntennaRegular}`,
          },
          fuelAndTransmission: {
            display: 'none',
            marginLeft: '20px',
            fontSize: '13px',
            lintHeight: '18px',
            color: '#ccc',
          },
          price: {
            display: 'flex',
          },
        },
        inner: {
          border: {
            tabletView: {
              bottom: {
                width: '0.5px',
                style: 'dashed',
                color: '#5E7D95',
              },
              top: {
                width: '0.5px',
                style: 'dashed',
                color: '#5E7D95',
              },
            },
            top: {
              width: '1px',
              style: 'dashed',
              color: '#70707050',
            },
            bottom: {
              width: '1px',
              style: 'dashed',
              color: Colours.tertiary,
            },
          },
          header: {
            image: {
              maxWidth: 'auto',
              maxHeight: '225px',
              width: 'auto',
              height: 'auto',
            },
            showDetail: {
              border: {
                top: '1px solid #CDCDCD50',
              },
            },
          },
          attribute: {
            odd: {
              background: {
                color: '#FAFAFA',
              },
            },
          },
        },
      },
    },
    loader: {
      text: {
        fontSize: '1.143rem',
      },
      icon: {
        fill: '#A7A8A9',
      },
    },
  },
  showcase: {
    background: '#FAFAFA',
  },
  vrmLookup: {
    background: '#FDC238',
  },
  modal: {
    default: {
      close: {
        color: '#000',
        opacity: '0.5',
      },
    },
    totalCostOfOwnership: {
      close: {
        color: '#fff',
        opacity: '1',
      },
    },
  },
  layout: {
    pageHeader: {
      backgroundColor: Colours.quinary,
      logo: {
        imageSrc: '/assets/ford/common/ford_oval_blue_logo.svg',
      },
    },
    background: {
      image: {
        desktop: {
          src: '/assets/ford/',
          height: '378px',
          width: '704px',
        },
      },
    },
  },
  NEDCFlag: {
    color: '#a85f02',
  },
};

export { ford };
