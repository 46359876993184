import { BaseTheme, Theme } from 'shared/types';
import { HeroContentPlacement } from 'features/Hero/constants';
import { radioTheme } from 'shared/components/Radio/theme';

const colors = {
  primary: '#1C6BBA',
  secondary: '#fff',
  tertiary: '#707070',
  quaternary: '#004fbc',
  quinary: '#004fbc',
  senary: '#000000',
  offWhite: '#FAFAFA',
  grey100: '#F7F7F7',
  grey200: '#E1DFDD',
  grey300: '#D0D0D0',
  grey400: '#A7A8A9',
  grey500: '#888B8D',
  grey600: '#53565A',
  highlightDefault: '#004FBC',
  highlightActive: '#104E9B',
  highlightHover: '#2B8EDE',
  highlightHoverDark: '#76BAFF',
  black: '#141414',
};

const alpha = {
  primary: '32',
};

const fonts = {
  bold: 'Volvo Novum-Bold',
  regular: 'Volvo Novum',
  medium: 'Volvo Novum-Medium',
  light: 'Volvo Novum-Light',
  desktop: {
    h1: `500 48px/52px Volvo Novum-Medium`,
    h2: `500 32px/40px Volvo Novum-Medium`,
    h3: `500 24px/30px Volvo Novum-Medium`,
    h3light: `300 24px/30px Volvo Novum-Light`,
    h4: `500 20px/25px Volvo Novum-Medium`,
    copy1Medium: `500 16px/21px Volvo Novum-Medium`,
    copy1Regular: `400 16px/21px Volvo Novum`,
    copy2Medium: `500 14px/18px Volvo Novum-Medium`,
    copy2Regular: `400 14px/18px Volvo Novum`,
    copy3Medium: `500 12px/20px Volvo Novum-Medium`,
    copy3Regular: `400 12px/20px Volvo Novum`,
    button: `500 14px/22px Volvo Novum`,
  },
  mobile: {
    h1: `500 32px/40px Volvo Novum-Medium`,
    h2: `500 24px/30px Volvo Novum-Medium`,
    h3: `500 20px/25px Volvo Novum-Medium`,
    h3light: `300 20px/25px Volvo Novum-Light`,
    h4: `500 16px/21px Volvo Novum-Medium`,
  },
};

const fontSizes = {
  sm: '.857rem', // 12px
  md: '1rem', // 14px
  lg: '1.142rem', // 16px
  xl: '1.2857rem', // 18px
  xxl: '1.7857rem', // 25px
  xxxl: '2rem', // 28px
  xxxxl: '2.571rem', // 36px
  xxxxxl: '5.143rem', // 72px,
  desktop: {
    heading1: '3rem', // 48px
    heading2: '2rem', // 32px
    heading3: '1.5rem', // 24px
    heading4: '1.25rem', // 20px
    copy1: '1rem', // 16px [Base font]
    copy2: '0.875rem', // 14px
    copy3: '0.75rem', // 12px
  },
  mobile: {
    heading1: '2rem', // 32px
    heading2: '1.5rem', // 24px
    heading3: '1.25rem', // 20px
    heading4: '1rem', // 14px
  },
};

const lineHeight = {
  desktop: {
    heading1: '3.25rem', // 52px
    heading2: '2.5rem', // 40px
    heading3: '1.875rem', // 30px
    heading4: '1.563rem', // 25px
    copy1: '1.313rem', // 21px
    copy2: '1.125rem', // 18px
    copy3: '1.25rem', // 20px
  },
  mobile: {
    heading1: '2.5rem', // 40px
    heading2: '1.875rem', // 30px
    heading3: '1.563rem', // 25px
    heading4: '1.313rem', // 21px
  },
};

const volvo: Theme<BaseTheme> = {
  colors,
  fonts,
  fontSizes,
  lineHeight,
  alpha,
  favicons: {
    16: 'favicon-16x16.v2.svg',
    32: 'favicon-32x32.png',
    192: 'favicon-192x192.png',
  },
  background: {
    primary: '#fff',
    secondary: colors.offWhite,
  },
  border: {
    primary: '#CDCDCD',
    radius: '100px',
  },
  buttons: {
    globals: {
      font: fonts.desktop.button,
      color: colors.secondary,
      transform: 'uppercase',
      border: {
        width: '1px',
        style: 'solid',
        radius: '4px',
        color: colors.quaternary,
      },
      variants: {
        large: {
          width: '114px',
          height: '48px',
          padding: '12px 24px',
        },
        medium: {
          width: '114px',
          height: '40px',
          padding: '9px 24px',
        },
        small: {
          width: '114px',
          height: '32px',
          padding: '5px 24px',
        },
      },
      transition: 'all 200ms ease-in-out',
    },
    primary: {
      backgroundColor: colors.quaternary,
      border: {
        width: '1px',
        style: 'solid',
        radius: '4px',
        color: colors.quaternary,
      },
      font: {
        color: colors.secondary,
      },
      state: {
        hover: {
          borderColor: colors.senary,
          backgroundColor: colors.senary,
        },
        active: {
          transform: 'scale(0.95, 0.9)',
        },
        focussed: {
          backgroundColor: colors.quaternary,
          fontColor: colors.secondary,
          outline: {
            color: colors.quaternary,
            style: 'solid',
            width: '2px',
            offset: '2px',
          },
        },
        disabled: {
          color: colors.secondary,
          borderColor: `${colors.quaternary}00`,
          backgroundColor: `${colors.quaternary}${alpha.primary}`,
        },
      },
    },
    secondary: {
      backgroundColor: 'unset',
      border: {
        width: '1px',
        style: 'solid',
        radius: '4px',
        color: colors.quaternary,
      },
      font: {
        color: colors.quaternary,
      },
      state: {
        hover: {
          borderColor: colors.quaternary,
          backgroundColor: colors.quaternary,
          fontColor: colors.secondary,
        },
        active: {
          transform: 'scale(0.95, 0.9)',
          font: {
            color: colors.secondary,
          },
        },
        focussed: {
          backgroundColor: 'unset',
          fontColor: colors.quaternary,
          outline: {
            color: colors.quaternary,
            style: 'solid',
            width: '2px',
            offset: '2px',
          },
        },
        disabled: {
          color: `${colors.quaternary}${alpha.primary}`,
          borderColor: `${colors.quaternary}${alpha.primary}`,
          backgroundColor: colors.secondary,
        },
      },
    },
    tertiary: {
      backgroundColor: 'transparent',
      border: {
        width: 'none',
        style: 'none',
        radius: 'none',
        color: colors.quaternary,
      },
      font: {
        color: colors.quaternary,
      },
      state: {
        hover: {
          borderColor: 'none',
          backgroundColor: 'none',
          fontColor: colors.senary,
        },
        active: {
          transform: 'none',
          font: {
            color: `${colors.senary}56`,
          },
        },
        focussed: {
          backgroundColor: 'transparent',
          fontColor: colors.quaternary,
          outline: {
            color: colors.quaternary,
            style: 'solid',
            width: '2px',
            offset: '2px',
          },
        },
        disabled: {
          color: `${colors.quaternary}${alpha.primary}`,
          borderColor: 'none',
          backgroundColor: 'none',
        },
      },
    },
  },
  form: {
    inputs: {
      radio: radioTheme(lineHeight, colors),
    },
  },
  progress: {
    0: {
      background: '#E9F0E7',
      border: '#E0F1C6',
      bar: '#68875C',
    },
    1: {
      background: '#EAF7FF',
      border: '#C0DEF0',
      bar: '#1C6BBA',
    },
    border: {
      attributes: '0.5px solid',
      radius: '2.25rem',
    },
  },
  components: {
    simpleComparator: {
      vehicleSummary: {
        title: {
          font: { mobile: fonts.mobile.h3, desktop: fonts.desktop.h3 },
          margin: { mobile: '0 0 8px', desktop: '0 0 8px' },
        },
        description: {
          font: { mobile: fonts.desktop.copy1Regular, desktop: fonts.desktop.copy1Regular },
          margin: { mobile: '0 0 8px', desktop: '' },
        },
        price: {
          font: { mobile: fonts.desktop.copy1Medium, desktop: fonts.desktop.copy1Regular },
          margin: { mobile: '0 0 16px', desktop: '' },
        },
        changeVehicleButton: {
          icon: { color: colors.primary },
        },
        attribute: {
          title: { font: fonts.desktop.copy1Regular },
          value: { font: fonts.desktop.copy1Medium },
        },
      },
      runningCosts: {
        title: {
          font: { mobile: fonts.mobile.h3, desktop: fonts.desktop.h3 },
        },
        price: {
          font: { mobile: fonts.mobile.h3, desktop: fonts.desktop.h3 },
        },
        infoLink: {
          icon: { color: colors.primary },
        },
      },
      showcase: {
        attributeBar: {
          backgroundColor: colors.highlightHover,
          color: 'white',
          flexGap: { mobile: '8 px', desktop: '2px' },
          font: { mobile: fonts.desktop.h4, desktop: fonts.desktop.h4 },
          minHeight: { mobile: '105px', desktop: '' },
          minWidth: { mobile: '', desktop: '160px' },
          padding: { mobile: '', desktop: '16px' },
        },
        content: {
          padding: { mobile: '0 16px', desktop: '0' },
          vehicle: {
            titles: { flexGap: '16px' },
            title: { font: { mobile: fonts.desktop.h4, desktop: fonts.desktop.h4 } },
            value: { font: { mobile: fonts.desktop.h4, desktop: fonts.desktop.h4 } },
          },
        },
        footer: {
          flexGap: '16px',
          title: { font: { mobile: fonts.desktop.copy1Medium, desktop: fonts.desktop.copy1Medium } },
          subTitle: { font: { mobile: fonts.desktop.copy1Regular, desktop: fonts.desktop.copy1Regular } },
        },
        rowPadding: { mobile: '24px 0 16px', desktop: '32px 0 16px' },
        rowBorder: `1px solid ${colors.grey200}`,
        wrapper: { flexGap: { mobile: '', desktop: '16px' }, padding: { mobile: '', desktop: '16px' } },
      },
      calculations: {
        modal: {
          header: {
            flexGap: '8px',
            font: { mobile: fonts.mobile.h2, desktop: '' },
            backgroundColor: colors.grey100,
            height: { mobile: '98px', desktop: '87px' },
            padding: { mobile: '24px 16px 14px', desktop: '32px 24px 30px' },
          },
          content: { padding: { mobile: '16px', desktop: '16px' } },
          vehicles: {
            gridColumnGap: '14px',
            font: { mobile: fonts.mobile.h3, desktop: fonts.mobile.h3 },
            borderBottom: `1px solid ${colors.grey200}`,
            padding: { mobile: '0 0 16px', desktop: '0 0 24px' },
          },
          costs: {
            gridColumnGap: '14px',
            gridRowGap: '10px',
            padding: '16px 0',
            cost: { font: { mobile: fonts.mobile.h3, desktop: fonts.desktop.h4 } },
            title: { font: { mobile: fonts.mobile.h3, desktop: fonts.desktop.h4 } },
            subTitle: { font: { mobile: fonts.desktop.copy1Regular, desktop: fonts.desktop.copy1Regular } },
          },
          assets: {
            backgroundColor: colors.grey100,
            flexGap: '16px',
            title: { font: { mobile: fonts.desktop.copy1Medium, desktop: fonts.desktop.copy1Medium } },
            value: { font: { mobile: fonts.desktop.copy1Regular, desktop: fonts.desktop.copy1Regular } },
            padding: { mobile: '16px 0', desktop: '16px' },
            asset: { gridColumnGap: '14px', gridRowGap: '8px' },
          },
          attributes: {
            gridColumnGap: '14px',
            gridRowGap: '5.5px',
            padding: '16px 0',
            borderBottom: `1px solid ${colors.grey200}`,
            title: { flexGap: '8px', font: { mobile: fonts.desktop.copy1Medium, desktop: fonts.desktop.copy1Medium } },
            value: { font: { mobile: fonts.desktop.copy1Regular, desktop: fonts.desktop.copy1Regular } },
          },
          footer: {
            flexGap: '4px',
            title: { font: { mobile: fonts.desktop.copy3Medium, desktop: fonts.desktop.copy3Medium } },
            body: { font: { mobile: fonts.desktop.copy3Regular, desktop: fonts.desktop.copy3Regular } },
            padding: { mobile: '0 16px 16px', desktop: '0 16px 16px' },
          },
        },
      },
      toggle: {
        width: '40px',
        height: '24px',
        backgroundColor: colors.highlightActive,
        insert: {
          width: '20px',
          height: '20px',
        },
      },
      toggler: {
        font: fonts.desktop.copy1Regular,
        fontColor: colors.grey600,
        active: {
          font: fonts.desktop.copy1Medium,
          fontColor: colors.black,
        },
      },
    },
    detailedComparator: {
      attributes: {
        title: { font: { mobile: fonts.mobile.h2, desktop: fonts.desktop.h2 } },
        value: { padding: { desktop: '16px 0' } },
        tooltipLabel: { font: fonts.desktop.copy2Medium, color: colors.black },
        tooltipContent: { font: fonts.desktop.copy2Regular, color: colors.black },
        attributeTitle: { font: fonts.desktop.copy1Medium },
        attributeValue: {
          font: fonts.desktop.copy1Regular,
          alignItems: { mobile: 'flex-start', desktop: 'center' },
          padding: '16.5px 0 16px',
        },
        list: {
          padding: { mobile: '16.5px 0 16px', desktop: '0 0 24px' },
          flexGap: { mobile: '16.5px', desktop: '0' },
        },
        borderBottom: `1px solid ${colors.grey200}`,
        borderTop: `1px solid ${colors.grey200}`,
        note: {
          title: { font: fonts.desktop.copy3Medium, marginBottom: '16px', color: colors.black },
          body: { font: fonts.desktop.copy3Regular, color: colors.black },
        },
        marginBottom: { mobile: '26px', desktop: '44px' },
        columnGap: '0',
      },
      tabDefaultHeader: {
        marginBottom: { mobile: '24px', desktop: '40px' },
        header: {
          defaultFont: {
            mobile: fonts.mobile.h2,
            desktop: fonts.desktop.h2,
          },
          boldFont: { mobile: fonts.mobile.h2, desktop: fonts.desktop.h2 },
          margin: { mobile: '0 0 24px', desktop: '0 0 24px' },
        },
        body: { defaultFont: { mobile: fonts.desktop.copy1Regular } },
      },
    },
    steps: {
      active: {
        background: '#707070',
      },
      completed: {
        background: '#1C6BBA',
      },
      incomplete: {
        background: '#D5D5D5',
      },
    },
    landingPage: {
      header: {
        contentBackgroundColor: colors.offWhite,
        font: {
          family: 'Volvo Broad-Pro-Digital',
        },
      },
    },
    pageFooter: {
      font: {
        family: 'Volvo Novum-Light',
        color: colors.black,
      },
      background: {
        color: colors.offWhite,
      },
      padding: {
        right: {
          mobile: '24px',
          tablet: '24px',
        },
        left: {
          mobile: '24px',
          tablet: '24px',
        },
        top: {
          mobile: '24px',
          tablet: '24px',
        },
        bottom: {
          mobile: '24px',
          tablet: '24px',
        },
      },
      iconHeight: '90px',
      linkWidth: '150px',
      nextSteps: {
        background: {
          color: '#fff',
        },
        font: {
          family: fonts.medium,
          color: '#000000',
        },
        title: {
          padding: '0',
          font: {
            family: fonts.medium,
            color: '#000000',
            size: '2rem',
          },
        },
        icon: {
          margin: '30px auto',
        },
        link: {
          font: {
            family: 'Volvo Novum-Light',
          },
          color: '#1C6BBA',
        },
        margin: {
          top: '20px',
          right: '40px',
          bottom: '20px',
          left: '40px',
        },
      },
      copy: {
        font: {
          family: 'Volvo Novum-Light',
          color: '#707070',
        },
      },
      footerBottom: {
        links: {
          order: {
            mobile: '1',
            tablet: '1',
          },
          justifyContent: 'center',
          marginBottom: '0px',
          link: {
            margin: {
              top: '4px',
              right: '8px',
              bottom: '8px',
              left: '8px',
            },
          },
          padding: {
            left: '24px',
            right: '24px',
          },
        },
        copy: {
          order: '2',
        },
        disclaimerLine1: {
          order: {
            mobile: '3',
            tablet: '3',
          },
          color: colors.black,
        },
        disclaimerLine2: {
          order: '4',
        },
      },
    },
    form: {
      header: {
        font: `700 16px/19px ${fonts.medium}`,
      },
      logo: {
        width: '98px',
      },
      border: {
        bottom: `1px solid ${colors.senary}12`,
      },
      backgroundColor: colors.offWhite,
      tab: {
        selected: {
          fontWeight: 'bold',
        },
      },
      select: {
        color: colors.primary,
        outlineFocus: `thin dotted ${colors.quaternary}`,
        borderBottom: `1px solid ${colors.primary}`,
        borderBottomFocus: `2px solid ${colors.primary}`,
        label: {
          font: {
            family: fonts.regular,
          },
        },
        font: 'unset',
        icon: {
          top: '0px',
          right: '0px',
          width: '33px',
          height: '33px',
          iconWidth: '24px',
          iconHeight: '24px',
          color: colors.primary,
        },
      },
      vehicle: {
        font: `400 16px/19px ${fonts.regular}`,
        margin: {
          top: {
            mobile: '10px',
            desktop: '10px',
          },
          bottom: { mobile: '90px', desktop: '90px' },
        },
        loader: {
          fill: colors.grey400,
        },
        image: {
          fill: colors.grey400,
        },
        display: 'flex',
        justifyContent: 'center',
        body: {
          width: {
            desktop: '450px',
            mobile: '450px',
          },
          background: colors.offWhite,
        },
        label: {
          background: '#0158C1',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          width: '31px',
          height: '43px',
          borderRadius: '4px 0px 0px 4px',
          color: '#FFFFFF',
          border: {
            width: '1px',
            style: 'solid',
            color: '#000000',
          },
          font: {
            weight: '800',
            size: '14px',
          },
          padding: {
            left: '2px',
            right: '2px',
            top: '2px',
            bottom: '2px',
          },
          footer: {
            textAlign: 'center',
            padding: {
              top: '23px',
              right: '23px',
              bottom: '7px',
              left: '23px',
            },
            color: '#696969',
          },
          content: {
            input: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
            padding: {
              top: '31px',
              bottom: '10px',
            },
          },
          vrm: {
            margin: {
              right: '13px',
            },
          },
        },
        group: {
          justifyContent: 'center',
          display: 'flex',
          margin: {
            top: '62px',
          },
        },
        btn: {
          width: '137px',
          disable: {
            background: colors.grey200,
            color: '#696969',
          },
          hover: {
            background: '#000000',
            color: '#FFFFFF',
          },
        },
        errorMessage: {
          color: '#bf2012',
          font: {
            size: '14px',
            weight: '400',
          },
        },
      },
      inputField: {
        width: '210px',
        height: '43px',
        marginBottom: {
          desktop: '0',
          mobile: '0.5rem',
        },
        border: '1px solid #000000',
        fontWeight: 'normal',
        fontSize: '24px',
        textAlign: 'center',
        background: '#FBDA2D',
        borderRadius: '0px 4px 4px 0px',
        registration: {
          font: {
            size: '1rem',
          },
          textAlign: 'left',
        },
      },
    },
    modal: {
      closeButton: {
        border: '0',
        borderRadius: '0',
      },
      background: {
        color: '#333333',
      },
    },
    testTypeInformation: {
      header: {
        color: 'black',
        font: `24px ${fonts.regular}`,
      },
      title: {
        color: 'black',
        font: `16px/24px ${fonts.regular}`,
      },
      body: {
        color: '#707070',
        font: `14px/24px ${fonts.regular}`,
        margin: '0 0 25px',
      },
      link: {
        font: {
          lineHeight: '18px',
        },
      },
      button: {
        width: 'unset',
      },
    },
    dataTableColumn: {
      width: {
        mobile: '50%',
        desktop: '33.33%',
      },
    },
    vehicleConfirm: {
      findAnotherVehicle: {
        font: `600 1.143rem/18px ${fonts.regular}`,
        color: '#1c6bba',
        cursor: 'pointer',
        marginTop: '12px',
      },
      details: {
        vrm: {
          font: `400 ${fontSizes.xxl}/34px ${fonts.bold}`,
        },
        description: {
          font: `400 ${fontSizes.xl}/25px ${fonts.regular}`,
          color: colors.tertiary,
        },
      },
      currentMileage: {
        maxWidth: '447px',
        width: '100%',
        background: colors.offWhite,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '7px',
        description: {
          font: `400 1rem/18px ${fonts.regular}`,
          maxWidth: {
            mobile: '332px',
            desktop: '332px',
          },
          color: '#707070',
          marginTop: '8px',
        },
        form: {
          label: {
            marginBottom: '7px',
            font: `400 16px/18px ${fonts.regular}`,
            color: {
              default: colors.senary,
              optional: colors.senary,
            },
          },
          input: {
            height: '43px',
            width: {
              mobile: '177px',
              desktop: '177px',
            },
            border: {
              width: '1px',
              style: 'solid',
              color: '#cdcdcd',
            },
            font: `400 24px/18px ${fonts.regular}`,
          },
        },
      },
      actionButtons: {
        display: 'flex',
        marginBottom: '22px',
        marginTop: '16px',
      },
    },
    vehicleOption: {
      showIcon: {
        prepend: false,
        append: false,
      },
      minHeight: '212px',
      padding: 'unset',
      backgroundColor: 'white',
      unSelected: { backgroundColor: colors.secondary },
      border: '1px dashed #707070',
      width: {
        mobile: '50%',
        desktop: '33.33%',
      },
      vehicleSummaryDetails: {
        backgroundColor: 'white',
        alignSelf: 'flex-start',
        display: 'inline',
        link: {
          display: 'none',
        },
        color: colors.tertiary,
      },
      image: {
        mobile: {
          height: 'auto',
          stickyHeight: 'auto',
          stickyWidth: '50%',
          width: '100%',
        },
        height: 'auto',
        width: '100%',
      },
      price: {
        fontFamily: 'Volvo Novum-Bold',
      },
      hr: {
        color: 'transparent',
      },
      testStandard: {
        font: `400 16px/18px ${fonts.regular}`,
        link: {
          color: '#1c6bba',
        },
        wrapper: {
          order: '4',
          width: '100%',
          color: '#707070',
        },
      },
    },
    vehicleSummary: {
      icon: '/close.svg',
      changeButton: { margin: { mobile: '0 0 16px', desktop: '0 0 16px' } },
      shopButton: { margin: { mobile: '19px 0 0', desktop: '8px 0 0' } },
      description: { margin: { mobile: '0 0 8px', desktop: '0 0 12px' } },
      closeButton: { icon: { backgroundColor: colors.primary } },
    },
    findVehicleForm: {
      buttons: {
        margin: { mobile: '2rem 0 0', desktop: '2rem 0 0' },
      },
    },
    vehicleOptionsForm: {
      padding: '60px 38px 46px 38px',
      title: {
        font: fonts.desktop.h3,
        color: '#000000',
      },
      description: {
        font: fonts.desktop.copy1Regular,
        color: '#707070',
        marginTop: '50px',
      },
      options: {
        option: {
          margin: {
            top: '77px',
            right: '33px',
          },
        },
        container: {
          border: '1px solid #D5D5D5',
          borderRadius: '4px',
          width: {
            desktop: '221px',
            mobile: '221px',
          },
          height: '198px',
          hover: {
            border: '1px solid #1C6BBA',
          },
          description: {
            font: fonts.desktop.copy1Regular,
            color: '#707070',
            marginTop: '40px',
          },
        },
      },
      button: {
        marginTop: { mobile: '117px', desktop: '117px' },
        width: '177px',
        background: '#284E80',
        color: '#FFFFFF',
        hover: {
          background: '#000000',
          color: '#FFFFFF',
        },
      },
    },
    tabHeader: {
      color: { mobile: colors.senary, desktop: colors.tertiary },
      backgroundColor: { mobile: colors.secondary, desktop: colors.secondary },
      chevronIcon: { color: colors.primary, width: '11px', height: '19px' },
      height: { mobile: '48px', desktop: '96px' },
      margin: { mobile: '0', desktop: '0' },
      minHeight: '9.5rem',
      spacing: {
        xxxl: '0',
      },
      item: {
        hover: {
          borderBottomColor: `${colors.senary}50`,
        },
        minHeight: 'unset',
        border: {
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          borderBottom: 'none',
          lastItem: {
            borderRight: 'none',
          },
        },
        padding: { desktop: '0' },
        svg: {
          fill: '#707070',
          stroke: 'none',
        },
      },
      itemDisabled: {
        border: {
          borderRight: 'none',
        },
        svg: {
          fill: '#707070',
          stroke: 'none',
        },
      },
      itemActive: {
        backgroundColor: '#ffffff',
        color: { mobile: colors.senary, desktop: colors.tertiary },
        border: {
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          borderBottom: 'none',
        },
        underlineSpacing: '11px',
        underline: `2px solid ${colors.senary}`,
        svg: {
          fill: '#1C6BBA',
          stroke: 'none',
          opacity: '0.5',
        },
      },
      heading: {
        borderBottom: '1px solid #CDCDCD',
      },
    },
    toggle: {
      position: 'left',
      font: {
        color: colors.senary,
        family: fonts.desktop.copy1Medium,
      },
      margin: {
        mobile: {
          top: '8px',
          bottom: '8px',
          left: 'unset',
        },
        desktop: {
          left: 'unset',
        },
      },
      button: {
        margin: {
          mobile: {
            right: '8px',
            left: '8px',
          },
          desktop: {
            right: '12px',
            left: '12px',
          },
        },
        padding: {
          mobile: {
            top: '12px',
            bottom: '12px',
          },
          desktop: {
            top: '12px',
            bottom: '12px',
          },
        },
        border: {
          bottom: {
            width: '2px',
            style: 'solid',
            color: 'transparent',
          },
        },
        focus: {
          outline: `thin dotted ${colors.quaternary}`,
        },
        active: {
          font: {
            color: 'unset',
          },
          border: {
            color: colors.senary,
          },
        },
      },
    },
    prompt: {
      font: {
        family: 'Volvo Novum',
        weight: 'bold',
      },
    },
    attributes: {
      alignItems: 'center',
      icon: {
        color: colors.senary,
      },
      title: {
        color: '#707070',
      },
      tile: {
        hover: {
          borderColor: colors.highlightHoverDark,
          backgroundColor: colors.grey100,
          outline: `2px solid ${colors.highlightHoverDark}; outline-offset: 6px;`,
        },
      },
    },
    runningCostTable: {
      margin: { mobile: '36px 0 0', desktop: '48px 0 0' },
      attributesHeading: {
        backgroundColor: colors.secondary,
        title: {
          font: { mobile: fonts.mobile.h4, desktop: fonts.desktop.h4 },
          flexDirection: { mobile: 'row', desktop: 'column' },
          alignItems: { mobile: 'center', desktop: 'flex-start' },
          gap: { mobile: '4px', desktop: '0' },
          padding: {
            mobile: '0 0 16px',
            desktop: '0 0 13px 0',
          },
        },
        subheading: {
          fontFamily: 'Volvo Novum',
          font: fonts.desktop.copy1Regular,
          color: colors.black,
        },
        value: {
          font: { mobile: fonts.mobile.h2, desktop: fonts.desktop.h2 },
          padding: { mobile: '0 0 16px', desktop: '0' },
          justifyContent: { mobile: 'flex-start', desktop: 'center' },
        },
        font: {
          family: 'Volvo Novum',
        },
        headerBorder: {
          borderBottom: { mobile: `1px solid ${colors.grey200}`, desktop: `1px solid ${colors.grey200}` },
        },
      },
      tableCell: {
        borderBottom: '0px',
        margin: '0',
      },
      inputCell: {
        borderTop: '1px solid #CDCDCD',
        borderBottom: '1px solid #CDCDCD',
        borderLeft: '1px solid #CDCDCD',
        borderRight: '1px solid #CDCDCD',
        borderRadius: '4px',
        width: { mobile: '83px', desktop: '86px' },
        height: { mobile: '48px', desktop: '48px' },
      },
      title: {
        mobile: {
          margin: {
            bottom: '1.142rem',
            left: '0',
          },
        },
        tablet: {
          margin: {
            bottom: '0',
            left: '0',
          },
        },
      },
    },
    productCard: {
      title: {
        font: {
          family: 'Volvo Novum-Web-Latin-Medium',
        },
      },
      buttons: {
        font: {
          family: 'Volvo Novum-Medium',
        },
      },
    },
    recalculateForm: {
      backgroundColor: colors.offWhite,
      wrapper: {
        margin: {
          bottom: '3rem',
        },
        toggle: {
          color: colors.primary,
        },
      },
      input: {
        height: '43px',
      },
    },
    infoPopup: {
      boxShadow: '0 2px 6px rgba(20, 20, 20, 0.4)',
      borderRadius: '4px',
      messageRunningCosts: 'inherit',
      background: 'white',
      color: 'white',
      arrowBackground: 'white',
      minWidth: '250px',
      maxWidth: '300px',
      text: {
        width: '',
        textAlign: 'center',
      },
      close: {
        width: '0px',
        display: 'none',
      },
      message: {
        left: '180px',
        runningCost: {
          width: '50px',
          left: '52vw',
        },
      },
      border: {
        color: '#fff',
      },
      image: {
        height: '20px',
        width: '20px',
      },
    },
    monthlyCost: {
      color: '#707070',
      font: {
        size: '1.2857rem',
        family: 'Volvo Novum',
        weight: 'normal',
      },
      heading: {
        title: {
          font: { mobile: fonts.desktop.copy1Medium },
          color: colors.black,
        },
        description: {
          font: { mobile: fonts.desktop.copy1Regular },
          color: colors.black,
        },
      },
      padding: {
        left: '2.571rem',
      },
      inputs: {
        margin: '16px 0 0',
      },
      totals: {
        ownTypeOwn: {
          backgroundColor: { mobile: colors.grey100, desktop: colors.grey100 },
          padding: {
            mobile: '16px 0',
            desktop: '16px',
          },
          heading: { padding: { mobile: '0 0 16px', desktop: '0' } },
          title: {
            mobile: {
              font: fonts.desktop.copy1Medium,
              margin: '24px 0 0',
            },
          },
          value: {
            mobile: {
              font: fonts.desktop.copy1Regular,
              margin: '16px 0 0',
            },
          },
        },
        heading: {
          padding: { mobile: '16px 0 16px', desktop: '10px 0 16px' },
        },
        value: {
          padding: { mobile: '0 0 16px', desktop: '16px 0 16px' },
        },
        gap: { mobile: '24px', desktop: '24px' },
      },
    },
    hero: {
      'landing-page': {
        mobile: {
          height: '462px',
        },
        tablet: {
          height: '583px',
        },
        hiRes: {
          height: '672px',
        },
        ultraHiRes: {
          height: '672px',
        },
        images: {
          altText: 'A C40 connected to a charging point',
          srcSet: [
            '/assets/volvo/landing-page/hero/c40_recharge_fjord_blue-480px-4x3.webp',
            '/assets/volvo/landing-page/hero/c40_recharge_fjord_blue-1080px-4x3.webp',
            '/assets/volvo/landing-page/hero/c40_recharge_fjord_blue-1080px.webp',
            '/assets/volvo/landing-page/hero/c40_recharge_fjord_blue-1920px.webp',
            '/assets/volvo/landing-page/hero/c40_recharge_fjord_blue-4863px.webp',
          ],
          srcSetMimeType: 'image/webp',
          fallback: '/assets/volvo/landing-page/hero/c40_recharge_fjord_blue-1920px.jpg',
          media: ['420px', '760px', '1024px', '1440px', '4000px'],
          fit: 'cover',
          position: {
            default: 'center bottom',
            480: 'center bottom',
            1440: ' center bottom',
            2560: 'center 60%',
          },
        },
        content: {
          display: {
            mobile: { title: HeroContentPlacement.INSIDE, subTitle: HeroContentPlacement.INSIDE },
            desktop: { title: HeroContentPlacement.INSIDE, subTitle: HeroContentPlacement.INSIDE },
          },
          width: {
            default: '272px',
            480: '100%',
          },
          padding: {
            default: {
              top: '24px',
              left: 'unset',
            },
            480: {
              top: '61px',
              left: 'unset',
            },
          },
          title: {
            alignItems: {
              default: 'center',
              480: 'center',
            },
            justifyContent: {
              default: 'center',
              480: 'center',
            },
            text: {
              alignment: {
                default: 'center',
                480: 'center',
              },
            },
            color: '#fff',
            font: {
              family: 'Volvo Broad-Pro-Digital',
              size: {
                default: '5.142rem',
                480: '5.142rem',
              },
            },
          },
          subTitle: {
            justifyContent: {
              default: 'flex-start',
              '480': 'center',
            },
            textAlign: {
              default: 'left',
              '480': 'center',
            },
            margin: {
              top: '1.643rem',
            },
            font: {
              size: '1.429rem',
            },
          },
        },
      },
    },
    titleAndDescription: {
      padding: {
        top: ['24px', '48px'],
        bottom: ['40px', '40px'],
      },
    },
    businessToggle: {
      button: {
        font: fonts.desktop.copy2Medium,
        border: {
          top: 'none',
          right: 'none',
          bottom: '2px solid transparent',
          left: 'none',
        },
        hover: {
          borderBottom: `2px solid ${colors.grey200}`,
        },
        isSelected: {
          borderBottom: `2px solid ${colors.black}`,
        },
      },
      margin: {
        mobile: '0 0 24px',
        desktop: '0 0 24px',
      },
    },
    survey: {
      question: {
        description: {
          fontFamily: fonts.medium,
        },
        option: {
          input: {
            checked: {
              border: '2px solid #1C6BBA',
            },
            radio: {
              borderChecked: {
                background: 'radial-gradient(#1C6BBA 0%, #1C6BBA 40%, transparent 50%, transparent)',
                color: '#1C6BBA',
              },
            },
          },
        },
      },
    },
    results: {
      attributeHeader: {
        justifyContent: 'center',
        border: {
          bottom: `solid 2px ${colors.black}`,
        },
        attribute: {
          minHeight: '130px',
          evenAttribute: {
            background: {
              color: 'transparent',
            },
            border: {
              radius: {
                topLeft: '0px',
                topRight: '0px',
                bottomRight: '0px',
                bottomLeft: '0px',
              },
            },
          },
          left: {
            flex: 'inherit',
            width: '172px',
          },
          icon: {
            justifyContent: 'center',
            alignItems: 'flex-end',
            flex: 'none',
            globalSvg: {
              fill: colors.senary,
              transform: 'scale(1)',
            },
          },
          text: {
            title: {
              margin: {
                bottom: '4px',
              },
              font: {
                weight: 'bold',
                size: 'null',
              },
              textAlign: 'center',
            },
          },
        },
      },
      attribute: {
        figureAmount: {
          font: fonts.desktop.h4,
        },
        figureUnit: {
          font: fonts.desktop.copy1Regular,
          color: colors.black,
        },
        icon: {
          globalSvg: {
            fill: colors.senary,
            transform: 'scale(1)',
          },
        },
      },
      vehicle: {
        margin: {
          left: '0px',
          right: '0px',
          top: '16px',
        },
        title: {
          display: 'flex',
          justifyContent: 'between',
          alignItems: 'stretch',
          padding: {
            top: '0px',
            right: '15px',
            bottom: '0px',
            left: '15px',
          },
          model: {
            font: fonts.desktop.h3,
          },
          fuelAndTransmission: {
            display: 'none',
            marginLeft: '20px',
            fontSize: '13px',
            lintHeight: '18px',
            color: '#ccc',
          },
          price: {
            display: 'flex',
            startingFrom: {
              font: fonts.desktop.copy1Regular,
            },
            value: {
              fontMobile: fonts.desktop.copy1Medium,
              fontDesktop: fonts.desktop.h4,
            },
          },
          font: { desktop: fonts.desktop.h3, mobile: fonts.mobile.h3 },
        },
        inner: {
          border: {
            tabletView: {
              bottom: {
                width: '0.5px',
                style: 'solid',
                color: colors.grey400,
              },
              top: {
                width: '0.5px',
                style: 'dashed',
                color: '#5E7D95',
              },
            },
            top: {
              width: '1px',
              style: 'dashed',
              color: '#70707050',
            },
            bottom: {
              width: '1px',
              style: 'dashed',
              color: '#707070',
            },
          },
          header: {
            image: {
              maxWidth: '100px',
              maxHeight: '100%',
              width: 'auto',
              height: 'auto',
            },
            showDetail: {
              border: {
                top: '1px solid #CDCDCD50',
              },
            },
          },
          attribute: {
            odd: {
              background: {
                color: colors.offWhite,
              },
            },
            title: {
              font: fonts.desktop.copy1Medium,
            },
            amount: {
              font: fonts.desktop.copy1Medium,
            },
            unit: {
              font: fonts.desktop.copy1Regular,
            },
            marginBottom: '12px',
          },
          icon: {
            fill: colors.primary,
          },
        },
        borderBottom: `1px solid ${colors.grey400}`,
      },
      footer: {
        margin: {
          desktop: '48px 0 0',
          mobile: '24px 0 12px',
        },
      },
    },
    loader: {
      text: {
        fontSize: '1.143rem',
      },
      icon: {
        fill: colors.grey400,
      },
    },
    nextStepsRedesign: {
      color: colors.senary,
      backgroundColor: colors.grey100,
      marginMobile: '40px 0',
      marginDesktop: '70px 0',
      paddingMobile: '24px',
      paddingDesktop: '32px 50px',
      title: {
        fontMobile: fonts.mobile.h2,
        fontDesktop: fonts.desktop.h2,
        marginMobile: '0 0 40px 0',
        marginDesktop: '0 0 46px 0',
      },
      stepTitle: { margin: '0 0 16px 0', font: fonts.desktop.copy1Medium },
      stepDescription: { margin: '0 0 25px 0', font: fonts.desktop.copy1Regular },
      stepCTA: { paddingMobile: '9px 24px', paddingDesktop: '13px 24px' },
    },
    navMenu: {
      trigger: { font: { mobile: fonts.desktop.button, desktop: fonts.desktop.button } },
      header: {
        height: '65px',
        padding: '21px 0',
        backgroundColor: 'white',
        borderBottom: '1px solid #ebebeb',
        font: fonts.desktop.copy1Medium,
      },
      logo: { margin: '0 21px' },
      closeButton: {
        borderRadius: '4px',
        margin: '0 calc(21px - 0.8rem) 0 0',
        width: '58px',
        height: '58px',
        padding: 'none',
        hover: {
          backgroundColor: '#ebebeb',
        },
        icon: {
          width: '24px',
          height: '24px',
          backgroundColor: 'black',
        },
      },
      backButton: {
        borderRadius: '4px',
        padding: '0.5rem',
        margin: '0 0 0 calc(21px - 0.8rem)',
        hover: {
          backgroundColor: '#ebebeb',
        },
        icon: {
          width: '28px',
          height: '28px',
          backgroundColor: 'black',
        },
      },
      linksWrapper: {
        padding: '32px 0',
        atStart: { borderBottom: '1px solid #ebebeb', padding: '32px 0 42px' },
      },
      link: {
        listItem: {
          padding: '10px 21px',
          height: '52px',
          hover: {
            backgroundColor: colors.offWhite,
          },
        },
        icon: { width: '28px', height: '28px', backgroundColor: 'black' },
        font: fonts.desktop.h4,
      },
    },
  },
  showcase: {
    background: colors.offWhite,
    font: {
      mobile: fonts.mobile.h2,
      desktop: fonts.desktop.h2,
    },
  },
  vrmLookup: {
    background: '#FDC238',
  },
  modal: {
    default: {
      close: {
        color: '#161618',
        opacity: '1',
      },
    },
    totalCostOfOwnership: {
      close: {
        color: '#fff',
        opacity: '1',
      },
    },
  },
  layout: {
    pageHeader: {
      backgroundColor: colors.offWhite,
      logo: {
        imageSrc: '/assets/volvo/common/volvo-logo.svg',
      },
    },
    background: {
      image: {
        desktop: {
          src: '/assets/volvo/decision-tree/background.desktop.png',
          height: '378px',
          width: '704px',
        },
      },
    },
  },
  NEDCFlag: {
    color: '#a85f02',
  },
};

export { volvo };
