import { useRouter } from 'next/router';
import React, { useContext, PropsWithChildren } from 'react';
import { Brand } from 'shared/types';

const FeatureContext = React.createContext({});

interface Props {
  features?: { [key: string]: string | boolean };
}

const FeatureProvider = ({ features = {}, children }: PropsWithChildren<Props>) => {
  return <FeatureContext.Provider value={features}>{children}</FeatureContext.Provider>;
};

const readFeatureToggles = (environmentVariables: Record<string, string>): { [key: string]: string | boolean } => {
  const featureToggles = {};

  Object.keys(environmentVariables).map((variable) => {
    const featureFlagMatch = variable.match(/^FEATURE.((?!BRANDS).)*$/i);
    const featureBrandMatch = variable.match(/^FEATURE.*BRANDS/i);
    const featureRoutesMatch = variable.match(/^FEATURE.*ROUTES/i);

    if (featureFlagMatch != null) {
      featureToggles[variable] = environmentVariables[variable].toLowerCase() === 'true';
    } else if (featureBrandMatch != null) {
      featureToggles[variable] = environmentVariables[variable];
    }
    if (featureRoutesMatch != null) {
      featureToggles[variable] = environmentVariables[variable];
    }
  });

  return featureToggles;
};

const useFeature = (feature: string, brand?: Brand): boolean => {
  const featureToggles = useContext(FeatureContext);
  const { pathname } = useRouter();
  const splitPathname = pathname.split('/');
  const upperCaseFeature = feature.toUpperCase();
  const isFeatureEnabled = featureToggles[upperCaseFeature] === true;

  if (brand && isFeatureEnabled) {
    const featureRoutesByBrand = `${upperCaseFeature}_${brand.toUpperCase()}_ROUTES`;
    const availableBrands: string[] =
      featureToggles[`${upperCaseFeature}_BRANDS`]
        ?.toLowerCase()
        ?.split(',')
        .map((brand) => brand?.trim()) ?? [];
    const availableRoutes: string[] =
      featureToggles[featureRoutesByBrand]
        ?.toLowerCase?.()
        ?.split(',')
        .map((route) => route?.trim()) ?? [];

    if (availableRoutes.length) {
      if (availableRoutes.some((route) => splitPathname.includes(route))) {
        return true;
      }
      return false;
    }

    return isFeatureEnabled && availableBrands.includes(brand.toLowerCase());
  }

  return isFeatureEnabled;
};

export { FeatureProvider, readFeatureToggles, useFeature };
