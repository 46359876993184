import React, { useContext } from 'react';

const FuelCostContext = React.createContext({});

interface FuelCostContent {
  miles: number;
  petrol: string;
  diesel: string;
  electricity: number;
}

export interface FuelCosts {
  business: FuelCostContent;
  private: FuelCostContent;
  fuel_cost: FuelCostContent;
}

interface Props {
  fuelCosts: FuelCosts;
  children: React.ReactNode;
}

const FuelCostProvider: React.FC<Props> = ({ fuelCosts, children }) => {
  return <FuelCostContext.Provider value={fuelCosts}> {children} </FuelCostContext.Provider>;
};

type UseFuelCost = () => FuelCosts;

const useFuelCost: UseFuelCost = () => useContext(FuelCostContext) as FuelCosts;

export { FuelCostProvider, useFuelCost };
