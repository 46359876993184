import { RadioType } from 'shared/components/Radio/types';
import { Colors } from 'shared/types';

export const radioTheme = (fonts: any, colors: Colors): RadioType => {
  return {
    option: {
      text: {
        transformation: 'capitalize',
        font: fonts.regular,
      },
      spacing: '12px',
      size: '24px',
      border: {
        size: '1px',
        style: 'solid',
      },
      checked: {
        color: colors.primary,
      },
      unchecked: {
        color: colors.primary,
      },
      isCircular: true,
    },
  };
};
