import { BaseTheme, Theme } from 'shared/types';
import { HeroContentPlacement } from 'features/Hero/constants';
import { radioTheme } from 'shared/components/Radio/theme';

const fonts = {
  regular: 'wide normal',
  medium: 'wide normal',
  bold: 'wide bold',
  light: 'wide light',
};

const fontSizes = {
  sm: '.857rem', // 12px
  md: '1rem', // 14px
  lg: '1.142rem', // 16px
  xl: '1.2857rem', // 18px
  xxl: '1.7857rem', // 25px
  xxxl: '2rem', // 28px
  xxxxl: '2.571rem', // 36px
  xxxxxl: '5.143rem', // 72px
};

const colors = {
  primary: '#000',
  secondary: '#ffffff',
  tertiary: '#707070',
  quaternary: '#4c4c4c',
  quinary: '#b3b3b3',
  senary: '#ffffff',
  grey: 'grey',
  backgroundPrimary: '#F2F2F2',
};

const lineHeight = {
  desktop: {
    heading1: '4.429rem', // 52px
    heading2: '2.857rem', // 32px
    heading3: '2.143', // 30px
    heading4: '1.786rem', // 30px
    copy1: '1.5rem', // 16px
    copy3: '1.286rem', // 12px
    copy2: '1.429rem', // 14px
  },
  mobile: {
    heading1: '4.429rem', // 52px
    heading2: '2.857rem', // 32px
    heading3: '2.143', // 30px
    heading4: '1.786rem', // 30px
  },
};

const audi: Theme<BaseTheme> = {
  fonts,
  fontSizes,
  colors,
  favicons: {
    16: 'favicon-16x16.png',
    32: 'favicon-32x32.png',
    192: 'favicon-192x192.png',
  },
  background: {
    primary: colors.backgroundPrimary,
    secondary: '',
  },
  border: {
    primary: colors.quaternary,
    radius: '0px',
  },
  progress: {
    0: {
      background: '#CCCCCC',
      border: '',
      bar: colors.quaternary,
    },
    1: {
      background: '#CCCCCC',
      border: '',
      bar: colors.quaternary,
    },
    border: {
      attributes: '',
      radius: '',
    },
  },

  buttons: {
    globals: {
      font: `400 ${fontSizes.md}/22px ${fonts.regular}`,
      color: colors.primary,
      transform: 'none',
      border: {
        width: '1px',
        style: 'solid',
        radius: '0px',
        color: colors.primary,
      },
      variants: {
        large: {
          width: '114px',
          height: '48px',
          padding: '12px 24px',
        },
        medium: {
          width: '114px',
          height: '40px',
          padding: '9px 24px',
        },
        small: {
          width: '114px',
          height: '32px',
          padding: '5px 24px',
        },
      },
      transition: 'all 200ms ease-in-out',
    },
    primary: {
      backgroundColor: colors.primary,
      border: {
        width: '1px',
        style: 'solid',
        radius: '0px',
        color: colors.primary,
      },
      font: {
        color: colors.secondary,
      },
      state: {
        hover: {
          borderColor: colors.quaternary,
          backgroundColor: colors.quaternary,
          fontColor: colors.secondary,
        },
        active: {
          transform: 'none',
        },
        focussed: {
          backgroundColor: colors.primary,
          fontColor: colors.secondary,
          outline: {
            color: colors.quaternary,
            style: 'solid',
            width: '2px',
            offset: '2px',
          },
        },
        disabled: {
          color: colors.secondary,
          borderColor: colors.quinary,
          backgroundColor: colors.quinary,
        },
      },
    },
    secondary: {
      backgroundColor: colors.secondary,
      border: {
        width: '1px',
        style: 'solid',
        radius: '0px',
        color: colors.quaternary,
      },
      font: {
        color: colors.primary,
      },
      state: {
        hover: {
          borderColor: colors.grey,
          backgroundColor: 'unset',
          fontColor: colors.quaternary,
        },
        active: {
          transform: 'scale(0.95, 0.9)',
          font: {
            color: colors.secondary,
          },
        },
        focussed: {
          backgroundColor: colors.secondary,
          fontColor: colors.primary,
          outline: {
            color: colors.quaternary,
            style: 'solid',
            width: '2px',
            offset: '2px',
          },
        },
        disabled: {
          color: colors.quinary,
          borderColor: colors.quinary,
          backgroundColor: 'transparent',
        },
      },
    },
    tertiary: {
      backgroundColor: 'unset',
      border: {
        width: 'unset',
        style: 'unset',
        radius: 'unset',
        color: 'unset',
      },
      font: {
        color: colors.primary,
      },
      state: {
        hover: {
          borderColor: 'none',
          backgroundColor: 'none',
          fontColor: colors.quaternary,
        },
        active: {
          transform: 'none',
          font: {
            color: `${colors.senary}56`,
          },
        },
        focussed: {
          backgroundColor: 'unset',
          fontColor: colors.primary,
          outline: {
            color: colors.quaternary,
            style: 'solid',
            width: '2px',
            offset: '2px',
          },
        },
        disabled: {
          color: colors.primary,
          borderColor: 'unset',
          backgroundColor: 'unset',
        },
      },
    },
  },
  form: {
    inputs: {
      radio: radioTheme(lineHeight, colors),
    },
  },
  components: {
    detailedComparator: {
      attributes: {
        title: {
          font: { mobile: `700 28px/44px ${fonts.bold}`, desktop: `700 28px/44px ${fonts.bold}` },
          marginBottom: { mobile: '24px', desktop: '17px' },
        },
        value: { padding: { desktop: '19px 0' } },
        tooltipLabel: { font: fonts.bold },
        tooltipContent: { font: `16px/24px  ${fonts.regular}` },
        attributeTitle: { font: `700 16px ${fonts.bold}` },
        attributeValue: {
          font: `16px/18px ${fonts.regular}`,
          alignItems: { mobile: 'center', desktop: 'center' },
          padding: '14px 0 16px',
        },
        list: { padding: { mobile: '23px 0 40px', desktop: '0 0 16px' }, flexGap: { mobile: '23px', desktop: '0' } },
        borderBottom: `1px solid ${colors.primary}`,
        borderTop: `3px solid ${colors.primary}`,
        note: {
          title: { font: `${fontSizes.xl} ${fonts.bold}`, marginBottom: '1.142rem' },
          body: { font: fonts.regular },
        },
        columnGap: '14px',
        minHeight: { mobile: '0', desktop: '0' },
      },
      tabDefaultHeader: {
        marginBottom: { mobile: '24px', desktop: '40px' },
        header: {
          defaultFont: {
            mobile: `24px/36px ${fonts.medium}`,
            desktop: `32px/48px ${fonts.medium}`,
          },
          boldFont: { mobile: `24px/36px ${fonts.medium}`, desktop: `32px/48px ${fonts.medium}` },
          margin: { mobile: '0 0 24px', desktop: '0 0 16px' },
        },
        body: { defaultFont: { mobile: `16px/24px ${fonts.medium}` } },
      },
    },
    landingPage: {
      header: {
        contentBackgroundColor: '',
      },
    },
    pageFooter: {
      font: {
        family: 'wide normal',
        color: colors.secondary,
      },
      background: {
        color: colors.primary,
      },
      padding: {
        right: {
          mobile: '80px',
          tablet: '80px',
        },
        left: {
          mobile: '80px',
          tablet: '80px',
        },
        top: {
          mobile: '25px',
          tablet: '25px',
        },
        bottom: {
          mobile: '25px',
          tablet: '25px',
        },
      },
      iconHeight: '55px',
      linkWidth: '100px',
      nextSteps: {
        background: {
          color: colors.backgroundPrimary,
        },
        font: {
          family: 'extended normal',
          color: colors.secondary,
        },
        title: {
          padding: '2.4rem',
          font: {
            family: 'extended normal',
            color: colors.primary,
            size: '2.857rem',
          },
        },
        icon: {
          margin: '0 auto 0.5rem',
        },
        link: {
          font: {
            family: 'wide normal',
          },
          color: colors.primary,
        },
        margin: {
          top: '20px',
          right: '40px',
          bottom: '20px',
          left: '40px',
        },
      },
      copy: {
        font: {
          family: 'wide normal',
          color: colors.secondary,
        },
        textAlign: 'left',
      },
      footerBottom: {
        links: {
          color: colors.senary,
          order: {
            mobile: '2',
            tablet: '2',
          },
          justifyContent: 'flex-start',
          marginBottom: '52px',
          link: {
            margin: {
              top: '0px',
              right: '16px',
              bottom: '8px',
              left: '0px',
            },
          },
          padding: {
            left: '0px',
            right: '0px',
          },
        },
        copy: {
          order: '1',
        },
        disclaimerLine1: {
          order: {
            mobile: '3',
            tablet: '3',
          },
          color: colors.secondary,
        },
        disclaimerLine2: {
          order: '4',
        },
      },
    },
    form: {
      header: {
        font: fonts.medium,
      },
      borderColor: `${colors.senary}`,
      logo: {
        width: '60px;',
      },
      margin: {
        bottom: '2.714rem',
      },
      paddingLeft: '0',
      backgroundColor: colors.backgroundPrimary,
      alignItems: 'center',
      label: {
        fontWeight: 'normal',
        marginBottom: '18px',
      },
      tab: {
        color: '#666666',
        selected: {
          color: colors.primary,
        },
      },
      select: {
        color: '#333',
        backgroundColor: 'transparent',
        outlineFocus: `thin dotted ${colors.quaternary}`,
        borderBottom: `1px solid ${colors.primary}`,
        borderBottomFocus: `2px solid ${colors.primary}`,
        label: {
          font: {
            family: 'wide bold',
          },
        },
        font: 'unset',
        icon: {
          top: '0px',
          right: '0px',
          width: '33px',
          height: '33px',
          iconWidth: '24px',
          iconHeight: '24px',
        },
      },
      content: {
        marginTop: '28px',
        marginBottom: '36px',
        marginLeft: '28px',
        marginRight: '28px',
      },
      inputField: {
        width: '210px',
        height: '43px',
        marginBottom: {
          desktop: '36px',
          mobile: '36px',
        },
        border: `1px solid ${colors.primary}`,
        fontWeight: '700',
        fontSize: '1.714rem',
        textAlign: 'center',
        background: '#FBDA2D',
        borderRadius: '0',
      },
      buttons: {
        justifyContent: 'center',
        maxWidth: '100%',
      },
      errorFontSize: '1.143rem',
      viewports: {
        mobile: {
          spacing: '2rem',
          error: {
            flexDirection: 'column',
            paddingLeft: '2rem',
            paddingRight: '2rem',
            textMarginLeft: '0px',
            textMarginTop: '1rem',
          },
        },
        desktop: {
          spacing: '2rem',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'start',
          error: {
            flexDirection: 'row',
            marginLeft: '2rem',
          },
        },
      },
      border: {
        top: 'none',
        left: 'none',
        right: 'none',
        bottom: `1px solid ${colors.quaternary}`,
      },
      vehicle: {
        font: `400 16px/19px ${fonts.bold}`,
        loader: {
          fill: colors.primary,
        },
        image: {
          fill: colors.quinary,
        },
        btn: {
          width: '140px',
          disable: {
            background: colors.quinary,
            color: colors.secondary,
          },
          hover: {
            background: colors.quaternary,
            color: colors.secondary,
          },
        },
        body: {
          width: {
            desktop: '450px',
            mobile: '100%',
          },
        },
        label: {
          background: '#0158C1',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          width: '31px',
          height: '43px',
          borderRadius: '0',
          color: colors.secondary,
          border: {
            width: '1px',
            style: 'solid',
            color: colors.primary,
          },
          font: {
            weight: '800',
            size: '14px',
          },
          padding: {
            left: '2px',
            right: '2px',
            top: '2px',
            bottom: '2px',
          },
          footer: {
            textAlign: 'center',
            padding: {
              top: '23px',
              right: '23px',
              bottom: '7px',
              left: '23px',
            },
            color: '#696969',
          },
          content: {
            input: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
            padding: {
              top: '31px',
              bottom: '10px',
            },
          },
          vrm: {
            margin: {
              right: '13px',
            },
          },
        },
      },
    },
    dataTableColumn: {
      width: {
        mobile: '50%',
        desktop: '33.33%',
      },
    },
    modal: {
      closeButton: {
        border: `1px solid ${colors.primary}`,
        borderRadius: '100px',
      },
      background: {
        color: colors.primary,
      },
    },
    testTypeInformation: {
      header: {
        color: 'black',
        font: `21px ${fonts.regular}`,
      },
      title: {
        color: 'black',
        font: `16px/24px ${fonts.regular}`,
      },
      body: {
        color: colors.tertiary,
        font: `14px/24px ${fonts.regular}`,
        margin: '0 0 25px',
      },
      link: {
        font: {
          lineHeight: '18px',
        },
      },
    },
    vehicleConfirm: {
      findAnotherVehicle: {
        font: `600 1.144rem/18px ${fonts.regular}`,
        color: '#1c6bba',
        cursor: 'pointer',
        marginTop: '12px',
      },
      details: {
        vrm: {
          font: `400 ${fontSizes.xxl}/30px ${fonts.bold}`,
        },
        description: {
          font: `400 ${fontSizes.xl}/25px ${fonts.regular}`,
          color: colors.tertiary,
        },
      },
      currentMileage: {
        maxWidth: '447px',
        width: '100%',
        background: '#fafafa',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '7px',
        description: {
          font: `400 1rem/18px ${fonts.regular}`,
          maxWidth: {
            mobile: '332px',
            desktop: '332px',
          },
          color: colors.tertiary,
          marginTop: '8px',
        },
        form: {
          label: {
            marginBottom: '7px',
            font: `400 16px/18px ${fonts.regular}`,
            color: {
              default: colors.primary,
              optional: colors.primary,
            },
          },
          input: {
            height: '43px',
            width: {
              mobile: '177px',
              desktop: '177px',
            },
            border: {
              width: '1px',
              style: 'solid',
              color: '#cdcdcd',
            },
          },
        },
      },
      actionButtons: {
        display: 'flex',
        marginBottom: '22px',
        marginTop: '16px',
      },
    },
    vehicleOption: {
      superscript: {
        font: `400 8px/18px ${fonts.bold}`,
      },
      showIcon: {
        prepend: true,
        append: false,
      },
      minHeight: '212px',
      padding: '5px',
      backgroundColor: 'white',
      unSelected: { backgroundColor: colors.backgroundPrimary },
      width: {
        mobile: '50%',
        desktop: '33.33%',
      },
      changeVehicle: {
        mobile: {
          justification: 'flex-start',
        },
      },
      vehicleSummaryDetails: {
        backgroundColor: 'white',
        alignSelf: 'flex-start',
        display: 'inline',
        link: {
          display: 'none',
        },
        color: colors.tertiary,
      },
      image: {
        mobile: {
          height: 'auto',
          stickyHeight: 'auto',
          stickyWidth: '50%',
          width: '100%',
        },
        height: 'auto',
        width: '100%',
      },
      price: {
        fontFamily: 'wide bold',
      },
      hr: {
        color: colors.primary,
      },
      testStandard: {
        font: `400 14px/18px ${fonts.regular}`,
        link: {
          color: '#1c6bba',
        },
        wrapper: {
          order: '4',
          width: '100%',
          color: colors.tertiary,
          padding: '9px 0 0',
        },
      },
    },
    vehicleSummary: {
      icon: '/assets/audi/detailed-comparator/close-audi.svg',
      width: '22px',
      changeButton: { margin: { mobile: '0 0 16px', desktop: '0 0 16px' } },
    },
    vehicleOptionsForm: {
      padding: '60px 38px 46px 38px',
      title: {
        font: `400 24px/18px ${fonts.medium}`,
        color: colors.primary,
      },
      description: {
        font: `400 16px/20px ${fonts.medium}`,
        color: colors.primary,
        marginTop: '50px',
      },
      options: {
        option: {
          margin: {
            top: '77px',
            right: '33px',
          },
        },
        container: {
          border: `1px solid ${colors.primary}`,
          borderRadius: '0px',
          width: {
            desktop: '221px',
            mobile: '221px',
          },
          height: '198px',
          hover: {
            border: '1px solid #F50537',
            backgroundColor: 'none',
          },
          description: {
            font: `400 1.144rem/18px ${fonts.medium}`,
            color: colors.primary,
            marginTop: '40px',
          },
        },
      },
      button: {
        marginTop: { mobile: '117px', desktop: '117px' },
        width: '177px',
        background: colors.primary,
        color: colors.secondary,
        hover: {
          background: colors.quaternary,
          color: colors.secondary,
        },
      },
    },
    tabHeader: {
      color: { mobile: colors.secondary, desktop: colors.tertiary },
      backgroundColor: { mobile: colors.primary, desktop: colors.primary },
      chevronIcon: { color: colors.secondary, width: '11px', height: '19px' },
      height: { mobile: '48px', desktop: '101px' },
      margin: { mobile: '0 0 24px', desktop: '0 0 56px' },
      minHeight: '6.313rem',
      spacing: {
        xxxl: '0',
      },
      item: {
        hover: {
          borderBottomColor: `${colors.senary}`,
        },
        minHeight: '6.313rem',
        textTransform: 'capitalize',
        border: {
          borderTop: `1px solid ${colors.primary}`,
          borderLeft: `1px solid ${colors.primary}`,
          borderRight: `1px solid ${colors.secondary}`,
          borderBottom: `1px solid ${colors.primary}`,
          lastItem: {
            borderRight: 'none',
          },
        },
        svg: {
          fill: 'none',
          stroke: '#808080',
        },
      },
      itemDisabled: {
        border: {
          borderRight: '',
        },
        svg: {
          fill: 'none',
          stroke: '#808080',
        },
      },
      itemActive: {
        underlineSpacing: '11px',
        underline: `2px solid ${colors.senary}`,
        backgroundColor: colors.primary,
        color: { mobile: colors.secondary, desktop: '' },
        border: {
          borderTop: `1px solid ${colors.primary}`,
          borderLeft: `1px solid ${colors.primary}`,
          borderRight: `1px solid ${colors.secondary}`,
          borderBottom: `1px solid ${colors.primary}`,
        },
        svg: {
          fill: 'none',
          stroke: colors.secondary,
          opacity: '00.5',
        },
        active: {
          color: colors.senary,
        },
      },
      heading: {
        borderBottom: 'none',
      },
    },
    toggle: {
      position: 'left',
      font: {
        color: colors.tertiary,
        family: `400 14px/16px ${fonts.regular}`,
      },
      margin: {
        mobile: {
          top: 'unset',
          bottom: '24px',
          left: '0',
        },
        desktop: {
          left: '17px',
        },
      },
      button: {
        margin: {
          mobile: {
            right: '11px',
            left: '11px',
          },
          desktop: {
            right: '12px',
            left: '12px',
          },
        },
        padding: {
          mobile: {
            top: '12px',
            bottom: '12px',
          },
          desktop: {
            top: '12px',
            bottom: '12px',
          },
        },
        border: {
          bottom: {
            width: '2px',
            style: 'solid',
            color: 'transparent',
          },
        },
        focus: {
          outline: `thin dotted ${colors.quaternary}`,
        },
        active: {
          font: {
            color: colors.primary,
          },
          border: {
            color: colors.primary,
          },
        },
      },
    },
    attributes: {
      alignItems: 'center',
      title: {
        color: colors.primary,
      },
      icon: {
        color: colors.secondary,
      },
    },
    prompt: {
      alignItems: 'flex-start',
      textAlign: 'left',
      font: {
        family: 'wide bold',
        weight: 'normal',
      },
    },
    runningCostTable: {
      margin: { mobile: '40px 0 0', desktop: '80px 0 0' },
      attributesHeading: {
        backgroundColor: '',
        title: {
          font: {
            mobile: '700 20px/32px wide normal',
            desktop: '700 20px/32px wide normal',
          },
          padding: {
            mobile: '0 0 16px',
            desktop: '16px 0 20px',
          },
          flexDirection: { mobile: 'column', desktop: 'column' },
          alignItems: { mobile: 'flex-start', desktop: 'flex-start' },
          gap: { mobile: '0', desktop: '0' },
          borderBottom: { mobile: 'none', desktop: `3px solid ${colors.primary}` },
        },
        subheading: {
          font: '400 16px/22px wide normal',
          color: colors.primary,
        },
        value: {
          font: {
            mobile: '700 20px/32px wide normal',
            desktop: '700 20px/32px wide normal',
          },
          padding: { mobile: '0 0 16px 0', desktop: '0' },
          borderBottom: { mobile: 'none', desktop: `3px solid ${colors.primary}` },
          justifyContent: { mobile: 'center', desktop: 'center' },
        },
        font: {
          family: 'wide bold',
          weight: 'normal',
        },
        MobileAlignItems: 'flex-end',
        DesktopAlignItems: 'center',
        headerBorder: {
          borderBottom: {
            mobile: `3px solid ${colors.primary}`,
            desktop: 'none',
            mobileFleetManagerSelected: `3px solid ${colors.primary}`,
          },
        },
      },
      tableRow: {
        border: '0px',
        borderTop: '0px',
      },
      tableCell: {
        borderBottom: `1px solid ${colors.primary}`,
        margin: 'none',
      },
      inputCell: {
        borderTop: '0px',
        borderBottom: '1px solid #808080',
        borderLeft: '0px',
        borderRight: '0px',
        width: { mobile: '108px', desktop: '108px' },
        height: { mobile: '40px', desktop: '40px' },
        focus: {
          outline: 'transparent',
          borderBottom: `1px solid ${colors.primary}`,
        },
      },
      title: {
        mobile: {
          margin: {
            bottom: '1rem',
            left: '0',
          },
        },
        tablet: {
          margin: {
            bottom: '1.5714285714285714rem',
            left: '0',
          },
        },
      },
    },
    recalculateForm: {
      backgroundColor: colors.backgroundPrimary,
      wrapper: {
        margin: {
          bottom: '3rem',
        },
        toggle: {
          iconSize: '24',
        },
      },
      input: {
        height: '56px',
      },
    },
    infoPopup: {
      messageRunningCosts: 'flex',
      background: colors.secondary,
      arrowBackground: colors.secondary,
      color: colors.secondary,
      icon: '/assets/audi/detailed-comparator/close-info-popup.svg',
      minWidth: '250px',
      maxWidth: '320px',
      text: {
        width: '288px',
        textAlign: 'left',
      },
      close: {
        width: '12px',
        display: 'inline',
      },
      message: {
        left: '190px',
        runningCost: {
          width: '60px',
          left: '50vw',
        },
      },
      border: {
        color: colors.secondary,
      },
    },
    monthlyCost: {
      color: colors.primary,
      font: {
        size: '1.142rem',
        family: 'wide bold',
        weight: 'bold',
      },
      heading: {
        title: {
          color: colors.primary,
          font: { mobile: '700 16px/24px wide normal' },
        },
        description: {
          color: colors.primary,
          font: { mobile: '400 14px/20px wide normal' },
        },
      },
      padding: {
        left: '1.2857rem',
      },
      totals: {
        ownTypeOwn: {
          backgroundColor: { mobile: 'transparent', desktop: colors.backgroundPrimary },
          innerBackgroundColor: colors.backgroundPrimary,
          padding: {
            mobile: '16px 16px 17px',
            desktop: '16px',
          },
          heading: { padding: { mobile: '0 0 16px', desktop: '0' } },
          titleAndValuesGap: '24px',
          title: {
            mobile: {
              font: '700 16px wide normal',
              margin: '24px 0 0',
            },
          },
          value: {
            mobile: {
              font: '400 16px/18px wide normal',
              margin: '16px 0 0',
            },
          },
        },
        heading: {
          padding: { mobile: '16px 0 16px', desktop: '16px 0 16px' },
        },
        value: {
          padding: { mobile: '0 0 16px', desktop: '16px 0 16px' },
        },
      },
    },
    hero: {
      'detailed-comparator': {
        mobile: {
          height: '180px',
          marginBottom: '40px',
        },
        tablet: {
          height: '421px',
          marginBottom: '0',
        },
        hiRes: {
          height: '721px',
          marginBottom: '0',
        },
        images: {
          altText: 'An e-tron parked in the mountains',
          srcSet: [
            '/assets/audi/detailed-comparator/hero/e-tron_2020_2096-480x600px.webp',
            '/assets/audi/detailed-comparator/hero/e-tron_2020_2096-1080x608px.webp',
            '/assets/audi/detailed-comparator/hero/e-tron_2020_2096-1920x1080px.webp',
            '/assets/audi/detailed-comparator/hero/e-tron_2020_2096-3840x2160px.webp',
          ],
          srcSetMimeType: 'image/webp',
          fallback: '/assets/audi/detailed-comparator/hero/e-tron_2020_2096-1920x1080px.jpg',
          media: ['768px', '1024px', '1440px', '4000px'],
          fit: 'cover',
          position: {
            default: 'center 60%',
            480: 'center 40%',
            610: 'center 50%',
            1024: 'center 80%',
            1440: 'center 80%',
            2560: 'center 90%',
          },
        },
        content: {
          display: {
            mobile: { title: HeroContentPlacement.INSIDE, subTitle: HeroContentPlacement.OUTSIDE },
            desktop: { title: HeroContentPlacement.INSIDE, subTitle: HeroContentPlacement.INSIDE },
          },
          width: {
            1024: '866px',
          },
          padding: {
            default: {
              top: '24px',
              right: '16px',
              bottom: '0',
              left: '16px',
            },
            480: {
              top: '42px',
              right: '80px',
              bottom: '42px',
              left: '80px',
            },
          },
          title: {
            alignItems: {
              default: 'flex-start',
              480: 'flex-start',
            },
            justifyContent: {
              default: 'flex-start',
              480: 'flex-start',
            },
            text: {
              alignment: {
                default: 'left',
                480: 'left',
              },
            },
            color: '#000',
            font: {
              size: {
                default: '2rem',
                480: '2.85714rem',
              },
            },
          },
          subTitle: {
            justifyContent: {
              default: 'flex-start',
              '480': 'flex-start',
            },
            textAlign: {
              default: 'left',
              '480': 'left',
            },
            margin: {
              top: 'unset',
            },
            font: {
              size: '24px',
            },
          },
          inside: { gap: '24px' },
        },
      },
    },
    loader: {
      text: {
        fontSize: '1.144rem',
      },
      icon: {
        fill: colors.primary,
      },
    },
  },
  vrmLookup: {
    background: '#FDC238',
  },
  layout: {
    pageHeader: {
      logo: {
        imageSrc: '/assets/audi/common/audi-logo.svg',
        width: {
          desktop: '60px',
          tablet: '60px',
          mobile: '60px',
        },
        height: '20px',
      },
    },
  },
  tools: {
    detailedComparator: {
      tabs: {
        default: {
          title: {
            font: {
              family: 'extended normal',
              size: '2.286rem',
            },
          },
        },
      },
    },
  },
};

export { audi };
