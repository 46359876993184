import { subBrandMapping } from 'shared/components/constants';
import { Brand } from 'shared/types';

const getSubBrandMapping = (brand: Brand): Brand => {
  if (subBrandMapping[brand] !== undefined) {
    return subBrandMapping[brand];
  }
  return brand;
};

export default getSubBrandMapping;
