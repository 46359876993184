import { Product } from 'shared/constants';
import { Brand } from 'shared/types';

export const showDisclaimerLine2 = {
  [Product.EV_TREE]: false,
  [Product.COMPARATOR]: false,
  [Product.DETAILED_COMPARATOR]: true,
  [Product.USED_COMPARATOR]: true,
  [Product.LANDING_PAGE]: false,
};

export const showIconLinks = {
  [Brand.VOLVO]: false,
  [Brand.WAYLANDS]: false,
  [Brand.MOTABILITY]: true,
  [Brand.AUDI]: false,
  [Brand.DRIVVNQA]: false,
};

export const EnableClickDisclaimerLine1ForBrands = {
  [Brand.VOLVO]: false,
  [Brand.WAYLANDS]: false,
  [Brand.MOTABILITY]: true,
  [Brand.AUDI]: false,
  [Brand.DRIVVNQA]: false,
};

export interface HeaderPlacementForBrandsType {
  [key: string]: {
    [key: string]: boolean;
  };
}

export const HeaderPlacementForBrands: HeaderPlacementForBrandsType = {
  [Product.EV_TREE]: {
    [Brand.VOLVO]: true,
    [Brand.WAYLANDS]: true,
    [Brand.MOTABILITY]: false,
    [Brand.DRIVVNQA]: true,
  },
};

export const subBrandMapping = {
  [Brand.WAYLANDS]: Brand.VOLVO,
};
